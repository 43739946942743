import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import {  getList } from '../../helpers/fackBackend_Helper';

function* getDashboardGraphListResponse() {
    try {
        const response = yield call(getList, '/getActivationCount',);
        if (response.response.Status) {
            yield put(
                actions.getDashboardGraphListSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getDashboardGraphListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDashboardGraphListFailed(error));
    }
}

// function* getWidgetDataResponse() {
//     try {
//         const response = yield call(getList, '/getTodayActivationCount',);
//         if (response.response.Status) {
//             yield put(
//                 actions.getWidgetDataSuccess(
//                     response.response.Data
//                 )
//             )
//         } else {
//             yield put(actions.getWigetDataFailed(response.response.Message));
//         }
//     } catch (error) {
//         yield put(actions.getWigetDataFailed(error));
//     }
// }

function* getChartDataResponse() {
    try {
        const response = yield call(getList, '/getChartData',);
        if (response.response.Status) {
            yield put(
                actions.getChartDataSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getChartDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getChartDataFailed(error));
    }
}

function* getDashboardRechargeListResponse() {
    try {
        const response = yield call(getList, '/getRechargeCount',);
        if (response.response.Status) {
            yield put(
                actions.getDashboardRechargeListSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getDashboardRechargeListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDashboardRechargeListFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_DASHBOARD_GRAPH_LIST, getDashboardGraphListResponse)]);
    // yield all([takeEvery(actions.GET_WIDGET_DATA, getWidgetDataResponse)]);
    yield all([takeEvery(actions.GET_CHART_DATA, getChartDataResponse)]);
    yield all([takeEvery(actions.GET_DASHBOARD_RECHARGE_LIST, getDashboardRechargeListResponse)]);

}