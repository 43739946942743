import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Tag, Select, Button, message, Alert, Space, } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import manualAddFundAction from '../../redux/manualAddFund/actions';
import dealerActions from '../../redux/dealer/actions';
import authActions from '../../redux/auth/actions';
import { formatString } from '../../helpers/commonFunction';

const { manualAddFunds, resetManualAddFund } = manualAddFundAction;
const { getDealer } = dealerActions;
const { getProfileData } = authActions;

const ManualAddFund = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const entedAmount = Form.useWatch('amount', form);
    const [errorText, setErrorText] = useState("");
    const [dealerAmountToShow, setDealerAmountToShow] = useState(`0.00`)
    const [addFormData, setAddFormData] = useState({
        dealerId: '',
        amount: '',
        remarks: '',
        remark: ''
    });
    const [dealerError, setDealerError] = useState(false)
    const [amountValidError, setAmountValidError] = useState(false)

    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const { manualAddFundMessage, manualAddFundError, updateTopUpFlag, buttonLoading } = useSelector((state) => state.manualAddFund);
    useEffect(() => {
        dispatch(getDealer());
    }, [])

    useEffect(() => {
        dispatch(getDealer());
        setAddFormData(prevState => ({
            ...prevState,
            dealerId: "",
            amount: "",
            remarks: '',
            remark: ''
        }));
        setDealerAmountToShow(`0.00`);
        dispatch(getDealer());
        dispatch(getProfileData());

    }, [updateTopUpFlag])

    useEffect(() => {
        if (manualAddFundMessage) {
            form.resetFields();
            addFormData.dealerId = ""
            setDealerAmountToShow('0.00');
            setAddFormData({
                ...addFormData,
                dealerId: "",
                remarks: '',
                amount: '',
                remark: ''
            });

            dispatch(getDealer());
            dispatch(getProfileData());
        }
    }, [manualAddFundMessage])

    const handleNumber = (e) => {
        const { value } = e.target;
        if (!((e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57)) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 190 || e.keyCode === 110)) {
            e.preventDefault();
        }
        if ((e.keyCode === 190 || e.keyCode === 110) && value.includes('.')) {
            e.preventDefault();
        }
        if (value.includes('.')) {
            const parts = value.split('.');
            if (parts[1].length >= 2 && e.keyCode !== 8) {
                e.preventDefault();
            }
        }
    };

    const handleSelectDealer = (e) => {
        const dealerId = e
        if (dealerId !== "") {
            setAddFormData(prevState => ({
                ...prevState,
                dealerId: e
            }));
            var selectedDealer = dealerResult && dealerResult.filter(dealer => dealer.ID === dealerId);
            const amount = selectedDealer && selectedDealer
            const formattedAmount = parseFloat(selectedDealer[0].AccountBalance).toFixed(2);
            setDealerAmountToShow(formattedAmount);
        }
    }
    const manualError = (errorList) => {
        message.error(errorList)
    };
    const handleChangeAmount = (e) => {
        const fvalue = e.target.value;
        setAddFormData(prevState => ({
            ...prevState,
            amount: fvalue
        }));
        setErrorText("");
    }


    const handleChangRemark = (e) => {
        const rValue = e.target.value;
        setAddFormData(prevState => ({
            ...prevState,
            remark: rValue,
        }));
        setErrorText("");
    };

    const handleSelectFundType = (fundTypeValue) => {
        setAmountValidError(false)
        const submitDealerFund = {
            dealerId: addFormData.dealerId,
            amount: addFormData.amount,
            remarks: addFormData.remark,
            fundType: fundTypeValue
        };
        if (addFormData.dealerId === '' || addFormData.amount === "" || addFormData.amount <= 0 || addFormData.remark === "") {
            if (addFormData.amount !== '' && addFormData.amount <= 0) {
                setAmountValidError(true)
            } else {
                setAmountValidError(false)
            }
        } else {
            dispatch(manualAddFunds(submitDealerFund));
            setAddFormData({
              ...addFormData,
                amount: '',
                remark: ''
            });
            setDealerAmountToShow('0.00');
            dispatch(getDealer());
        }
    };

    const handleCloseAlert = () => {
        dispatch(resetManualAddFund())
    }

    const success = (text) => {
        message.success(text);
        dispatch(resetManualAddFund()); 
        setDealerAmountToShow('0.00');
        dispatch(getDealer());
        dispatch(getProfileData());
        setAddFormData({
            ...addFormData,
            dealerId: "",
            remarks: '',
            amount: '',
            remark: ''
        });
        form.resetFields();

    };

    const error = (text) => {
        message.error(text);
        dispatch(resetManualAddFund()); // Reset messages after error message
    };

    return (
        <div>
            {manualAddFundMessage !== null && manualAddFundMessage !== undefined && success(manualAddFundMessage)}
            {manualAddFundError !== null && manualAddFundError !== undefined && error(manualAddFundError)}
            <Row gutter={24} justify="center" style={{ 'marginBottom': '0px', marginTop: "0px" }}>
                <Col lg={10} md={24} sm={24} xs={24} >
                    <div className='upper-div-add'>
                        <div className='first-layer-add'>
                            <div className='addFundColumn'>
                                <div className='mini-layer-add'>

                                    <Form id="manualTopupForm"
                                        form={form}
                                        layout="vertical"
                                        autoComplete="off"
                                    >
                                        <Row style={{ padding: "0 10px 0 10px", margin: "7px 0 2px 0" }}>

                                        </Row>
                                        <Form.Item
                                            name="dealerId"
                                            label="Dealer"
                                            rules={[{ required: true, message: 'Please select dealer!' }]}
                                        >
                                            <Select
                                                className="ant-select sect-nnn"
                                                showSearch
                                                optionFilterProp="children"
                                                placeholder="Select Dealer"
                                                name="dealerId"
                                                onChange={handleSelectDealer}
                                            >
                                                <Select.Option value="" key="0">Select</Select.Option>
                                                {dealerResult && dealerResult.length > 0 && dealerResult.map(dealer => (
                                                    dealer.ParentID !== 0 && <Select.Option name="dealerId" value={dealer.ID} key={dealer.ID}>{dealer.DealerName}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Col lg={24} md={24} sm={24} xs={24} style={{ padding: "11px 0px 6px 0px", }}>
                                            <div className='para-pp'>
                                                <p>Dealer Account Balance</p>
                                            </div>

                                            <div className='manual-ne-dd-top'>
                                                <div className='manual-uper-layr'>
                                                    <h5>${dealerAmountToShow ? dealerAmountToShow : 0.00}</h5>
                                                </div>
                                            </div>
                                        </Col>
                                        <Form.Item
                                            label="Amount" name="amount"
                                            rules={[
                                                { required: true, message: formatString('Please input your amount!') },
                                                {
                                                    validator(_, value) {
                                                        if (value === undefined || value === '') {
                                                            return Promise.resolve();
                                                        }
                                                        if (value > 0) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Amount must be greater than 0!'));
                                                    },
                                                }
                                            ]}                                        >

                                            <Input placeholder="Amount" onKeyDown={handleNumber} className='inputAmount txt-input-ttx ' name="amount" value={addFormData.amount} onChange={handleChangeAmount} />
                                        </Form.Item>


                                        <Form.Item
                                            label="Remark" name="remark"
                                            rules={[{ required: true, message: formatString('Remark is required!') }]}                                        >
                                            <Input placeholder="Remark" className='txt-input-ttx ' name="remark" value={addFormData.remark} onChange={handleChangRemark} />
                                        </Form.Item>

                                        <Form.Item >
                                            <div className='btnadd-min-add' style={{ marginTop: "10px", marginBottom: "-20px" }}>
                                                <Button type="primary" htmlType="submit" loading={buttonLoading} style={{ backgroundColor: '#F14336', color: 'white', border: "none", borderRadius: "5px", width: "40%", height: "38px", marginRight: '7px' }} onClick={() => handleSelectFundType(2)}>DEDUCT</Button>
                                                <Button type="primary" htmlType="submit" loading={buttonLoading} style={{ backgroundColor: '#2ECC71', color: 'white', border: "none", borderRadius: "5px", width: "40%", height: "38px" }} onClick={() => handleSelectFundType(1)}>ADD TOP UP</Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ManualAddFund
