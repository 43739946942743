import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import homeActions from '../../redux/home/actions';
import {
  LoadingOutlined
} from "@ant-design/icons";
function EChart() {
  const { Title, Paragraph } = Typography;
  const { getDashboardGraphList, getChartData } = homeActions;
  const dispatch = useDispatch();
  const dashboardListResult = useSelector((state) => state.home.dashboardListResult);
  const chartDataResult = useSelector((state) => state.home.chartDataResult);
  const chartDataLoading = useSelector((state) => state.home.chartDataLoading);


  useEffect(() => {
    dispatch(getDashboardGraphList());
    dispatch(getChartData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec");
  var d = new Date();
  d.setDate(1);
  var month = [];
  for (var i = 0; i <= 11; i++) {
    month.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
    d.setMonth(d.getMonth() - 1);
  }

  // var totalCountsData = [];
  var pendingCountsData = [];
  var processingCountData = [];
  var completedCountData = [];
  var failedCountData = [];
  chartDataResult && chartDataResult.length > 0 && chartDataResult.map((data) => {
    // totalCountsData.push(data.TotalCounts.split(",").map(Number));
    pendingCountsData.push(data.PendingCounts.split(",").map(Number));
    processingCountData.push(data.ProcessingCounts.split(",").map(Number));
    completedCountData.push(data.CompleteCounts.split(",").map(Number));
    failedCountData.push(data.FailedCounts.split(",").map(Number));
  });

  const options = {
    chart: {
      type: "bar",
      width: "100%",
      height: "auto",
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      // colors: ["transparent"],
    },
    // grid: {
    //   show: true,
    //   borderColor: "black",
    //   strokeDashArray: 2,
    // },
    xaxis: {
      categories: month.reverse(),
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        // style: {
        //   colors: [
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff"
        //   ],
        // },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        // style: {
        //   colors: [
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //     "#fff",
        //   ],
        // },
      },
    },
    title: {
      text: 'Monthly Recharge', // The title you want to give
      align: 'center', // Alignment of the title (left, center, right)
      margin: 10, // Margin in pixels
      offsetX: 0, // X-offset in pixels (use this to adjust the title position horizontally)
      offsetY: 20, // Y-offset in pixels (use this to adjust the title position vertically)
      floating: false, // Set to true if you want the title to float above the chart
      style: {
        fontSize: '20px', // Font size of the title
        fontWeight: '700', // Font weight of the title
        color: 'green', // Text color of the title
      },
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  }

  const series = [
    // {
    //   name: "Total",
    //   data: totalCountsData[0],
    //   color: "#fff",
    // },
    {
      name: "Pending",
      data: pendingCountsData[0],
      // color: "#FF5733",
      color: "#808080",
    },
    {
      name: "Processing",
      data: processingCountData[0],
      color: "#f4bd0e",
    },
    {
      name: "Completed",
      data: completedCountData[0],
      color: "#008000",
    },
    {
      name: "Cancelled",
      data: failedCountData[0],
      // color: "#E91E63",
      color: "#ff0000",
    },
  ]

  var widgetAdminData = dashboardListResult.length > 0 && dashboardListResult.map((data) => {
    return [
      { titleName: 'Total', title: `${data.Total === null ? 0 : data.Total}` },
      { titleName: 'Pending', title: `${data.Pending === null ? 0 : data.Pending}` },
      { titleName: 'Processing', title: `${data.Processing === null ? 0 : data.Processing}` },
      { titleName: 'Complete', title: `${data.Complete === null ? 0 : data.Complete}` },
      { titleName: 'Failed', title: `${data.Failed === null ? 0 : data.Failed}` },
    ]
  });

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <>
      <div id="chart">
        {chartDataLoading === true ? <div className="loading-container">
          <Spin size="large" indicator={antIcon} />
        </div> : 
        <ReactApexChart
          title="Proccessed Recharge"
          className="bar-chart"
          options={options}
          series={series}
          type="bar"
          height={320}
        />}
      </div>
      {/* <div className="chart-vistior">
        <Title level={5}>Recharge</Title>
        <Paragraph className="lastweek">
        </Paragraph>
        <Row gutter={[24, 0]}>
          {widgetAdminData && widgetAdminData[0].length > 0 && widgetAdminData[0].map((v, index) => (
            <Col xs={8} xl={4} lg={6} sm={2} md={5} key={index}>
              <div className="chart-visitor-count">
                <Title level={4}>{v.title}</Title>
                <span>{v.titleName}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div> */}
      <div className="chart-vistior">
        <Title level={5}>Recharge</Title>
        <Paragraph className="lastweek">
        </Paragraph>
        {chartDataLoading === true ? (
          <div className="loading-container">
            <Spin size="large" indicator={antIcon} />
          </div>
        ) : (
          <Row gutter={[24, 0]}>
            {widgetAdminData && widgetAdminData[0].length > 0 && widgetAdminData[0].map((v, index) => (
              <Col xs={8} xl={4} lg={6} sm={2} md={5} key={index}>
                <div className="chart-visitor-count">
                  <Title level={4}>{v.title}</Title>
                  <span>{v.titleName}</span>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>

    </>
  );
}

export default EChart;
