import actions from "./actions";

const initState = {
    validateResult: [],
    loading: false,
    blankError: null,
    duplicatedSimDataInv: [],
    blankResult: [],
    showButtonUpload: false,
    blankDetails: [],
    blankInvResult: null,
    showAddPage: false,
    showDetailPage: false,
    blankMessage: null,
    showValidateSuccess: false,
    noteError: false,
    buttonLoading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.VALIDATE_BLANK_DATA:
            return {
                ...state,
                loading: true,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.VALIDATE_BLANK_DATA_SUCCESS:
            return {
                ...state,
                blankMessage: action.blankMessage,
                loading: false,
                showButtonUpload: true,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: true,
                noteError: false
            };
        case actions.VALIDATE_BLANK_DATA_FAILED:
            return {
                ...state,
                blankError: action.blankError,
                loading: false,
                duplicatedSimDataInv: action.simData && action.simData.split(','),
                showButtonUpload: false,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: true
            };
        case actions.ADD_BLANK:
            return {
                ...state,
                loading: false,
                blankError: null,
                showButtonUpload: true,
                blankMessage: null,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false,
                buttonLoading: true
            };
        case actions.ADD_BLANK_SUCCESS:
            return {
                ...state,
                loading: false,
                blankResult: action.blankResult,
                blankMessage: action.blankMessage,
                blankError: null,
                showButtonUpload: false,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false,
                buttonLoading: false
            };
        case actions.ADD_BLANK_FAILED:
            return {
                ...state,
                loading: false,
                blankMessage: null,
                showButtonUpload: false,
                blankError: action.blankError,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false,
                buttonLoading: false
            };
        case actions.GET_BLANK:
            return {
                ...state,
                loading: true,
                blankError: null,
                blankMessage: null,
                buttonLoading: true,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_BLANK_SUCCESS:
            return {
                ...state,
                loading: false,
                blankError: null,
                blankResult: action.blankResult,
                blankMessage: null,
                buttonLoading: false,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_BLANK_FAILED:
            return {
                ...state,
                loading: false,
                blankError: action.blankError,
                blankMessage: null,
                buttonLoading: false,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_BLANK_DETAILS:
            return {
                ...state,
                loading: true,
                blankMessage: null,
                // purchaseId:action.payload.purchaseId,
                blankError: null,
                showAddPage: false,
                showDetailPage: true,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_BLANK_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                blankDetails: action.blankDetails,
                blankInvResult: action.blankInvResult,
                showDetailPage: true,
                showAddPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.RESET_BLANK_ACTIVATION:
            return {
                ...state,
                validateResult: [],
                loading: false,
                blankError: null,
                duplicatedSimDataInv: [],
                showButtonUpload: false,
                blankInvResult: null,
                showAddPage: false,
                showDetailPage: false,
                blankMessage: null,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.RESET_BLANK_MESSAGE:
            return {
                ...state,
                blankError: null,
                loading: false,
                blankMessage: null,
                noteError: false
            };
        case actions.PREPARE_ADD_BLANK_FORM:
            return {
                ...state,
                loading: false,
                blankError: null,
                blankMessage: null,
                // showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.PREPARE_ADD_BLANK_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                blankInvResult: action.blankInvResult,
                blankError: null,
                blankMessage: null,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.PREPARE_ADD_BLANK_FORM_FAILED:
            return {
                ...state,
                loading: false,
                blankError: action.blankError,
                blankMessage: null,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        default:
            return state;
    }
}
