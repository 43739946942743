import { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation, Link } from "react-router-dom";
import logo from "../../assets/images/moh-logo-blue.png";
import { useSelector } from 'react-redux';
import { HomeOutlined, UsergroupAddOutlined, FileDoneOutlined, DollarOutlined, ScheduleOutlined, IeOutlined, AppstoreAddOutlined, CloudSyncOutlined, FileSyncOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { isAdmin, isAdminAgent, isDealerAgent } from "../../helpers/commonFunction";
import { UserOutlined, TrademarkOutlined, SettingOutlined, FileTextOutlined } from '@ant-design/icons';

function Sidenav({ color }) {
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { profileDataResult: tokenData } = useSelector((state) => state.auth);


  const menuItems = [
    {
      key: '1',
      label: <NavLink to="/dashboard">
        <span className="icon" style={{ background: page === "dashboard" ? color : "", }} >
          <HomeOutlined />
        </span>
        <span className="label">Dashboard</span>
      </NavLink>
    },
    {
      key: '2',
      icon: <UserOutlined />,
      label: 'ADMIN',
      children: [
        {
          key: '21',
          label: !isAdminAgent(tokenData) && !isDealerAgent(tokenData) ? <NavLink to="/user">
            <span className="icon" style={{ background: page === "user" ? color : "", }} >
              <UsergroupAddOutlined />
            </span>
            <span className="label">User</span>
          </NavLink> : '',
        },
        {
          key: '22',
          label: isAdmin(tokenData) ? <NavLink to="/dealer">
            <span className="icon" style={{ background: page === "dealer" ? color : "", }} >
              <UsergroupAddOutlined />
            </span>
            <span className="label">Dealer</span>
          </NavLink> : '',
        },
        {
          key: '23',
          label: isAdmin(tokenData) ? <NavLink to="/manual-add-fund">
            <span className="icon" style={{ background: page === "manual-add-fund" ? color : "", }} >
              <DollarOutlined />
            </span>
            <span className="label">Manual Add Fund</span>
          </NavLink> : '',
        },
        {
          key: '24',
          label: isAdmin(tokenData) ? <NavLink to="/customer">
            <span className="icon" style={{ background: page === "customer" ? color : "", }} >
              <UsergroupAddOutlined />
            </span>
            <span className="label">Customer</span>
          </NavLink> : '',
        },
      ],
    },
    {
      key: '3',
      icon: <SettingOutlined />,
      label: 'CONFIG',
      children: [
        {
          key: '31',
          label: isAdmin(tokenData) ? <NavLink to="/network">
            <span className="icon" style={{ background: page === "network" ? color : "", }} >
              <IeOutlined />
            </span>
            <span className="label">Network</span>
          </NavLink> : '',
        },
        {
          key: '32',
          label: isAdmin(tokenData) ? <NavLink to="/aggregator">
            <span className="icon" style={{ background: page === "aggregator" ? color : "", }} >
              <AppstoreAddOutlined />
            </span>
            <span className="label">Aggregator</span>
          </NavLink> : '',
        },
        {
          key: '33',
          label: !isAdminAgent(tokenData) && !isDealerAgent(tokenData) ? <NavLink to="/plan">
            <span className="icon" style={{ background: page === "plan" ? color : "", }} >
              <ScheduleOutlined />
            </span>
            <span className="label">Plan</span>
          </NavLink> : '',
        },
        {
          key: '34',
          label: isAdmin(tokenData) ? <NavLink to="/recharge-price-mapping">
            <span className="icon" style={{ background: page === "recharge-price-mapping" ? color : "", }} >
              <DollarOutlined />
            </span>
            <span className="label">Recharge Price Mapping</span>
          </NavLink> : '',
        },
      ],
    },
    {
      key: '4',
      icon: <TrademarkOutlined />,
      label: 'RECHARGE',
      children: [
        {
          key: '41',
          label: <NavLink to="/mobile-recharge">
            <span className="icon" style={{ background: page === "mobile-recharge" ? color : "", }} >
              <ThunderboltOutlined />
            </span>
            <span className="label">Mobile Recharge</span>
          </NavLink>,
        },
      ],
    },
    {
      key: '5',
      icon: <FileTextOutlined />,
      label: 'REPORTS',
      children: [
        {
          key: '51',
          label: <NavLink to="/mobile-recharge-report">
            <span className="icon" style={{ background: page === "mobile-recharge-report" ? color : "", }} >
              <FileDoneOutlined />
            </span>
            <span className="label">Mobile Recharge Report</span>
          </NavLink>,
        },
        {
          key: '52',
          label: !isAdminAgent(tokenData) && !isDealerAgent(tokenData) ? <NavLink to="/audit-report">
            <span className="icon" style={{ background: page === "audit-report" ? color : "", }} >
              <FileDoneOutlined />
            </span>
            <span className="label">Audit Report</span>
          </NavLink> : '',
        },
        {
          key: '53',
          label: !isAdminAgent(tokenData) && !isDealerAgent(tokenData) ? <NavLink to="/commission-report">
            <span className="icon" style={{ background: page === "commission-report" ? color : "", }} >
              <FileDoneOutlined />
            </span>
            <span className="label">Commission Report</span>
          </NavLink> : '',
        },
      ],
    }
  ];

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(menuItems);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <>
      <div className="brand mb-1">
        <Link to="/dashboard"><img src={logo} style={{ height: '100px', cursor: 'pointer' }} alt="Logo" /></Link>
        {/* <span>MOH Connect</span> */}
      </div>

      <Menu
        mode="inline"
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        items={menuItems}
      />

    </>
  );
}

export default Sidenav;