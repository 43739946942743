// This is all common function used in all over the application
import moment from "moment";
import momentTimezone from 'moment-timezone';

export const isAdmin = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany === 1 && tokenData[0].RoleID === 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdminAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany === 1 && tokenData[0].RoleID === 3) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdminOrAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if ((tokenData[0].IsCompany === 1 && tokenData[0].RoleID === 1) ||
            (tokenData[0].IsCompany === 1 && tokenData[0].RoleID === 3)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isAdminOrDealer = (tokenData) => {
    if (tokenData.length > 0) {
        if ((tokenData[0].IsCompany === 1 && tokenData[0].RoleID === 1) ||
            (tokenData[0].IsCompany === 0 && tokenData[0].RoleID === 1)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealer = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany === 0 && tokenData[0].RoleID === 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const isDealerAgent = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany === 0 && tokenData[0].RoleID === 3) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const allowDealerCreation = (tokenData) => {
    if (tokenData.length > 0) {
        if (tokenData[0].IsCompany === 0 && tokenData[0].AllowSellerCreation === 1) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const convertDateToRequiredFormatTZ = (datetime) => {
    if (datetime) {
        try {
            let dateT = moment(new Date(datetime)).tz(process.env.REACT_APP_TIMEZONE).format(process.env.REACT_APP_DATE_FORMAT);
            return dateT;
        } catch (error) {
            console.log('error ', error);
            return null;
        }
    } else {
        return null;
    }
}

export const convertDateTimeToRequiredFormatTZ = (datetime) => {
    if (datetime) {
        try {
            let dateT = moment(new Date(datetime)).tz(process.env.REACT_APP_TIMEZONE).format(process.env.REACT_APP_LONG_TIME_FORMAT);
            return dateT;
        } catch (error) {
            console.log('error ', error);
            return null;
        }
    } else {
        return null;
    }
}

export const formatString = (string) => {
    if (string.length === 0) {
        return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
