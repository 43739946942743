const actions = {
    GET_BULK_ACTIVATION_REPORT_LIST: "GET_BULK_ACTIVATION_REPORT_LIST",
    GET_BULK_ACTIVATION_REPORT_LIST_SUCCESS: "GET_BULK_ACTIVATION_REPORT_LIST_SUCCESS",
    GET_BULK_ACTIVATION_REPORT_LIST_FAILED: "GET_BULK_ACTIVATION_REPORT_LIST_FAILED",

    GET_BULK_ACTIVATION_REPORT_DETAILS_LIST: "GET_BULK_ACTIVATION_REPORT_DETAILS_LIST",
    GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_SUCCESS: "GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_SUCCESS",
    GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_FAILED: "GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_FAILED",

    getBulkActivationReportList: (data) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_LIST,
        payload: { data }
    }),
    getBulkActivationReportListSuccess: (bulkReportResult) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_LIST_SUCCESS,
        bulkReportResult
    }),
    getBulkActivationReportListFailed: (bulkReportError) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_LIST_FAILED,
        bulkReportError
    }),
    getBulkActivationReportDetails: (data) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST,
        payload: { data }
    }),
    getBulkActivationReportDetailsSuccess: (bulkDetailsResult) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_SUCCESS,
        bulkDetailsResult
    }),
    getBulkActivationReportDetailsFailed: (bulkReportError) => ({
        type: actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_FAILED,
        bulkReportError
    }),
}
export default actions;