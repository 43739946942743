const actions = {
    GET_PLAN: 'GET_PLAN',
    GET_PLAN_SUCCESS: 'GET_PLAN_SUCCESS',
    GET_PLAN_FAILED: 'GET_PLAN_FAILED',
    RESET_PLAN_MESSAGE:'RESET_PLAN_MESSAGE',
    DELETE_PLAN: "DELETE_PLAN",
    DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
    DELETE_PLAN_FAILED: "DELETE_PLAN_FAILED",
    GET_PLAN_BY_NETWORK: "GET_PLAN_BY_NETWORK",
    GET_PLAN_BY_NETWORK_SUCCESS: "GET_PLAN_BY_NETWORK_SUCCESS",
    GET_PLAN_BY_NETWORK_FAILED: "GET_PLAN_BY_NETWORK_FAILED",
    
    getPlan: (data) => ({
        type: actions.GET_PLAN,
        payload: {data}
    }),
    getPlanSuccess: (planResult) => ({
        type: actions.GET_PLAN_SUCCESS,
        planResult
    }),
    getPlanFailed: (planError) => ({
        type: actions.GET_PLAN_FAILED,
        planError
    }),
    deletePlan: (planId, status,searchData) => ({
        type: actions.DELETE_PLAN,
        payload: { planId, status,searchData }
    }),
    deletePlanSuccess: (planMessage, planResult) => ({
        type: actions.DELETE_PLAN_SUCCESS,
        planMessage,
        planResult
    }),
    deletePlanFailed: (planError, planResult) => ({
        type: actions.DELETE_PLAN_FAILED,
        planError,
        planResult,
    }),
    getPlanByNetwork: (network) => ({
        type: actions.GET_PLAN_BY_NETWORK,
        network
    }),
    getPlanByNetworkSuccess: (planResult) => ({
        type: actions.GET_PLAN_BY_NETWORK_SUCCESS,
        planResult
    }),
    getPlanByNetworkFailed: (planError) => ({
        type: actions.GET_PLAN_BY_NETWORK_FAILED,
        planError
    }),
    resetPlanMessage:()=>({
        type:actions.RESET_PLAN_MESSAGE,
    })
};
export default actions;
