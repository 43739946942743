import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Input, Table, Modal, Select, Checkbox, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import RechargeRequestActions from '../../redux/rechargeRequest/actions';
import networkActions from '../../redux/network/actions';
import planActions from '../../redux/plan/actions';
import rechargeReportActions from '../../redux/rechargeReport/actions';
import { formatString } from '../../helpers/commonFunction';
import { Image } from "antd";
import ReactCountryFlag from "react-country-flag"
import countryAction from "../../redux/country/actions"
import Item from 'antd/lib/list/Item';

const BASE_URL = process.env.REACT_APP_BASEURL;
const InternationalRechargeForm = ({ searchData }) => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const countryData = useSelector((state) => state.country.countryData);
    const { getCountryInternational } = countryAction;
    const { getNetworkByCountry } = networkActions;
    const { getPlanByNetwork, resetPlanMessage } = planActions;
    const { getRechargeReportList } = rechargeReportActions;
    const { closeRechargeFormInternational, resetRecharge, createRechargeRequestInternational, resetRechargeMessage } = RechargeRequestActions;
    const { rechargeFormModalInternational, createRechargeRequestInternationalLoading, createRechargeRequestInternationalMessage, createRechargeRequestInternationalError } = useSelector((state) => state.rechargeRequest);
    const { planResult, loading } = useSelector((state) => state.plan);
    const networkData = useSelector((state) => state.network.networkData);
    const [formTitle, setFormTitle] = useState('ADD RECHARGE');
    const [formButton, setFormButton] = useState('SUBMIT');
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectednetwork, setSelectedNetwork] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [priceOptions, setPriceOptions] = useState([]);
    const [networkList, setNetworkList] = useState([]);


    useEffect(() => {
        dispatch(getCountryInternational())
    }, []);

    useEffect(() => {
        if (networkList.length === 1) {
            form.setFieldsValue({ network: networkList[0].ID });
        }
        else {
            form.setFieldsValue({ network: undefined });
        }
    }, [networkList, form]);

    useEffect(() => {
        setNetworkList(networkData && networkData)
    }, [networkData]);

    useEffect(() => {
        if (planResult) {
            if (planResult.length === 1) {
                const singlePlan = planResult[0];
                setSelectedPlan(singlePlan);
                form.setFieldsValue({
                    plan: singlePlan.ID,
                    planPrice: singlePlan.IsRange === 0 ? `$${singlePlan.PlanPrice}` : null,
                });
                if (singlePlan.IsRange !== 0) {
                    generatePriceOptions(singlePlan);
                }
            } else {
                setSelectedPlan(null);
                form.setFieldsValue({ planPrice: null });
            }
        }
    }, [planResult]);

    useEffect(() => {
        if (countryData && countryData.length === 1) {
            const country = countryData[0];
            setSelectedCountry(country.ID);
            form.setFieldsValue({ CountryID: country.ID });
            dispatch(getNetworkByCountry(country.ID));
        }
    }, [countryData, dispatch, form, getNetworkByCountry]);


    const handleChangeCountry = (value) => {
        setSelectedCountry(value)
        setSelectedNetwork(null)
        dispatch(getNetworkByCountry(value));
    }

    const handleChange = (value) => {
        setSelectedNetwork(value)
        setSelectedPlan(null);
        form.setFieldsValue({ plan: null, planPrice: null });
        dispatch(getPlanByNetwork(value));
    };

    const handleCancel = () => {
        dispatch(resetRecharge());
        dispatch(resetPlanMessage());
        dispatch(closeRechargeFormInternational());
        setSelectedPlan(null);
        form.resetFields()
        setSelectedNetwork(null)
    };

    const handleSubmit = (data) => {
        const { CountryID, ...filteredData } = data;
        dispatch(createRechargeRequestInternational(filteredData))
        dispatch(resetRecharge());
        handleCancel()
    };


    const handleChangePlan = (value) => {
        const selectedPlan = planResult.find(plan => plan.ID === value);
        setSelectedPlan(selectedPlan)
        form.setFieldsValue({ planPrice: null });
        if (selectedPlan && selectedPlan.IsRange === 0) {
            form.setFieldsValue({ planPrice: `$${selectedPlan.PlanPrice}` });
        }
    };
    const generatePriceOptions = (plan) => {
        const min = plan.MinTopUpAmount;
        const max = plan.MaxTopUpAmount;
        const step = 5;
        const options = [];
        options.push({ label: `$${min.toFixed(2)}`, value: min.toFixed(2) });
        for (let i = Math.ceil(min / step) * step; i < max; i += step) {
            if (i > min && i < max) {
                options.push({ label: `$${i.toFixed(2)}`, value: i.toFixed(2) });
            }
        }
        options.push({ label: `$${max.toFixed(2)}`, value: max.toFixed(2) });
        setPriceOptions(options);
    };


    const validatePlanPrice = (_, value) => {
        if (selectedPlan === null) {
            return Promise.reject(new Error(formatString(`please input Plan Price!`)));
        }
        if (value < selectedPlan.MinTopUpAmount || value > selectedPlan.MaxTopUpAmount) {
            return Promise.reject(new Error(formatString(`Plan Price must be between $${selectedPlan.MinTopUpAmount} and $${selectedPlan.MaxTopUpAmount}!`)));
        }
        return Promise.resolve();
    };

    const options2 = planResult && planResult.map(plan => ({
        label: plan.PlanName,
        value: plan.ID,
    }));

    const success = (text) => {
        form.resetFields();
        message.success(text);
        dispatch(getRechargeReportList(searchData));
        dispatch(resetRechargeMessage());
    };

    const error = (text) => {
        form.resetFields();
        message.error(text);
        dispatch(resetRechargeMessage());
        dispatch(getRechargeReportList(searchData));
    };

    const handleChangeMsisdn = (e) => {
        const num = e.target.value
        if (num.length <= 3) {
            const filteredItems = networkData && networkData.length > 0 ? networkData.filter(item => {
                const startNumber = item.StartNumber.split(',');
                const numberArray = startNumber.map(Number);
                const isPresent = numberArray.includes(Number(num));
                return isPresent;
            }) : [];

            if (filteredItems.length > 0) {
                const newList = [filteredItems[0]]
                setNetworkList(newList);
                if (newList.length == 1) {
                    handleChange(newList[0].ID)
                }
                else {
                    handleChange(newList[0].ID)
                }
            }

        }
    }

    return (
        <>
            {createRechargeRequestInternationalMessage !== null && createRechargeRequestInternationalMessage !== undefined && success(createRechargeRequestInternationalMessage)}
            {createRechargeRequestInternationalError !== null && createRechargeRequestInternationalError !== undefined && error(createRechargeRequestInternationalError)}
            <Modal
                title={formTitle}
                style={{ top: 20 }}
                width={500}
                visible={rechargeFormModalInternational}
                onCancel={handleCancel}
                closable={true}
                destroyOnClose={true}
                maskClosable={false}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        CANCEL
                    </Button>,
                    <Button form="userForm" key="submit" loading={createRechargeRequestInternationalLoading} htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} >
                        {formButton}
                    </Button>,
                ]}
            >
                <Form id="userForm" layout="vertical" className="row-col" onFinish={handleSubmit} form={form} >
                    <Row gutter={[16, 0]}>

                        {/* <Col span={24} >
                            <Form.Item label='Country' className="CountryID" name="CountryID" rules={[{ required: true, message: formatString("Please Select Country!"), }]}>

                                <Select className="ant-select sect-nnn" showSearch optionFilterProp="children" placeholder="Select Country" name="country" onChange={handleChangeCountry} >
                                    <Select.Option value={''} key={''}>Select</Select.Option>
                                    {countryData && countryData.length > 0 && countryData.map(country => (
                                        <Select.Option value={country.ID} key={country.ID} ><ReactCountryFlag countryCode={country.ISO2} style={{ width: "20px", height: "20px" }} svg /> {country.CountryName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item
                                label='Country'
                                className="CountryID"
                                name="CountryID"
                                rules={[{ required: true, message: "Please Select Country!" }]}
                            >
                                <Select
                                    className="ant-select sect-nnn"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Select Country"
                                    name="country"
                                    onChange={handleChangeCountry}
                                    value={countryData.length === 1 ? countryData[0].ID : undefined}
                                >
                                    <Select.Option value={''} key={''}>Select</Select.Option>
                                    {countryData && countryData.length > 0 && countryData.map(country => (
                                        <Select.Option value={country.ID} key={country.ID}>
                                            <ReactCountryFlag countryCode={country.ISO2} style={{ width: "20px", height: "20px" }} svg /> {country.CountryName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                className="username"
                                label="MSISDN"
                                name="msisdn"
                                rules={[
                                    {
                                        required: true,
                                        message: formatString("Please input MSISDN!"),
                                    },
                                    {
                                        pattern: /^\d{10}$/,
                                        message: formatString("MSISDN must be exactly 10 digits!"),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={handleChangeMsisdn}
                                    placeholder="Enter MSISDN"
                                    maxLength={10}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="network"
                                label="Network"
                                rules={[{ required: true, message: 'Please Select Network!' }]}
                            >
                                <Select
                                    rules={[{ required: true, message: 'Please Select Network!' }]}
                                    className="ant-select sect-nnn"
                                    // showSearch
                                    optionFilterProp="children"
                                    placeholder="Select Network"
                                    name="network"
                                    onSelect={handleChange}
                                    onChange={handleChange}
                                    value={networkList.length === 1 ? networkList[0].ID : undefined}
                                >
                                    <Option value="" key="0">Select</Option>
                                    {networkData && networkData.length > 0 && networkData.map(network => (
                                        <Option name="network" value={network.ID} key={network.ID}>
                                            <Image
                                                preview={false}
                                                src={`${BASE_URL}/images/network/${network.NetworkLogoURL}?${Date.now()}`}
                                                width={55}
                                                style={{ marginRight: "7px" }}
                                            />
                                            <span style={{ marginLeft: "5px" }}>{network.NetworkName}</span>
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item
                                className="username"
                                name="plan"
                                label="Plan"
                                span={24}
                                rules={[{ required: true, message: formatString('Please Select Plan !') }]}
                            >
                                <Select
                                    placeholder="Plan"
                                    options={selectednetwork ? options2 : ''}
                                    onChange={handleChangePlan}
                                    loading={loading}
                                    disabled
                                />
                            </Form.Item>
                        </Col>


                        {/* <Col span={24}>
                            <Form.Item
                                className="username"
                                label="Plan"
                                name="plan"
                                rules={[{ required: true, message: formatString('Please Select Plan !') }]}
                            >
                                <Input
                                    placeholder="Plan"
                                    value={planResult && planResult.length > 0 ? planResult[0].PlanName : ''}
                                    disabled
                                    type='text'
                                />
                                <div>{selectedPlan ? selectedPlan.PlanName : ''}</div>
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item
                                className="username"
                                label="Plan Price"
                                name="planPrice"
                                rules={[
                                    {
                                        required: true,
                                        validator: validatePlanPrice,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Enter Plan Price"
                                    options={priceOptions}
                                    disabled={!selectedPlan || selectedPlan.IsRange === 0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                className="username"
                                label="Sender Phone Number"
                                name="senderPhonNumber"
                                rules={[
                                    {
                                        message: formatString("Please input Phone Number!"),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Phone Number"
                                    maxLength={20}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal></>

    );
};

export default InternationalRechargeForm;