import actions from './actions';
const initState = {
    error: null,
    message: null,
    loading: false,
    token: null,
    openModel: false,
    passwordMessage: null,
    passwordError: null,
    profileError: null,
    profileDataResult: [],
    userData: [],
    jarLoading: false,
    jarMessage: null
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                profileError: null
            };
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.token,
                loading: false,
                profileError: null
            }
        case actions.LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                profileError: null
            }
        case actions.LOGOUT_REQUEST:
            return {
                ...state,
                message: null,
                error: null,
                loading: true
            };
        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                error: null,
                message: null,
                loading: false
            };
        case actions.LOGOUT_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false,
                message: null,
            };
        case actions.GET_POFILE_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_POFILE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                profileDataResult: action.profileDataResult,
                profileError:null
            };
        case actions.GET_POFILE_DATA_FAILED:
            return {
                ...state,
                loading: false,
                profileError: action.profileError,
                profileMessage: null,
                buttonLoading: false,

            };
        case actions.CHANGE_PASSWORD:
            return {
                ...state,
                loading: false,
                buttonLoading: true,
                profileError:null
            };
        case actions.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordMessage: action.passwordMessage,
                buttonLoading: false,
                openModel: false,
                profileError:null
            };
        case actions.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                passwordError: action.passwordError,
                buttonLoading: false,
                openModel: true,
                profileError:null
            };
        case actions.PASSWORD_FORM_MODEL:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                openModel: true,
                profileError:null
            };
        case actions.RESET_AUTH_ERROR:
            return {
                ...state,
                error: null,
                loading: false,
                message: null,
                passwordMessage: null,
                passwordError: null,
                profileError: null,
                openModel: false,

            };
        case actions.RESET_AUTH_MESSAGE:
            return {
                ...state,
                error: null,
                loading: false,
                message: null,
                passwordMessage: null,
                passwordError: null,
                profileError: null,

            };
        case actions.UPDATE_JAR_STATUS:
            return {
                ...state,
                error: null,
                loading: false,
                message: null,
                passwordMessage: null,
                passwordError: null,
                profileError: null,
                jarLoading: true
            };
        case actions.UPDATE_JAR_STATUS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                message: null,
                passwordMessage: null,
                passwordError: null,
                profileError: null,
                jarLoading: false,
                jarMessage: action.jarMessage
            };
        case actions.UPDATE_JAR_STATUS_FAILED:
            return {
                ...state,
                passwordError: action.error,
                loading: false,
                message: null,
                passwordMessage: null,
                profileError: null,
                jarLoading: false,
                jarMessage: null
            };

        default:
            return state;
    }
};