const actions = {
    OPEN_AGGREGATOR_MODAL: "OPEN_AGGREGATOR_MODAL",
    ADD_AGGREGATOR: "ADD_AGGREGATOR",
    ADD_AGGREGATOR_SUCCESS: "ADD_AGGREGATOR_SUCCESS",
    ADD_AGGREGATOR_FAILED: "ADD_AGGREGATOR_FAILED",
    GET_AGGREGATOR: "GET_AGGREGATOR",
    GET_AGGREGATOR_SUCCESS: "GET_AGGREGATOR_SUCCESS",
    GET_AGGREGATOR_FAILED: "GET_AGGREGATOR_FAILED",
    RESET_AGGREGATOR: "RESET_AGGREGATOR",

    UPDATE_AGGREGATOR: "UPDATE_AGGREGATOR",
    UPDATE_AGGREGATOR_SUCCESS: "UPDATE_AGGREGATOR_SUCCESS",
    UPDATE_AGGREGATOR_FAILED: "UPDATE_AGGREGATOR_FAILED:",
    GET_PREFFERED_AGGREGATOR: 'GET_PREFFERED_AGGREGATOR',
    GET_PREFFERED_AGGREGATOR_SUCCESS: 'GET_PREFFERED_AGGREGATOR_SUCCESS',
    GET_PREFFERED_AGGREGATOR_FAILED: ' GET_PREFFERED_AGGREGATOR_FAILED',

    getPreffedAggregatorList: (networkId) => ({
        type: actions.GET_PREFFERED_AGGREGATOR,
        payload: { networkId }
    }),
    getPreffedAggregatorListSuccess: (AggregatorList) => ({
        type: actions.GET_PREFFERED_AGGREGATOR_SUCCESS,
        AggregatorList
    }),
    getPreffedAggregatorListFailed: (error) => ({
        type: actions.GET_PREFFERED_AGGREGATOR_FAILED,
        error
    }),

    getAggregator: () => ({
        type: actions.GET_AGGREGATOR,
    }),
    getAggregatorSuccess: (data) => ({
        type: actions.GET_AGGREGATOR_SUCCESS,
        data
    }),
    getAggregatorFailed: (error) => ({
        type: actions.GET_AGGREGATOR_FAILED,
        error
    }),
    openAddAggregatorModal: () => ({
        type: actions.OPEN_AGGREGATOR_MODAL,
    }),
    addAggregator: (data) => ({
        type: actions.ADD_AGGREGATOR,
        payload: { data }
    }),
    addAggregatorSuccess: (message, regionResult) => ({
        type: actions.ADD_AGGREGATOR_SUCCESS,
        message, regionResult
    }),
    addAggregatorFailed: (error) => ({
        type: actions.ADD_AGGREGATOR_FAILED,
        error
    }),
    resetAggregator: () => ({
        type: actions.RESET_AGGREGATOR,
    }),
    
    updateAggregator: (data) => ({
        type: actions.UPDATE_AGGREGATOR,
        payload: { data }
    }),
    updateAggregatorSuccess: (message) => ({
        type: actions.UPDATE_AGGREGATOR_SUCCESS,
        message
    }),
    updateAggregatorFailed: (error) => ({
        type: actions.UPDATE_AGGREGATOR_FAILED,
        error
    }),

    getActiveAggregator: () => ({
        type: actions.GET_ACTIVE_AGGREGATOR,
    }),
    getActiveAggregatorSuccess: (data) => ({
        type: actions.GET_ACTIVE_AGGREGATOR_SUCCESS,
        data
    }),
    getActiveAggregatorFailed: (error) => ({
        type: actions.GET_ACTIVE_AGGREGATOR_FAILED,
        error
    }),

}
export default actions;