import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table, Row, Col, Input, Form, Modal, Tag, Space, message, Popconfirm, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CheckOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import userActions from '../../redux/user/actions';
import withTokenValidation from '../../withTokenValidation';
import { isAdmin, formatString } from '../../helpers/commonFunction';
import { PASSWORD_VALIDATE } from '../../helpers/constant'


const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        width: "5%",
    },
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "USERNAME",
        key: "username",
        dataIndex: "username",
    },
    {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
    },
    {
        title: "ROLE",
        key: "role",
        dataIndex: "role",
    },
    {
        title: "CREATED DATE",
        key: "createDate",
        dataIndex: "createDate",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },

];

function User() {
    const dispatch = useDispatch();
    const { getUser, addUser, editUser, deleteUser, userPasswordReset, resetUser, prepareUserForm, preparePasswordForm, resetUserMessage } = userActions;
    const { userResult, userMessage, loading, userError, buttonLoading, userPasswordModal, userFormModal } = useSelector((state) => state.user);
    const { profileDataResult: tokenData } = useSelector((state) => state.auth);
    const [userFormData, setUserFormData] = useState({
        name: "",
        username: "",
        password: "",
        roleId: 3,
    });
    const [passwordData, setPasswordData] = useState({
        newPassword: "",
        userId: null
    })

    const [formTitle, setFormTitle] = useState('ADD USER');
    const [formButton, setFormButton] = useState('SUBMIT');
    const [editFlag, setEditFlag] = useState(false);
    const [editId, setEditId] = useState();

    const handleChange = (e) => {
        dispatch(prepareUserForm());
        const fname = e.target.name;
        const fvalue = e.target.value;
        setUserFormData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }));
    }

    useEffect(() => {
        dispatch(getUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handleAddForm = () => {
        setEditFlag(false);
        setUserFormData(prevState => ({
            ...prevState,
            name: '',
            username: '',
            password: '',
            confirmPassword: "",
            roleId: 3,
        }));
        setFormTitle('ADD USER');
        setFormButton('SUBMIT');
        dispatch(prepareUserForm());
    }

    const handleEditForm = (userId) => {
        var selectedUser = userResult.filter(user => user.ID === userId);
        setUserFormData(prevState => ({
            ...prevState,
            name: selectedUser[0].Name,
            username: selectedUser[0].Username,
            roleId: selectedUser[0].RoleID
        }));
        dispatch(prepareUserForm());
        setEditFlag(true);
        setEditId(userId);
    }

    const handleStatusChange = (userId, userStatus) => {
        dispatch(deleteUser(userId, userStatus));
    }

    const handleSubmit = () => {
        dispatch(addUser(userFormData));
    }
    const handleUpdate = () => {
        dispatch(editUser(editId, userFormData));
    }

    const handleCancel = () => {
        dispatch(resetUser());
    }

    const dataSource = userResult && userResult.length > 0 && userResult.map((user, key) => {
        return {
            key: key + 1,
            name: user.Name,
            username: user.Username,
            role: user.UserRole,
            createDate: moment(new Date(user.CreatedDtTm)).format(process.env.REACT_APP_DATE_FORMAT),
            status: <Tag color={user.IsActive === 1 ? "#1d89ca" : "#f50"} className="draft">{user.IsActive === 1 ? 'Active' : 'Inactive'}</Tag>,
            action: user.RoleID === 3 ? <Space style={{ width: '100%' }}>

                <Tooltip title="Edit">
                    <Button type="default" icon={<EditOutlined />} size="large" onClick={() => { setFormTitle('EDIT USER'); setFormButton('UPDATE'); handleEditForm(user.ID); }} />
                </Tooltip>
                    {user.IsActive === 1 ? (
                        <Tooltip title="Inactive">
                        <Popconfirm
                            title="Are you sure to inactive this user?"
                            okText="Yes"
                            placement="left"
                            cancelText="No"
                            onConfirm={() => confirmAction(user.ID, 0)}
                        >
                            <Button
                                type="danger"
                                ghost={true}
                                icon={<DeleteOutlined />}
                                size="large"
                            />
                        </Popconfirm>
                           </Tooltip>
                        
                    ) : (
                        <Tooltip title="Active">
                        <Popconfirm
                            title="Are you sure to active this user?"
                            okText="Yes"
                            placement="left"
                            cancelText="No"
                            onConfirm={() => confirmAction(user.ID, 1)}
                        >
                            <Button
                                className="prop-btn-toggle"
                                icon={<CheckOutlined />}
                                size="large"
                            />
                        </Popconfirm>
                        </Tooltip>
                        
                    )}
             

                <Tooltip title="Reset Password">
                    <Button type="primary" ghost={true} icon={<LockOutlined />} size="large" onClick={() => handleOpenPasswordFormModal(user.ID)}></Button>
                </Tooltip>
            </Space> : ''
        }
    });

    const handleOpenPasswordFormModal = (userId) => {
        dispatch(preparePasswordForm());
        setPasswordData(prevState => ({
            ...prevState,
            newPassword: "",
            userId: userId
        }));
    }

    const handlePassword = (e) => {
        dispatch(preparePasswordForm());
        const fname = e.target.name;
        const fvalue = e.target.value;
        setPasswordData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }));
    }

    const handleSubmitResetPassword = () => {
        dispatch(userPasswordReset(passwordData))

    }

    const success = (text) => {
        message.success(text);
        dispatch(resetUserMessage()); // Reset messages after success message
    };

    const error = (text) => {
        message.error(text);
        dispatch(resetUserMessage()); // Reset messages after error message
    };

    const confirmAction = (id, status) =>
        new Promise((resolve) => {
            setTimeout(() => {
                handleStatusChange(id, status);
                resolve(null);
            }, 2000);
        });


    return (
        <>
            {userMessage !== null && userMessage !== undefined && success(userMessage)}
            {userError !== null && userError !== undefined && error(userError)}
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24} md={24} lg={24} sm={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="User"
                            extra={
                                <>
                                    <Button style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }} onClick={handleAddForm}><PlusOutlined />ADD USER</Button>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination
                                    loading={loading}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Modal title={formTitle} style={{ top: 20 }} width={800} visible={userFormModal} onOk={handleCancel} onCancel={handleCancel} closable={true} destroyOnClose={true} maskClosable={false}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            CANCEL
                        </Button>,
                        <Button form="userForm" key="submit" htmlType="submit" loading={buttonLoading} style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} >
                            {formButton}
                        </Button>,
                    ]}>
                    <Form id="userForm" layout="vertical" className="row-col" onFinish={editFlag ? handleUpdate : handleSubmit} initialValues={userFormData}>
                        <Row gutter={[16, 0]}>
                            <Col span={12} >
                                <Form.Item className="username" label="Name" name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: formatString("Please input your name!"),
                                        }
                                    ]}>
                                    <Input placeholder="Name" name="name" onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item className="username" label="Username" name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: formatString("Please input your username!"),
                                        },
                                        {
                                            whitespace: true,
                                            message: formatString('White or empty space is not allowed !.')
                                        }, {
                                            // pattern: new RegExp("^[a-zA-Z0-9_]*$"),
                                            message: formatString('Please use only letters (a-z) (A-Z),numbers, _underscore')
                                        }
                                    ]}>
                                    <Input placeholder="Username" name="username" onChange={handleChange} onKeyDown={handleKeyDown} disabled={editFlag} />
                                </Form.Item>
                            </Col>

                            {!editFlag && <><Col span={12} ><Form.Item className="username" label="Password" name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: formatString("Please input your password!"),
                                    },
                                    {
                                        min: 8,
                                        pattern: PASSWORD_VALIDATE,
                                        message: formatString('Invalid password format(Example@123) !')
                                    }
                                ]}
                                hasFeedback>
                                <Input.Password addonBefore={<LockOutlined />} placeholder="Password" name="password" onChange={handleChange} />
                            </Form.Item>
                            </Col>
                                <Col span={12} >
                                    <Form.Item className="username" label="Confirm Password" name="confirmPassword" dependencies={['password']} hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: formatString("Please confirm your password!"),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(formatString('Confirm password does not match!')));
                                                },
                                            }),
                                        ]}>
                                        <Input.Password addonBefore={<LockOutlined />} placeholder="Confirm Password" name="confirmPassword" onChange={handleChange} />
                                    </Form.Item></Col></>}
                        </Row>
                    </Form>
                </Modal>
                {/* password reser modal */}
                <Modal title="RESET PASSWORD" visible={userPasswordModal} onOk={handleCancel} onCancel={handleCancel} closable={true} destroyOnClose={true} maskClosable={false} centered footer={[
                    <Button key="back" onClick={handleCancel}>
                        CANCEL
                    </Button>,
                    <Button form="passwordForm" key="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} htmlType="submit" type="primary" loading={buttonLoading}>
                        UPDATE
                    </Button>,
                ]}>
                    <Form id="passwordForm" layout="vertical" className="row-col" onFinish={handleSubmitResetPassword} >
                        <Form.Item className="username" label="New Password" name="newPassword" rules={[{ required: true, message: "Please input new password!", }, {
                            min: 8,
                            pattern: PASSWORD_VALIDATE,
                            message: 'Invalid password format(Example@123) !'
                        }]}>
                            <Input.Password addonBefore={<LockOutlined />} placeholder="New Password" name="newPassword" hasFeedback onChange={handlePassword} />
                        </Form.Item>
                        <Form.Item className="username" label="Confirm Password" name="confirmPassword" dependencies={['newPassword']} hasFeedback rules={[
                            { required: true, message: "Please input confirm password!" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Confirm password does not match!'));
                                },
                            })]}>
                            <Input.Password addonBefore={<LockOutlined />} placeholder="Confirm Password" name="confirmPassword" onChange={handlePassword} />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    )
}

export default withTokenValidation(User);