import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table, Row, Col, Input, Form, Modal, Tag, Space, message, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, CheckOutlined, UnlockOutlined } from "@ant-design/icons";
import moment from "moment";
import customerActions from '../../redux/customer/actions';
import { useEffect } from 'react';
import withTokenValidation from '../../withTokenValidation';

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
    },
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "MOBILE NUMBER",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
    },
    {
        title: "CREATED DATE",
        key: "createDate",
        dataIndex: "createDate",
    },
    {
        title: "STATUS",
        key: "status",
        dataIndex: "status",
    },
    {
        title: "ACTION",
        key: "action",
        dataIndex: "action",
    },
];

function Customer() {
    const dispatch = useDispatch();
    const { getCustomer, resetCustomer, addCustomer, editCustomer, deleteCustomer, prepareCustomerForm, resetCustomerMessage, releaseCustomer: releaseCustomerAction } = customerActions;
    const loading = useSelector((state) => state.customer.loading);
    const customerResult = useSelector((state) => state.customer.customerResult);
    const customerMessage = useSelector((state) => state.customer.customerMessage);
    const customerError = useSelector((state) => state.customer.customerError);
    const buttonLoading = useSelector((state) => state.customer.buttonLoading);
    const customerFormModal = useSelector((state) => state.customer.customerFormModal);
    const [customerFormData, setCustomerFormData] = useState({
        name: "",
        contactEmailID: "",
        contactNumber: "",
        address: "",
        countryID: "",
    });
    const [formTitle, setFormTitle] = useState('Add Customer');
    const [formButton, setFormButton] = useState('SUBMIT');
    const [editFlag, setEditFlag] = useState(false);
    const [editId, setEditId] = useState();

    useEffect(() => {
        dispatch(getCustomer());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (e) => {
        dispatch(prepareCustomerForm());
        const fname = e.target.name;
        const fvalue = e.target.value;

        setCustomerFormData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }));
    }

    const handleAddForm = () => {
        setEditFlag(false);
        setCustomerFormData(prevState => ({
            ...prevState,
            name: "",
            contactEmailID: "",
            contactNumber: "",
            address: "",
            countryID: "",
        }));
        setFormTitle('Add Customer');
        setFormButton('SUBMIT');
        dispatch(prepareCustomerForm());
    }

    const handleEditForm = (customerId) => {
        var selectedCustomer = customerResult.filter(customer => customer.ID === customerId);
        setCustomerFormData(prevState => ({
            ...prevState,
            name: selectedCustomer[0].Name,
        }));
        dispatch(prepareCustomerForm());
        setEditFlag(true);
        setEditId(customerId);
    }

    const handleStatusChange = (customerId, Status) => {
        dispatch(deleteCustomer(customerId, Status));
    }

    const handleSubmit = () => {
        dispatch(addCustomer(customerFormData));
    }

    const handleUpdate = () => {
        dispatch(editCustomer(editId, customerFormData));
    }

    const handleCancel = () => {
        dispatch(resetCustomer());
    }

    const confirmAction = (id, status) => {
        new Promise((resolve) => {
            setTimeout(() => {
                handleStatusChange(id, status);
                resolve(null);
            }, 2000);
        });
    }

    const releaseCustomer = (customer) => {
        let selectedCustomer = customerResult.filter((item) => item.ID == customer.ID)

        let request = {
            emailId: selectedCustomer[0].Email
        }
        dispatch(releaseCustomerAction(request))
    }

    const dataSource = customerResult && customerResult.length > 0 && customerResult.map((customer, key) => {
        return {
            key: key + 1,
            name: customer.Name,
            email: customer.Email,
            mobileNumber: customer.MobileNumber,
            createDate: moment(new Date(customer.CreatedDtTm)).format(process.env.REACT_APP_DATE_FORMAT),
            status: <Tag color={customer.IsActive === 1 ? "#87d068" : "#f50"} className="draft">{customer.IsActive === 1 ? 'Active' : 'Inactive'}</Tag>,
            action: <Space style={{ width: '100%' }}>
                {customer.IsActive === 1 ? (
                    <Tooltip title="Inactive">
                        <Popconfirm
                            title="Are you sure to inactive this customer?"
                            okText="Yes"
                            placement="left"
                            cancelText="No"
                            onConfirm={() => confirmAction(customer.ID, 0)}
                        >
                            <Button
                                type="danger"
                                ghost={true}
                                icon={<DeleteOutlined />}
                                size="large"
                            />
                        </Popconfirm>
                    </Tooltip>
                ) : (
                    <Tooltip title="Active">
                        <Popconfirm
                            title="Are you sure to active this customer?"
                            okText="Yes"
                            placement="left"
                            cancelText="No"
                            onConfirm={() => confirmAction(customer.ID, 1)}
                        >
                            <Button
                                className="prop-btn-toggle"
                                icon={<CheckOutlined />}
                                size="large"
                            />
                        </Popconfirm>
                    </Tooltip>
                )}

                {/* <Tooltip title="Release">
                    <Button
                        className="prop-btn-toggle"
                        icon={<UnlockOutlined />}
                        size="large"
                        onClick={() => releaseCustomer(customer)}
                    />
                </Tooltip> */}

            </Space>
        }
    });

    const success = (text) => {
        message.success(text);
        dispatch(resetCustomerMessage()); // Reset messages after success message
    };

    const error = (text) => {
        message.error(text);
        dispatch(resetCustomerMessage()); // Reset messages after error message
    };

    return (
        <>
            {customerMessage !== null && customerMessage !== undefined && success(customerMessage)}
            {customerError !== null && customerError !== undefined && error(customerError)}
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24} md={24} lg={24} sm={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Customer"

                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination
                                    loading={loading}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Modal title={formTitle} style={{ top: 20 }} visible={customerFormModal} onOk={handleCancel} onCancel={handleCancel} closable={true} destroyOnClose={true} maskClosable={false}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            CANCEL
                        </Button>,
                        <Button form="userForm" key="submit" htmlType="submit" loading={buttonLoading} type="ghost" style={{ backgroundColor: '#09a418', color: 'white', border: 'none' }} >
                            {formButton}
                        </Button>,
                    ]}>
                    <Form id="userForm" layout="vertical" className="row-col" onFinish={editFlag ? handleUpdate : handleSubmit} initialValues={customerFormData}>
                        <Row gutter={[16, 0]}>
                            <Col span={24} >
                                <Form.Item className="username" label="Name" name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your name!",
                                        }
                                    ]}>
                                    <Input placeholder="Name" name="name" onChange={handleChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </>

    )
}

export default withTokenValidation(Customer);