import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getBulkActivationReportListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getBulkActivationReport', data);
        if (response.response.Status) {
            yield put(
                actions.getBulkActivationReportListSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getBulkActivationReportListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getBulkActivationReportListFailed(error));
    }
}

function* getBulkActivationReportDetailsResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getActivationDetails', data);
        if (response.response.Status) {
            yield put(
                actions.getBulkActivationReportDetailsSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getBulkActivationReportDetailsFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getBulkActivationReportDetailsFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_BULK_ACTIVATION_REPORT_LIST, getBulkActivationReportListResponse)]);
    yield all([takeEvery(actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST, getBulkActivationReportDetailsResponse)]);
}