import React from 'react'
import { Card } from 'antd';
import { StopOutlined } from "@ant-design/icons";
function AccessDenied() {
      return (
            <div className='card-Style-403-parent'>
                  <Card className='text-center card-Style-403'>
                        <StopOutlined style={{ fontSize: '56px', color: 'red', marginBottom: '20px' }} />
                        <h2> 403 - ACCESS DENIED </h2>
                        <h2> Oops, you don't have permission to access this page. </h2>
                        <h3> Please check your credential and try again.</h3>
                  </Card>
            </div>
      )
}
export default AccessDenied;