const actions = {
    GET_NETWORK: 'GET_NETWORK',
    GET_NETWORK_SUCCESS: 'GET_NETWORK_SUCCESS',
    GET_NETWORK_FAILED: 'GET_NETWORK_FAILED',
    ADD_NETWORK: "ADD_NETWORK",
    ADD_NETWORK_SUCCESS: "ADD_NETWORK_SUCCESS",
    ADD_NETWORK_FAILED: "ADD_NETWORK_FAILED",
    DELETE_NETWORK: "DELETE_NETWORK",
    DELETE_NETWORK_SUCCESS: "DELETE_NETWORK_SUCCESS",
    EDIT_NETWORK: "EDIT_NETWORK",
    EDIT_NETWORK_SUCCESS: "EDIT_NETWORK_SUCCESS",
    RESET_NETWORK: "RESET_NETWORK",
    RESET_NETWORK_MESSAGE: "RESET_NETWORK_MESSAGE",
    SHOW_NETWORK_MODAL: "SHOW_NETWORK_MODAL",
    CLOSE_NETWORK_MODAL: "CLOSE_NETWORK_MODAL",
    GET_ACTIVE_NETWORK: 'GET_ACTIVE_NETWORK',
    GET_ACTIVE_NETWORK_SUCCESS: 'GET_ACTIVE_NETWORK_SUCCESS',
    GET_ACTIVE_NETWORK_FAILED: ' GET_ACTIVE_NETWORK_FAILED',
    SHOW_PREF_AGGREGATOR_MODAL: 'SHOW_PREF_AGGREGATOR_MODAL',
    CLOSE_PREF_AGGREGATOR_MODAL: 'CLOSE_PREF_AGGREGATOR_MODAL',
    UPDATE_PREFFERED_AGGREGATOR: "UPDATE_PREFFERED_AGGREGATOR",
    UPDATE_PREFFERED_AGGREGATOR_SUCCESS: "UPDATE_PREFFERED_AGGREGATOR_SUCCESS",
    UPDATE_PREFFERED_AGGREGATOR_FAILED: "UPDATE_PREFFERED_AGGREGATOR_FAILED",
    GET_PREFFERED_AGGREGATOR_BY_NETWORK: 'GET_PREFFERED_AGGREGATOR_BY_NETWORK',
    GET_PREFFERED_AGGREGATOR_BY_NETWORK_SUCCESS: 'GET_PREFFERED_AGGREGATOR_BY_NETWORK_SUCCESS',
    GET_PREFFERED_AGGREGATOR_BY_NETWORK_FAILED: ' GET_PREFFERED_AGGREGATOR_BY_NETWORK_FAILED',
    GET_NETWORK_BY_COUNTRY: 'GET_NETWORK_BY_COUNTRY',
    GET_NETWORK_BY_COUNTRY_SUCCESS: 'GET_NETWORK_BY_COUNTRY_SUCCESS',
    GET_NETWORK_BY_COUNTRY_FAILED: ' GET_NETWORK_BY_COUNTRY_FAILED',
    GET_NETWORKS_BY_COUNTRY_ID: 'GET_NETWORKS_BY_COUNTRY_ID',
    GET_NETWORKS_BY_COUNTRY_ID_SUCCESS: 'GET_NETWORKS_BY_COUNTRY_ID_SUCCESS',
    GET_NETWORKS_BY_COUNTRY_ID_FAILED: 'GET_NETWORKS_BY_COUNTRY_ID_FAILED',
    GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK: 'GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK',
    GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_SUCCESS: 'GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_SUCCESS',
    GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_FAILED: 'GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_FAILED',

    getNetworksByCountryIDForNetwork: (countryID) => ({
        type: actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK,
        payload: { countryID }
    }),
    getNetworksByCountryIDForNetworkSuccess: (data) => ({
        type: actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_SUCCESS,
        payload: { data }
    }),
    getNetworksByCountryIDForNetworkFailed: (error) => ({
        type: actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_FAILED,
        payload: { error }
    }),

    getNetworksByCountryID: (countryID) => ({
        type: actions.GET_NETWORKS_BY_COUNTRY_ID,
        payload: { countryID }
    }),
    getNetworksByCountryIDSuccess: (data) => ({
        type: actions.GET_NETWORKS_BY_COUNTRY_ID_SUCCESS,
        payload: { data }
    }),
    getNetworksByCountryIDFailed: (error) => ({
        type: actions.GET_NETWORKS_BY_COUNTRY_ID_FAILED,
        payload: { error }
    }),

    getPreffedAggregatorListNetwork: () => ({
        type: actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK,
        payload: {}
    }),
    getPreffedAggregatorListNetworkSuccess: (networkResult) => ({
        type: actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK_SUCCESS,
        networkResult
    }),
    getPreffedAggregatorListNetworkFailed: (networkError) => ({
        type: actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK_FAILED,
        networkError
    }),
    getNetwork: (data) => ({
        type: actions.GET_NETWORK,
        payload: { data }
    }),
    getNetworkSuccess: (networkResult) => ({
        type: actions.GET_NETWORK_SUCCESS,
        networkResult
    }),
    getNetworkFailed: (networkError) => ({
        type: actions.GET_NETWORK_FAILED,
        networkError
    }),

    showNetworkModel: () => ({
        type: actions.SHOW_NETWORK_MODAL,
    }),
    closeNetworkModel: () => ({
        type: actions.CLOSE_NETWORK_MODAL,
    }),

    addNetwork: (data, searchData) => ({
        type: actions.ADD_NETWORK,
        payload: { data, searchData }
    }),
    addNetworkSuccess: (networkMessage, networkResult) => ({
        type: actions.ADD_NETWORK_SUCCESS,
        networkMessage, networkResult
    }),
    addNetworkFailed: (networkError) => ({
        type: actions.ADD_NETWORK_FAILED,
        networkError
    }),

    deleteNetwork: (networkId, status, searchData) => ({
        type: actions.DELETE_NETWORK,
        payload: { networkId, status, searchData }
    }),
    deleteNetworkSuccess: (networkMessage, networkResult) => ({
        type: actions.DELETE_NETWORK_SUCCESS,
        networkMessage, networkResult
    }),

    resetNetwork: () => ({
        type: actions.RESET_NETWORK
    }),

    editNetwork: (data, searchData) => ({
        type: actions.EDIT_NETWORK,
        payload: { data, searchData }
    }),
    editNetworkSuccess: (networkMessage, networkResult) => ({
        type: actions.EDIT_NETWORK_SUCCESS,
        networkMessage, networkResult
    }),

    resetNetworkMessage: () => ({
        type: actions.RESET_NETWORK_MESSAGE
    }),

    getActiveNetwork: () => ({
        type: actions.GET_ACTIVE_NETWORK,
    }),
    getActiveNetworkSuccess: (networkResult) => ({
        type: actions.GET_ACTIVE_NETWORK_SUCCESS,
        networkResult
    }),
    getActiveNetworkFailed: (networkError) => ({
        type: actions.GET_ACTIVE_NETWORK_FAILED,
        networkError
    }),

    showPrefAggregatorModel: () => ({
        type: actions.SHOW_PREF_AGGREGATOR_MODAL,
    }),
    closePrefAggregatorModel: () => ({
        type: actions.CLOSE_PREF_AGGREGATOR_MODAL,
    }),

    updatePreffedAggregator: (data, searchData) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR,
        payload: { data, searchData }
    }),
    updatePreffedAggregatorSuccess: (message) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR_SUCCESS,
        message
    }),
    updatePreffedAggregatorFailed: (error) => ({
        type: actions.UPDATE_PREFFERED_AGGREGATOR_FAILED,
        error
    }),
    getNetworkByCountry: (countryId) => ({
        type: actions.GET_NETWORK_BY_COUNTRY,
        countryId
    }),
    getNetworkByCountrySuccess: (networkData) => ({
        type: actions.GET_NETWORK_BY_COUNTRY_SUCCESS,
        networkData
    }),
    getNetworkByCountryFailed: (networkError) => ({
        type: actions.GET_NETWORK_BY_COUNTRY_FAILED,
        networkError
    }),

}
export default actions;