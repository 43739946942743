import actions from "./actions";

const initState = {
    loading: false,
    buttonLoading: false,
    rechargeFormModal: false,
    createRechargeRequestLoading: false,
    createRechargeRequestError: null,
    createRechargeRequestMessage: null,
    rechargeFormModalInternational: false,
    createRechargeRequestInternationalLoading: false,
    createRechargeRequestInternationalError: null,
    createRechargeRequestInternationalMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.PREPARE_RECHARGE_FORM:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                rechargeFormModal: true,
            };
        case actions.RESET_RECHARGE:
            return {
                ...state,
                loading: false,
                buttonLoading: false,

            };
        case actions.CLOSE_RECHARGE_FORM:
            return {
                ...state,
                rechargeFormModal: false,

            };

        case actions.CREATE_RECHARGE_REQUEST:
            return {
                ...state,
                createRechargeRequestLoading: true,
                createRechargeRequestError: null,
                createRechargeRequestMessage: null,
            };
        case actions.CREATE_RECHARGE_REQUEST_SUCCESS:
            return {
                ...state,
                createRechargeRequestLoading: false,
                createRechargeRequestError: null,
                createRechargeRequestMessage: action.payload.message,
                rechargeFormModal: false,
            };
        case actions.CREATE_RECHARGE_REQUEST_FAILED:
            return {
                ...state,
                createRechargeRequestLoading: false,
                createRechargeRequestError: action.payload.error,
                createRechargeRequestMessage: null,
                rechargeFormModal: false,
            };

        case actions.RESET_RECHARGE_MESSAGE:
            return {
                ...state,
                loading: false,
                createRechargeRequestError: null,
                createRechargeRequestMessage: null,
                createRechargeRequestLoading: false,
                createRechargeRequestInternationalLoading: false,
                createRechargeRequestInternationalError: null,
                createRechargeRequestInternationalMessage: null,
            };

        case actions.PREPARE_RECHARGE_FORM_INTERNATIONAL:
            return {
                ...state,
                rechargeFormModalInternational: true,
            };
        case actions.CLOSE_RECHARGE_FORM_INTERNATIONAL:
            return {
                ...state,
                rechargeFormModalInternational: false,
            };

        case actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL:
            return {
                ...state,
                createRechargeRequestInternationalLoading: true,
                createRechargeRequestInternationalError: null,
                createRechargeRequestInternationalMessage: null,
            };
        case actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL_SUCCESS:
            return {
                ...state,
                createRechargeRequestInternationalLoading: false,
                createRechargeRequestInternationalError: null,
                createRechargeRequestInternationalMessage: action.payload.message,
                rechargeFormModalInternational: false,
            };
        case actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL_FAILED:
            return {
                ...state,
                createRechargeRequestInternationalLoading: false,
                createRechargeRequestInternationalError: action.payload.error,
                createRechargeRequestInternationalMessage: null,
                rechargeFormModalInternational: false,
            };
        default:
            return state;
    }
}
