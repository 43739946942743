import actions from "./actions";

const initState = {
    customerResult: [],
    loading: false,
    customerError: null,
    customerMessage: null,
    buttonLoading: false,
    customerFormModal: false,
    releaseCustomerLoading: false,
    releaseCustomerError: null,
    releaseCustomerMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.RELEASE_CUSTOMER:
            return {
                ...state,
                releaseCustomerLoading: true,
                releaseCustomerError: null,
                releaseCustomerMessage: null,
            };
        case actions.RELEASE_CUSTOMER_SUCCESS:
            return {
                ...state,
                releaseCustomerLoading: false,
                releaseCustomerError: null,
                releaseCustomerMessage: action.message,
            };
        case actions.RELEASE_CUSTOMER_FAILED:
            return {
                ...state,
                releaseCustomerLoading: false,
                releaseCustomerError: action.error,
                releaseCustomerMessage: null,
            };


        case actions.GET_CUSTOMER:
            return {
                ...state,
                loading: true,
                customerMessage: null,
                customerError: null,
                buttonLoading: false,
                customerFormModal: false,
            };
        case actions.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customerResult: action.customerResult,
                customerMessage: null,
                customerError: null,
                buttonLoading: false,
                customerFormModal: false,
            };
        case actions.GET_CUSTOMER_FAILED:
            return {
                ...state,
                loading: false,
                customerError: action.customerError,
                customerMessage: null,
                buttonLoading: false,
                customerFormModal: false,
            };

        case actions.DELETE_CUSTOMER:
            return {
                ...state,
                loading: false,
                customerMessage: null,
                customerError: null,
                buttonLoading: true,
                customerFormModal: false,
            };
        case actions.DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customerError: null,
                customerMessage: action.customerMessage,
                customerResult: action.customerResult,
                buttonLoading: false,
                customerFormModal: false,
            };

        case actions.RESET_CUSTOMER:
            return {
                ...state,
                loading: false,
                customerError: null,
                customerMessage: null,
                buttonLoading: false,
                customerFormModal: false,
            };
        case actions.RESET_CUSTOMER_MESSAGE:
            return {
                ...state,
                loading: false,
                customerError: null,
                customerMessage: null,
                buttonLoading: false,
            };
        default:
            return state;
    }
}
