import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* getNetworkListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getNetwork',data);
        if (response.response.Status) {
            yield put(actions.getNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getNetworkFailed());
    }
}

function* addNetworkResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/createNetwork', data);
        if (response.Status) {
            const responseList = yield call(addData, '/getNetwork',searchData);
            if (responseList.response.Status) {
                yield put(actions.addNetworkSuccess(response.Message, responseList.response.Data));
            } else {
                yield put(actions.addNetworkFailed(response.response.Message));
            }
        } else {
            yield put(actions.addNetworkFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error.Message));
    }
}

function* editNetworkResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/updateNetwork', data);
        if (response.Status) {
            const responseList = yield call(addData, '/getNetwork',searchData);
            if (responseList.response.Status) {
                yield put(actions.editNetworkSuccess(response.Message, responseList.response.Data));
                yield put(actions.getNetwork());
            } else {
                yield put(actions.addNetworkFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addNetworkFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* deleteNetworkResponse({ payload: { networkId, status,searchData } }) {
    console.log("searchData",searchData)
    try {
        const response = yield call(deleteData, '/deleteNetwork/' + networkId + '/' + status);
        if (response.Status) {
            const responseList = yield call(addData, '/getNetwork',searchData);
            if (responseList.response.Status) {
                yield put(actions.deleteNetworkSuccess(response.Message, responseList.response.Data));
            } else {
                yield put(actions.getNetworkFailed(response.response.Message));
            }
        } else {
            yield put(actions.getNetworkFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addNetworkFailed(error));
    }
}

function* getActiveNetworkListResponse() {
    try {
        const response = yield call(getList, '/getActiveNetwork');
        if (response.response.Status) {
            yield put(actions.getActiveNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getActiveNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getActiveNetworkFailed());
    }
}

function* updatePrefferedAggregatorResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/updateNetworkPrefferedAggregator', data);
        if (response.Status) {
            const responseList = yield call(addData, '/getNetwork',searchData);
            if (responseList.response.Status) {
                yield put(actions.updatePreffedAggregatorSuccess(response.Message, responseList.response.Data));
                yield put(actions.getNetwork());
            } else {
                yield put(actions.updatePreffedAggregatorFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.updatePreffedAggregatorFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.updatePreffedAggregatorFailed(error));
    }
}

function* getPrefferedAggregatorListNetworkResponse() {
    try {
        const response = yield call(getList, '/getNetworkPrefferedAggregator');
        if (response.response.Status) {
            yield put(actions.getPreffedAggregatorListNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getPreffedAggregatorListNetworkFailed());
        }
    } catch (error) {
        yield put(actions.getPreffedAggregatorListNetworkFailed());
    }
}

function* gNetworkListByCountryResponse({countryId}) {
    try {
        const response = yield call(getList, '/getNetworkByCountry/'+countryId);
        if (response.response.Status) {
            yield put( actions.getNetworkByCountrySuccess( response.response.Data ));
        } else {
            yield put(actions.getNetworkByCountryFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworkByCountryFailed(error));
    }
}

function* getNetworksByCountryIdResponse({ payload: { countryID } }) {
    try {
        const response = yield call(getList, "/getNetworksByCountryID/" + countryID);
        if (response.Status) {
            yield put(actions.getNetworksByCountryIDSuccess(response.Networks));
        } else {
            yield put(actions.getNetworksByCountryIDFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworksByCountryIDFailed(error.Message));
    }
}

function* getNetworkListByCountryIdForNetworkResponse({ payload: { countryID } }) {
    try {
        const response = yield call(getList, "/getNetworkByCountryIdForNetwork/" + countryID);
        if (response.response.Status) {
            yield put(actions.getNetworksByCountryIDForNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getNetworksByCountryIDForNetworkFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworksByCountryIDForNetworkFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_NETWORK, getNetworkListResponse)]);
    yield all([takeEvery(actions.ADD_NETWORK, addNetworkResponse)]);
    yield all([takeEvery(actions.DELETE_NETWORK, deleteNetworkResponse)]);
    yield all([takeEvery(actions.EDIT_NETWORK, editNetworkResponse)]);
    yield all([takeEvery(actions.GET_ACTIVE_NETWORK, getActiveNetworkListResponse)]);
    yield all([takeEvery(actions.UPDATE_PREFFERED_AGGREGATOR, updatePrefferedAggregatorResponse)]);
    yield all([takeEvery(actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK, getPrefferedAggregatorListNetworkResponse)]); 
    yield all([takeEvery(actions.GET_NETWORK_BY_COUNTRY, gNetworkListByCountryResponse)]);  
    yield all([takeEvery(actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK, getPrefferedAggregatorListNetworkResponse)]); 
    yield all([takeEvery(actions.GET_NETWORKS_BY_COUNTRY_ID, getNetworksByCountryIdResponse)]); 
    yield all([takeEvery(actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK, getNetworkListByCountryIdForNetworkResponse)]); 


  
}