import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* updateAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/updateAggregator', data);
        if (response.Status) {
            yield put(actions.updateAggregatorSuccess(response.Message));
            yield put(actions.getAggregator());
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* addAggregatorResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addAggregator', data);
        if (response.Status) {
            yield put(actions.addAggregatorSuccess(response.Message));
            yield put(actions.getAggregator());
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* getAggregatorResponse() {
    try {
        const response = yield call(getList, '/getAggregatorList');
        if (response.Status) {
            yield put(actions.getAggregatorSuccess(response.Aggregators));
        }
    } catch (error) {
        yield put(actions.addAggregatorFailed(error));
    }
}

function* getPrefferedAggregatorListResponse({ payload: { networkId} }) {
    try {
        const response = yield call(getList, '/getAggregatorListByNetworkID/' + networkId);
        if (response.Status) {
            yield put(actions.getPreffedAggregatorListSuccess(response.Aggregators));
        } else {
            yield put(actions.getPreffedAggregatorListFailed());
        }
    } catch (error) {
        yield put(actions.getPreffedAggregatorListFailed());
    }
}



export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_AGGREGATOR, addAggregatorResponse)]);
    yield all([takeEvery(actions.GET_AGGREGATOR, getAggregatorResponse)]);
    yield all([takeEvery(actions.UPDATE_AGGREGATOR, updateAggregatorResponse)]);
    yield all([takeEvery(actions.GET_PREFFERED_AGGREGATOR, getPrefferedAggregatorListResponse)]);  


}