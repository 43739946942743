import actions from "./actions";

const initState = {
    dashboardListResult: [],
    loading: false,
    error: null,
    Message: null,
    dashboardDetailsResult: [],
    widgetResult: [],
    chartDataResult: [],
    buttonLoading: false,
    chartDataLoading: false,
    widgetDataLoading: false,
    activationDataLoading: false,
    rechargeList: [],
    rechargeListLoading: false,
    rechargeListMessage: null,
    rechargeListError: null,

};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_DASHBOARD_GRAPH_LIST:
            return {
                ...state,
                chartDataLoading: true,
            };
        case actions.GET_DASHBOARD_GRAPH_LIST_SUCCESS:
            return {
                ...state,
                chartDataLoading: false,
                dashboardListResult: action.dashboardListResult,
            };
        case actions.GET_DASHBOARD_GRAPH_LIST_FAILED:
            return {
                ...state,
                chartDataLoading: false,
                dashboardListError: action.dashboardListError,
                Message: null,
            };
        case actions.GET_WIDGET_DATA:
            return {
                ...state,
                widgetDataLoading: true,
            };
        case actions.GET_WIDGET_DATA_SUCCESS:
            return {
                ...state,
                widgetResult: action.widgetResult,
                widgetDataLoading: false
            };
        case actions.GET_WIDGET_DATA_FAILED:
            return {
                ...state,
                widgetDataLoading: false,
                error: action.error
            };
        case actions.GET_CHART_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_CHART_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                chartDataResult: action.chartDataResult,
            };
        case actions.GET_CHART_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case actions.GET_DASHBOARD_RECHARGE_LIST:
            return {
                ...state,
                rechargeListLoading: true,
                rechargeListMessage: null,
                rechargeListError: null,
            };
        case actions.GET_DASHBOARD_RECHARGE_LIST_SUCCESS:
            return {
                ...state,
                rechargeListLoading: false,
                rechargeListMessage: action.Message,
                rechargeListError: null,
                rechargeList: action.dashboardListResult,
            };
        case actions.GET_DASHBOARD_RECHARGE_LIST_FAILED:
            return {
                ...state,
                rechargeListLoading: false,
                rechargeListMessage: null,
                rechargeListError: action.dashboardListError,  
            };

        default:
            return state;
    }
}