import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* createRechargeRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createRechargeRequest', data);
        if (response.Status) {
            yield put(actions.createRechargeRequestSuccess(response.Message));
        } else {
            yield put(actions.createRechargeRequestFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createRechargeRequestFailed(error.Message));
    }
}

function* createRechargeRequestInternationalResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createRechargeRequestInternational', data);
        if (response.response.Status) {
            yield put(actions.createRechargeRequestInternationalSuccess(response.response.Message));
        } else {
            yield put(actions.createRechargeRequestInternationalFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createRechargeRequestInternationalFailed(error.Message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.CREATE_RECHARGE_REQUEST, createRechargeRequestResponse)]);
    yield all([takeEvery(actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL, createRechargeRequestInternationalResponse)]);
}
