import actions from "./actions";

const initState = {
    networkResult: [],
    loading: false,
    networkError: null,
    networkMessage: null,
    buttonLoading: false,
    deleteButtonLoading: false,
    networkFormModal: false,
    preferedAggregatorModal: false,
    tableLoading: false,
    getNetworksByCountryIdList: [],
    getNetworksByCountryIdLoading: false,
    getNetworksByCountryIdError: null,
    getNetworkListByCountryIdForNetwork: [],
    getNetworkListByCountryIdForNetworkLoading: false,
    getNetworkListByCountryIdForNetworkError: null,
    networkData:[],

};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK:
            return {
                ...state,
                getNetworkListByCountryIdForNetwork: [],
                getNetworkListByCountryIdForNetworkLoading: true,
                getNetworkListByCountryIdForNetworkError: null,
            };
        case actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_SUCCESS:
            return {
                ...state,
                getNetworkListByCountryIdForNetwork: action.payload.data,
                getNetworkListByCountryIdForNetworkLoading: false,
                getNetworkListByCountryIdForNetworkError: null,
            };
        case actions.GET_ALL_NETWORKS_BY_COUNTRY_ID_FOR_NETWORK_FAILED:
            return {
                ...state,
                getNetworkListByCountryIdForNetwork: [],
                getNetworkListByCountryIdForNetworkLoading: false,
                getNetworkListByCountryIdForNetworkError: action.payload.error,
            };


        case actions.GET_NETWORKS_BY_COUNTRY_ID:
            return {
                ...state,
                getNetworksByCountryIdList: [],
                getNetworksByCountryIdLoading: true,
                getNetworksByCountryIdError: null,
            };
        case actions.GET_NETWORKS_BY_COUNTRY_ID_SUCCESS:
            return {
                ...state,
                getNetworksByCountryIdList: action.payload.data,
                getNetworksByCountryIdLoading: false,
                getNetworksByCountryIdError: null,
            };
        case actions.GET_NETWORKS_BY_COUNTRY_ID_FAILED:
            return {
                ...state,
                getNetworksByCountryIdList: [],
                getNetworksByCountryIdLoading: false,
                getNetworksByCountryIdError: action.payload.error,
            };


        case actions.GET_NETWORK:
            return {
                ...state,
                networkError: null,
                networkMessage: null,
                buttonLoading: false,
                tableLoading: true,
            };
        case actions.GET_NETWORK_SUCCESS:
            return {
                ...state,
                networkError: null,
                networkResult: action.networkResult,
                networkMessage: null,
                buttonLoading: false,
                tableLoading: false,
            };
        case actions.GET_NETWORK_FAILED:
            return {
                ...state,
                networkError: action.networkError,
                networkMessage: null,
                buttonLoading: false,
                tableLoading: false,
            };

        case actions.SHOW_NETWORK_MODAL:
            return {
                ...state,
                networkFormModal: true,
            };
        case actions.CLOSE_NETWORK_MODAL:
            return {
                ...state,
                networkFormModal: false,
            };



        case actions.ADD_NETWORK:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                buttonLoading: true,
                networkFormModal: true
            };
        case actions.ADD_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkResult: action.networkResult,
                networkMessage: action.networkMessage,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.ADD_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: action.networkError,
                buttonLoading: false,
                networkFormModal: true
            };
        case actions.EDIT_NETWORK:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                buttonLoading: true,
                networkFormModal: true
            };
        case actions.EDIT_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkResult: action.networkResult,
                networkMessage: action.networkMessage,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false,
            };
        case actions.DELETE_NETWORK:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkMessage: null,
                deleteButtonLoading: true,
                networkFormModal: false
            };
        case actions.DELETE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkMessage: action.networkMessage,
                networkResult: action.networkResult,
                networkError: null,
                deleteButtonLoading: false,
                networkFormModal: false
            };
        case actions.RESET_NETWORK:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false,
                preferedAggregatorModal: false,
            };
        case actions.RESET_NETWORK_MESSAGE:
            return {
                ...state,
                loading: false,
                networkMessage: null,
                networkError: null,
                buttonLoading: false,
                preferedAggregatorModal: false,
            };

        case actions.GET_ACTIVE_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkResult: action.networkResult,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };
        case actions.GET_ACTIVE_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                networkError: action.networkError,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false
            };

        case actions.SHOW_PREF_AGGREGATOR_MODAL:
            return {
                ...state,
                preferedAggregatorModal: true,
                networkFormModal: false,
            };
        case actions.CLOSE_PREF_AGGREGATOR_MODAL:
            return {
                ...state,
                preferedAggregatorModal: false,
                networkFormModal: false,
            };

        case actions.UPDATE_PREFFERED_AGGREGATOR:
            return {
                ...state,
                networkError: null,
                networkMessage: null,
                buttonLoading: true,
                networkFormModal: false,
                preferedAggregatorModal: true,
            };
        case actions.UPDATE_PREFFERED_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                networkMessage: action.message,
                networkError: null,
                buttonLoading: false,
                networkFormModal: false,
                preferedAggregatorModal: false,
            };
        case actions.UPDATE_PREFFERED_AGGREGATOR_FAILED:
            return {
                ...state,
                ...state,
                networkError: action.error,
                networkMessage: null,
                buttonLoading: false,
                networkFormModal: false,
                preferedAggregatorModal: true,
            };
        case actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK:
            return {
                ...state,
                loading: true,
                networkError: null,
                networkMessage: null,
            };
        case actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                networkError: null,
                networkResult: action.networkResult,
                networkMessage: null,
            };
        case actions.GET_PREFFERED_AGGREGATOR_BY_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                networkError: action.networkError,
                networkMessage: null,
            };
                case actions.GET_NETWORK_BY_COUNTRY:
                    return {
                        ...state,
                        loading: true,
                        networkMessage: null,
                        networkError: null,
                    };
                case actions.GET_NETWORK_BY_COUNTRY_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        networkData: action.networkData,
                        networkMessage: null,
                        networkError: null,
                    };
                case actions.GET_NETWORK_BY_COUNTRY_FAILED:
                    return {
                        ...state,
                        loading: false,
                        networkError: action.networkError,
                        networkMessage: null,
                    };

        default:
            return state;
    }
}