const actions = {
    GET_COMMISSION_REPORT_LIST: "GET_COMMISSION_REPORT_LIST",
    GET_COMMISSION_REPORT_LIST_SUCCESS: "GET_COMMISSION_REPORT_LIST_SUCCESS",
    GET_COMMISSION_REPORT_LIST_FAILED: "GET_COMMISSION_REPORT_LIST_FAILED",


    getCommissionReportList: (data) => ({
        type: actions.GET_COMMISSION_REPORT_LIST,
        payload: { data }
    }),
    getCommissionReportListSuccess: (data,total) => ({
        type: actions.GET_COMMISSION_REPORT_LIST_SUCCESS,
        data,total
    }),
    getCommissionReportListFailed: (data) => ({
        type: actions.GET_COMMISSION_REPORT_LIST_FAILED,
        data
    }),

}
export default actions;