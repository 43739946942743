import actions from "./actions";

const initState = {
    planResult: [],
    loading: false,
    planError: null,
    planMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PLAN:
            return {
                ...state,
                loading: true
            };
        case actions.GET_PLAN_SUCCESS:
            return {
                ...state,
                planResult: action.planResult,
                loading: false
            };
        case actions.GET_PLAN_FAILED:
            return {
                ...state,
                planError: action.planError,
                loading: false
            };
            case actions.DELETE_PLAN:
                return {
                    ...state,
                    loading: false,
                    planMessage: null,
                    planError: null,
                };
            case actions.DELETE_PLAN_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    planError: null,
                    planMessage: action.planMessage,
                    planResult: action.planResult,
                };
                case actions.DELETE_PLAN_FAILED:
                return {
                    ...state,
                    loading: false,
                    planMessage:null,
                    planError: action.planError,
                    planResult: action.planResult,            
                };
        case actions.RESET_PLAN_MESSAGE:
            return {
                ...state,
                planError: null,
                loading: false,
                planMessage:null,
            };
            case actions.GET_PLAN_BY_NETWORK:
                return {
                    ...state,
                    loading: true,
                    planMessage: null,
                    planError: null,
                };
            case actions.GET_PLAN_BY_NETWORK_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    planResult: action.planResult,
                    planMessage: null,
                    planError: null,
                };
            case actions.GET_PLAN_BY_NETWORK_FAILED:
                return {
                    ...state,
                    loading: false,
                    planError: action.planError,
                    planMessage: null,
                };
        default:
            return state;
    }
}
