import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Input, Table, Modal, Select, Checkbox, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import RechargeRequestActions from '../../redux/rechargeRequest/actions';
import networkActions from '../../redux/network/actions';
import planActions from '../../redux/plan/actions';
import rechargeReportActions from '../../redux/rechargeReport/actions';
import { formatString } from '../../helpers/commonFunction';
import { Image } from "antd";

const BASE_URL = process.env.REACT_APP_BASEURL;
const RechargeRequestForm = ({ searchData }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { getPreffedAggregatorListNetwork } = networkActions;
    const { getPlanByNetwork, resetPlanMessage } = planActions;
    const { getRechargeReportList } = rechargeReportActions;
    const { prepareRechargeForm, closeRechargeForm, resetRecharge, createRechargeRequest, resetRechargeMessage } = RechargeRequestActions;
    const { rechargeFormModal, createRechargeRequestLoading, createRechargeRequestMessage, createRechargeRequestError } = useSelector((state) => state.rechargeRequest);
    const { planResult, loading } = useSelector((state) => state.plan);
    const networkResult = useSelector((state) => state.network.networkResult);
    const [formTitle, setFormTitle] = useState('ADD RECHARGE');
    const [formButton, setFormButton] = useState('SUBMIT');
    const [networkData, setNetworkData] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectednetwork, setSelectedNetwork] = useState(null);


    useEffect(() => {
        dispatch(getPreffedAggregatorListNetwork('233'));
    }, []);

    const handleChange = (value) => {
        setSelectedNetwork(value)
        setSelectedPlan(null);
        form.setFieldsValue({ plan: null, planPrice: null });
        dispatch(getPlanByNetwork(value));
    };

    const handleSubmit = (data) => {
        dispatch(createRechargeRequest(data))
        dispatch(resetRecharge());
    };

    const handleCancel = () => {
        dispatch(resetRecharge());
        dispatch(resetPlanMessage());
        dispatch(closeRechargeForm());
        setSelectedPlan(null);
        form.resetFields()
        setSelectedNetwork(null)
    };

    const handleChangePlan = (value) => {
        const selectedPlan = planResult.find(plan => plan.ID === value);
        setSelectedPlan(selectedPlan)
        form.setFieldsValue({ planPrice: null });
        if (selectedPlan && selectedPlan.IsRange === 0) {
            form.setFieldsValue({ planPrice: `$${selectedPlan.PlanPrice}` });
        }
    };

    const validatePlanPrice = (_, value) => {
        if (selectedPlan === null) {
            return Promise.reject(new Error(formatString(`please input Plan Price!`)));
        }

        if (value < selectedPlan.MinTopUpAmount || value > selectedPlan.MaxTopUpAmount) {
            return Promise.reject(new Error(formatString(`Plan Price must be between $${selectedPlan.MinTopUpAmount} and $${selectedPlan.MaxTopUpAmount}!`)));
        }
        return Promise.resolve();
    };

    const options2 = planResult && planResult.map(plan => ({
        label: plan.PlanName,
        value: plan.ID,
    }));

    const success = (text) => {
        form.resetFields();
        message.success(text);
        dispatch(getRechargeReportList(searchData));
        dispatch(resetRechargeMessage());
    };

    const error = (text) => {
        form.resetFields();
        message.error(text);
        dispatch(resetRechargeMessage());
        dispatch(getRechargeReportList(searchData));
    };
    return (
        <>
            {createRechargeRequestMessage !== null && createRechargeRequestMessage !== undefined && success(createRechargeRequestMessage)}
            {createRechargeRequestError !== null && createRechargeRequestError !== undefined && error(createRechargeRequestError)}
            <Modal
                title={formTitle}
                style={{ top: 20 }}
                width={500}
                visible={rechargeFormModal}
                // onOk={handleCancel}
                onCancel={handleCancel}
                closable={true}
                destroyOnClose={true}
                maskClosable={false}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        CANCEL
                    </Button>,
                    <Button form="userForm" key="submit" loading={createRechargeRequestLoading} htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} >
                        {formButton}
                    </Button>,
                ]}
            >
                <Form id="userForm" layout="vertical" className="row-col" onFinish={handleSubmit} form={form} >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="network"
                                label="Network"
                                rules={[{ required: true, message: formatString('Please Select Network !') }]}
                            >
                                <Select className="ant-select sect-nnn" showSearch optionFilterProp="children" placeholder="Select Network" name="network" onChange={handleChange} >
                                    <Select.Option value="" key="0">Select</Select.Option>
                                    {networkResult && networkResult.length > 0 && networkResult.map(network => (
                                        <Select.Option name="network" value={network.ID} key={network.ID}>
                                            <Image preview={false} src={`${BASE_URL}/images/network/${network.NetworkLogoURL}?${(Date.now())}`} width={55} style={{ marginRight: "7px" }} /><span style={{ marginLeft: "5px" }}>{network.NetworkName}</span></Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="username"
                                name="plan"
                                label="Plan"
                                span={24}
                                rules={[{ required: true, message: formatString('Please Select Plan !') }]}
                            >
                                <Select
                                    placeholder="Select Plan"
                                    options={selectednetwork ? options2 : ''}
                                    onChange={handleChangePlan}
                                    loading={loading}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="username"
                                label="Plan Price"
                                name="planPrice"
                                rules={[
                                    {
                                        validator: validatePlanPrice,
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Plan Price"
                                    type="text"
                                    disabled={!selectedPlan || selectedPlan.IsRange === 0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="username"
                                label="MSISDN"
                                name="msisdn"
                                rules={[
                                    {
                                        required: true,
                                        message: formatString("Please input MSISDN!"),
                                    },
                                    {
                                        pattern: /^\d{10}$/,
                                        message: formatString("MSISDN must be exactly 10 digits!"),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter MSISDN"
                                    maxLength={10}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '');
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal></>

    );
};

export default RechargeRequestForm;