import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import './assets/styles/own.css'
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import User from "./pages/Admin/User";
import Plan from "./pages/Config/Plan/Plan";
import Customer from "./pages/Admin/Customer";
import ActivationReport from "./pages/Reports/ActivationReport";
import BulkReport from "./pages/Reports/BulkReport";
// import BulkRecharge from "./pages/Inventory/BulkRecharge/BulkRecharge"
import MobileRechargeReport from "./pages/Reports/MobileRechargeReport";
import ManualAddFund from "./pages/Admin/ManualAddFund";
import Dealer from "./pages/Admin/Dealer"
import AuditReport from './pages/Reports/AuditReport'
import Network from "./pages/Config/Network/Network";
import MobileRecharge from './pages/Recharge/MobileRechage'
import RechargeMapping from "./pages/Config/RechargeMapping/RechargeMapping";
import Aggregator from "./pages/Config/Aggregator/Aggregator";
import CommissionReport from "./pages/Reports/CommissionReport";
import AccessDenied from "./pages/Restriction/AccessDenied403";


function App() {
  const profileDataResult = useSelector((state) => state.auth.profileDataResult);
  const [roleId, setroleId] = useState(null)
  const [isCompany, setIsCompany] = useState(null)


  useEffect(() => {
    if (profileDataResult && profileDataResult.length > 0) {
      setroleId(profileDataResult[0].RoleID)
      setIsCompany(profileDataResult[0].IsCompany)
    }
  }, [profileDataResult])

  return (
    <div className="App">
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Main>
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/user" component={roleId === 1 ? User : roleId !== null && AccessDenied} />
          <Route exact path="/dealer" component={roleId === 1 && isCompany === 1 ? Dealer : roleId !== null && AccessDenied} />
          <Route exact path="/customer" component={roleId === 1 && isCompany === 1 ? Customer : roleId !== null && AccessDenied} />
          <Route exact path="/manual-add-fund" component={roleId === 1 && isCompany === 1 ? ManualAddFund : roleId !== null && AccessDenied} />
          <Route exact path="/plan" component={roleId === 1 ? Plan : roleId !== null && AccessDenied} />
          {/* <Route exact path="/bulk-report" component={BulkReport} /> */}
          <Route exact path="/activation-report" component={roleId === 1 ? ActivationReport : roleId !== null && AccessDenied} />
          <Route exact path="/mobile-recharge-report" component={MobileRechargeReport} />
          <Route exact path="/audit-report" component={roleId === 1 ? AuditReport : roleId !== null && AccessDenied} />
          <Route exact path="/network" component={roleId === 1 && isCompany === 1 ? Network : roleId !== null && AccessDenied} />
          <Route exact path="/mobile-recharge" component={MobileRecharge} />
          <Route exact path="/aggregator" component={roleId === 1 && isCompany === 1 ? Aggregator : roleId !== null && AccessDenied} />
          <Route exact path="/recharge-price-mapping" component={roleId === 1 && isCompany === 1 ? RechargeMapping : roleId !== null && AccessDenied} />
          <Route exact path="/commission-report" component={roleId === 1 ? CommissionReport : roleId !== null && AccessDenied} />
          {/* <Route exact path="/bulk-recharge" component={BulkRecharge} /> */}
        </Main>
      </Switch>
    </div>
  );
}

export default App;
