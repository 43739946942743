import actions from "./actions";

const initState = {
    loading: false,
    buttonLoading: false,
    rechargeMappingLoading: false,
    rechargeMappingError: null,
    rechargeMappingMessage: null,
    rechargeMappingData: [],
    updateRechargeMappingLoading: false,
    updateRechargeMappingError: null,
    updateRechargeMappingMessage: null,
};

export default function reducer(state = initState, action) {

    switch (action.type) {

        case actions.UPDATE_RECHARGE_MAPPING_DATA:
            return {
                ...state,
                updateRechargeMappingLoading: true,
                updateRechargeMappingError: null,
                updateRechargeMappingMessage: null,
            };
        case actions.UPDATE_RECHARGE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                updateRechargeMappingLoading: false,
                updateRechargeMappingError: null,
                updateRechargeMappingMessage: action.payload.message,
            };
        case actions.UPDATE_RECHARGE_MAPPING_DATA_FAILED:
            return {
                ...state,
                updateRechargeMappingLoading: false,
                updateRechargeMappingError: action.payload.error,
                updateRechargeMappingMessage: null,
            };

        case actions.GET_RECHARGE_MAPPING_DATA:
            return {
                ...state,
                rechargeMappingLoading: true,
                rechargeMappingError: null,
                rechargeMappingMessage: null,
                rechargeMappingData: []
            };

        case actions.GET_RECHARGE_MAPPING_DATA_SUCCESS:
            return {
                ...state,
                rechargeMappingLoading: false,
                rechargeMappingError: null,
                rechargeMappingMessage: null,
                rechargeMappingData: action.payload.data
            };

        case actions.GET_RECHARGE_MAPPING_DATA_FAILED:
            return {
                ...state,
                rechargeMappingLoading: false,
                rechargeMappingError: action.payload.error,
                rechargeMappingMessage: null,
                rechargeMappingData: []
            };

        case actions.RESET_RECHARGE_PRICE_MAPPING_MESSAGES:
            return {
                ...state,
                rechargeMappingLoading: false,
                updateRechargeMappingLoading: false,
                updateRechargeMappingMessage: null,
                updateRechargeMappingError: null,
            };
        default:
            return state;
    }
}