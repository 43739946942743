const actions = {
    GET_PRELOADED: 'GET_PRELOADED',
    GET_PRELOADED_SUCCESS: 'GET_PRELOADED_SUCCESS',
    GET_PRELOADED_FAILED: 'GET_PRELOADED_FAILED',
    ADD_PRELOADED: "ADD_PRELOADED",
    ADD_PRELOADED_SUCCESS: "ADD_PRELOADED_SUCCESS",
    ADD_PRELOADED_FAILED: "ADD_PRELOADED_FAILED",
    GET_PRELOADED_DETAILS: "GET_PRELOADED_DETAILS",
    GET_PRELOADED_DETAILS_SUCCESS: "GET_PRELOADED_DETAILS_SUCCESS",
    VALIDATE_PRELOADED_DATA: 'VALIDATE_PRELOADED_DATA',
    VALIDATE_PRELOADED_DATA_SUCCESS: 'VALIDATE_PRELOADED_DATA_SUCCESS',
    VALIDATE_PRELOADED_DATA_FAILED: 'VALIDATE_PRELOADED_DATA_FAILED',
    PREPARE_ADD_PRELOADED_FORM: "PREPARE_ADD_PRELOADED_FORM",
    PREPARE_ADD_PRELOADED_FORM_SUCCESS: "PREPARE_ADD_PRELOADED_FORM_SUCCESS",
    PREPARE_ADD_PRELOADED_FORM_FAILED: "PREPARE_ADD_PRELOADED_FORM_FAILED",
    RESET_PRELOADED_ACTIVATION: 'RESET_PRELOADED_ACTIVATION',
    RESET_PRELOADED_MESSAGE: 'RESET_PRELOADED_MESSAGE',

    getPreloaded: (searchData) => ({
        type: actions.GET_PRELOADED,
        payload: { searchData }
    }),
    getPreloadedSuccess: (preloadedResult) => ({
        type: actions.GET_PRELOADED_SUCCESS,
        preloadedResult
    }),
    getPreloadedFailed: (preloadedError) => ({
        type: actions.GET_PRELOADED_FAILED,
        preloadedError
    }),
    addPreloaded: (data,searchData) => ({
        type: actions.ADD_PRELOADED,
        payload: { data,searchData }
    }),
    addPreloadedSuccess: (preloadedMessage, preloadedResult) => ({
        type: actions.ADD_PRELOADED_SUCCESS,
        preloadedMessage, preloadedResult
    }),
    addPreloadedFailed: (preloadedError) => ({
        type: actions.ADD_PRELOADED_FAILED,
        preloadedError
    }),
    getPreloadedDetails: (preloadedId) => ({
        type: actions.GET_PRELOADED_DETAILS,
        payload: { preloadedId }
    }),
    getPreloadedDetailsSuccess: (preloadedDetails) => ({
        type: actions.GET_PRELOADED_DETAILS_SUCCESS,
        preloadedDetails
    }),
    validatePreloadedData: (preloadeduploadData) => ({
        type: actions.VALIDATE_PRELOADED_DATA,
        payload: { preloadeduploadData }
    }),
    validatePreloadedDataSuccess: (preloadedMessage) => ({
        type: actions.VALIDATE_PRELOADED_DATA_SUCCESS,
        preloadedMessage
    }),
    validatePreloadedDataFailed: (preloadedError, simData) => ({
        type: actions.VALIDATE_PRELOADED_DATA_FAILED,
        preloadedError, simData
    }),
    prepareAddPreloadedForm: () => ({
        type: actions.PREPARE_ADD_PRELOADED_FORM
    }),
    prepareAddPreloadedFormSuccess: (preloadedInvResult) => ({
        type: actions.PREPARE_ADD_PRELOADED_FORM_SUCCESS,
        preloadedInvResult
    }),
    prepareAddPreloadedFormFailed: (preloadedError) => ({
        type: actions.PREPARE_ADD_PRELOADED_FORM_FAILED,
        preloadedError
    }),
    resetPreloadedActivation: () => ({
        type: actions.RESET_PRELOADED_ACTIVATION
    }),
    resetPreloadedMessage: () => ({
        type: actions.RESET_PRELOADED_MESSAGE
    }),
};
export default actions;
