import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Typography, Spin, Button } from "antd";
import { CloseCircleOutlined, ClockCircleOutlined, CheckCircleOutlined, LineChartOutlined, ReloadOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import homeActions from '../redux/home/actions';
import Echart from "../components/chart/EChart";
import withTokenValidation from "../withTokenValidation";
import { isDealer, isDealerAgent } from '../helpers/commonFunction';
import RechargeRequestActions from '../redux/rechargeRequest/actions'
import authActions from '../redux/auth/actions';
import RechargeRequestForm from '../pages/Recharge/RechargeRequestForm'
import InternationalRechargeForm from '../pages/Recharge/InternationalRechargeForm'

function Home() {
  const { Title } = Typography;
  const { getWidgetData, getDashboardRechargeList } = homeActions;
  const { prepareRechargeForm,prepareRechargeFormInternational } = RechargeRequestActions;
  const { getProfileData } = authActions;
  const dispatch = useDispatch();
  const { profileDataResult: tokenData } = useSelector((state) => state.auth);
  const widgetResult = useSelector((state) => state.home.widgetResult);
  const widgetDataLoading = useSelector((state) => state.home.widgetDataLoading);
  const { rechargeList, rechargeListLoading } = useSelector((state) => state.home);

  useEffect(() => {
    dispatch(getWidgetData());
    dispatch(getDashboardRechargeList())
    dispatch(getProfileData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var widgetData = widgetResult.length > 0 && widgetResult.map((data, index) => {
    return [
      { today: 'Today’s Recharge', title: `${data.Total === null ? 0 : data.Total}`, icon: <LineChartOutlined style={{ fontWeight: "900" }} />, color: '#2e3992' },
      { today: 'Today’s Pending', title: `${data.Pending === null ? 0 : data.Pending}`, icon: <ClockCircleOutlined style={{ fontWeight: "bolder" }} />, color: '#808080' },
      { today: 'Today’s Processing', title: `${data.Processing === null ? 0 : data.Processing}`, icon: <ReloadOutlined style={{ fontWeight: "bolder" }} />, color: '#f4bd0e' },
      { today: 'Today’s Processed', title: `${data.Processed === null ? 0 : data.Processed}`, icon: <CheckCircleOutlined style={{ fontWeight: "bolder" }} />, color: '#008000' },
      { today: 'Today’s Cancelled', title: `${data.Cancelled === null ? 0 : data.Cancelled}`, icon: <CloseCircleOutlined style={{ fontWeight: "bolder" }} />, color: '#ff0000' },
    ]
  })

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const handleRechargeForm = () => {
    dispatch(prepareRechargeForm());
  }
  const handleRechargeFormInternational = () => {
    dispatch(prepareRechargeFormInternational());
  }
  return (
    <>
      <div className="layout-content">
        {/* <Row className="rowgap-vbox" gutter={[24, 0]}>
        </Row> */}
        <Row gutter={[24, 0]}>
          {isDealer(tokenData) || isDealerAgent(tokenData) ? <Col span={6}>
            <Card bordered={false} className="criclebox h-full card-btn-top">
              <Button type="=ghost" style={{ height: "60px", fontWeight: 'bold', backgroundColor: "#088aca", color: '#fff', border: 'none', fontSize: "18px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "5px 20px", marginBottom: "1em" }} onClick={handleRechargeForm}>
                <svg style={{ color: "#fff", marginRight: "8px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
                DOMESTIC RECHARGE</Button>
            </Card>
            <RechargeRequestForm />
          </Col> : ''}

          {isDealer(tokenData) || isDealerAgent(tokenData) ? <Col span={8}>
            <Card bordered={false} className="criclebox h-full card-btn-top">
              <Button type="=ghost" style={{ height: "60px", fontWeight: 'bold', backgroundColor: "#e53935", color: '#fff', border: 'none', fontSize: "18px", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "5px 20px", marginBottom: "1em" }} onClick={handleRechargeFormInternational}>
                <svg style={{ color: "#fff", marginRight: "8px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg>
                INTERNATIONAL RECHARGE</Button>
            </Card>
            <InternationalRechargeForm />
          </Col> : ''}

          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Card bordered={false} className="criclebox h-full">
              <Echart />
            </Card>
          </Col>

          <Col xs={24} sm={12} md={12} lg={8} xl={8} className="mb-13">
            {/* {widgetDataLoading === true ? <div>
            <div className="loading-container">
            <Spin indicator={<LoadingOutlined spin />} />
            </div>
            </div> : (widgetData && widgetData[0].length > 0 && widgetData[0].map((c, index) => (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={24}
                xl={24}
                className="mb-24"
              >
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span>{c.today}</span>
                        <Title level={3}>
                          {c.title} <small className={c.bnb}>{c.persent}</small>
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box" style={{ backgroundColor: `${c.color}` }}>{c.icon}</div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            )))} */}



            <Card bordered={false} className="criclebox mb-5 rec-com mt-20">
              <h2 className="to-mo-to">Today</h2>
              {rechargeListLoading === true ? <div className="text-center"><Spin indicator={<LoadingOutlined spin />} /></div> : <Row gutter={[24, 0]} className="row-gap">
                <Col xs={12}>
                  <h4>Recharge</h4>
                </Col>
                <Col xs={12}>
                  <h4 >Commission</h4>
                </Col>
                <Col xs={12}><p className="recharge-style">{rechargeList && rechargeList.length > 0 && rechargeList[0].TodayRecharge}</p></Col>

                <Col xs={12}><p className="commision-style">{rechargeList && rechargeList.length > 0 && rechargeList[0].TodayCommision ? `$${rechargeList[0].TodayCommision}` : '$0'}</p></Col>
                <div className="vert-l"></div>
              </Row>}
            </Card>
            <Card bordered={false} className="criclebox mb-5 rec-com">
              <h2 className="to-mo-to">Monthly</h2>
              {rechargeListLoading === true ? <div className="text-center"><Spin indicator={<LoadingOutlined spin />} /></div> : <Row gutter={[24, 0]} className="row-gap">
                <Col xs={12}>
                  <h4>Recharge</h4>
                </Col>
                <Col xs={12}>
                  <h4>Commission</h4>
                </Col>
                <Col xs={12}><p className="recharge-style">{rechargeList && rechargeList.length > 0 && rechargeList[0].MonthlyRecharge}</p></Col>
                <Col xs={12}><p className="commision-style">{rechargeList && rechargeList.length > 0 && rechargeList[0].MonthlyCommision ? `$${rechargeList[0].MonthlyCommision}` : '$0'}</p></Col>
                <div className="vert-l"></div>
              </Row>}
            </Card>
            <Card bordered={false} className="criclebox rec-com">
              <h2 className="to-mo-to">Total</h2>
              {rechargeListLoading === true ? <div className="text-center"><Spin indicator={<LoadingOutlined spin />} /></div> : <Row gutter={[24, 0]} className="row-gap">
                <Col xs={12}>
                  <h4>Recharge</h4>
                </Col>
                <Col xs={12}>
                  <h4>Commission</h4>
                </Col>
                <Col xs={12}><p className="recharge-style">{rechargeList && rechargeList.length > 0 && rechargeList[0].TotalRecharge}</p></Col>
                <Col xs={12}><p className="commision-style">{rechargeList && rechargeList.length > 0 && rechargeList[0].TotalCommision ? `$${rechargeList[0].TotalCommision}` : '$0'}</p></Col>
                <div className="vert-l"></div>
              </Row>}
            </Card>



          </Col>



        </Row>

      </div>
    </>
  );
}

export default withTokenValidation(Home);
