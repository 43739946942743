const actions = {
    GET_RECHARGE_MAPPING_DATA: "GET_RECHARGE_MAPPING_DATA",
    GET_RECHARGE_MAPPING_DATA_SUCCESS: "GET_RECHARGE_MAPPING_DATA_SUCCESS",
    GET_RECHARGE_MAPPING_DATA_FAILED: "GET_RECHARGE_MAPPING_DATA_FAILED",
    UPDATE_RECHARGE_MAPPING_DATA: "UPDATE_RECHARGE_MAPPING_DATA",
    UPDATE_RECHARGE_MAPPING_DATA_SUCCESS: "UPDATE_RECHARGE_MAPPING_DATA_SUCCESS",
    UPDATE_RECHARGE_MAPPING_DATA_FAILED: "UPDATE_RECHARGE_MAPPING_DATA_FAILED",
    RESET_RECHARGE_PRICE_MAPPING_MESSAGES: "RESET_PLAN_PRICE_MAPPING_MESSAGES",
   
    updateRechargeMappingData: (data) => ({
        type: actions.UPDATE_RECHARGE_MAPPING_DATA,
        payload: { data }
    }),
    updateRechargeMappingDataSuccess: (message) => ({
        type: actions.UPDATE_RECHARGE_MAPPING_DATA_SUCCESS,
        payload: { message }
    }),
    updateRechargeMappingDataFailed: (error) => ({
        type: actions.UPDATE_RECHARGE_MAPPING_DATA_FAILED,
        payload: { error }
    }),

    getRechargeMapping: () => ({
        type: actions.GET_RECHARGE_MAPPING_DATA,
    }),
    getRechargeMappingSuccess: (data) => ({
        type: actions.GET_RECHARGE_MAPPING_DATA_SUCCESS,
        payload: { data }
    }),
    getRechargeMappingFailed: (error) => ({
        type: actions.GET_RECHARGE_MAPPING_DATA_FAILED,
        payload: { error }
    }),
    resetRechargePriceMappingMessages: () => ({
        type: actions.RESET_RECHARGE_PRICE_MAPPING_MESSAGES,
    })

}
export default actions;