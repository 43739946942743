const actions = {
    PREPARE_RECHARGE_FORM: "PREPARE_RECHARGE_FORM",
    CLOSE_RECHARGE_FORM: "CLOSE_RECHARGE_FORM",
    RESET_RECHARGE: "RESET_RECHARGE",
    CREATE_RECHARGE_REQUEST: "CREATE_RECHARGE_REQUEST",
    CREATE_RECHARGE_REQUEST_SUCCESS: "CREATE_RECHARGE_REQUEST_SUCCESS",
    CREATE_RECHARGE_REQUEST_FAILED: "CREATE_RECHARGE_REQUEST_FAILED",
    RESET_RECHARGE_MESSAGE: "RESET_RECHARGE_MESSAGE",
    PREPARE_RECHARGE_FORM_INTERNATIONAL: "PREPARE_RECHARGE_FORM_INTERNATIONAL",
    CLOSE_RECHARGE_FORM_INTERNATIONAL: "CLOSE_RECHARGE_FORM_INTERNATIONAL",
    CREATE_RECHARGE_REQUEST_INTERNATIONAL: "CREATE_RECHARGE_REQUEST_INTERNATIONAL",
    CREATE_RECHARGE_REQUEST_INTERNATIONAL_SUCCESS: "CREATE_RECHARGE_REQUEST_INTERNATIONAL_SUCCESS",
    CREATE_RECHARGE_REQUEST_INTERNATIONAL_FAILED: "CREATE_RECHARGE_REQUEST_INTERNATIONAL_FAILED",
    

    prepareRechargeForm: () => ({
        type: actions.PREPARE_RECHARGE_FORM,
    }),
    closeRechargeForm: () => ({
        type: actions.CLOSE_RECHARGE_FORM,
    }),
    resetRecharge: () => ({
        type: actions.RESET_RECHARGE,
    }),
    createRechargeRequest: (data) => ({
        type: actions.CREATE_RECHARGE_REQUEST,
        payload: { data }
    }),
    createRechargeRequestSuccess: (message) => ({
        type: actions.CREATE_RECHARGE_REQUEST_SUCCESS,
        payload: { message }
    }),
    createRechargeRequestFailed: (error) => ({
        type: actions.CREATE_RECHARGE_REQUEST_FAILED,
        payload: { error }
    }),
    resetRechargeMessage: () => ({
        type: actions.RESET_RECHARGE_MESSAGE,
    }),
    prepareRechargeFormInternational: () => ({
        type: actions.PREPARE_RECHARGE_FORM_INTERNATIONAL,
    }),
    closeRechargeFormInternational: () => ({
        type: actions.CLOSE_RECHARGE_FORM_INTERNATIONAL,
    }),
    createRechargeRequestInternational: (data) => ({
        type: actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL,
        payload: { data }
    }),
    createRechargeRequestInternationalSuccess: (message) => ({
        type: actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL_SUCCESS,
        payload: { message }
    }),
    createRechargeRequestInternationalFailed: (error) => ({
        type: actions.CREATE_RECHARGE_REQUEST_INTERNATIONAL_FAILED,
        payload: { error }
    }),

};
export default actions;