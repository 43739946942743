import actions from "./actions";

const initState = {
    shortCodeResult: [],
    error:null
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_SHORT_CODE:
            return {
                ...state,
            };
        case actions.GET_SHORT_CODE_SUCCESS:
            return {
                ...state,
                shortCodeResult: action.shortCodeResult
            };
        case actions.GET_SHORT_CODE_FAILED:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
