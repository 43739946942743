const actions = {
    MANUAL_ADD_FUND: 'MANUAL_ADD_FUND',
    MANUAL_ADD_FUND_SUCCESS: 'MANUAL_ADD_FUND_SUCCESS',
    MANUAL_ADD_FUND_FAILED: 'MANUAL_ADD_FUND_FAILED',
    RESET_MANUAL_ADD_FUND:"RESET_MANUAL_ADD_FUND",


    manualAddFunds: (data) => ({
        type: actions.MANUAL_ADD_FUND,
        payload:{data}
    }),
    manualAddFundSuccess: (manualAddFundMessage) => ({
        type: actions.MANUAL_ADD_FUND_SUCCESS,
        manualAddFundMessage
    }),
    manualAddFundFailed: (manualAddFundError) => ({
        type: actions.MANUAL_ADD_FUND_FAILED,
        manualAddFundError
    }),
    resetManualAddFund:()=>({
        type:actions.RESET_MANUAL_ADD_FUND
    })

};
export default actions;
