import actions from "./actions";

const initState = {
    commissionListResult: [],
    loading: false,
    commissionError: null,
    commissionMessage: null,
    buttonLoading: false,
    commissionTotal:[],
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_COMMISSION_REPORT_LIST:
            return {
                ...state,
                loading: true,
                commissionError: null,
                commissionMessage: null,
                buttonLoading: true,
                commissionListResult:true,
            };
        case actions.GET_COMMISSION_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                commissionError: null,
                commissionMessage: null,
                commissionListResult: action.data,
                commissionTotal:action.total,
                buttonLoading: false,
            };
        case actions.GET_COMMISSION_REPORT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                commissionError: action.activationListError,
                commissionListResult: null,
                buttonLoading: false,
                commissionMessage:null,
            };
        default:
            return state;
    }
}