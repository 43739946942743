const actions = {
    GET_DASHBOARD_GRAPH_LIST: "GET_DASHBOARD_GRAPH_LIST",
    GET_DASHBOARD_GRAPH_LIST_SUCCESS: "GET_DASHBOARD_GRAPH_LIST_SUCCESS",
    GET_DASHBOARD_GRAPH_LIST_FAILED: "GET_DASHBOARD_GRAPH_LIST_FAILED",
    GET_WIDGET_DATA: 'GET_WIDGET_DATA',
    GET_WIDGET_DATA_SUCCESS: 'GET_WIDGET_DATA_SUCCESS',
    GET_WIDGET_DATA_FAILED: 'GET_WIDGET_DATA_FAILED',
    GET_CHART_DATA: "GET_CHART_DATA",
    GET_CHART_DATA_SUCCESS: "GET_CHART_DATA_SUCCESS",
    GET_CHART_DATA_FAILED: "GET_CHART_DATA_FAILED",
    GET_DASHBOARD_RECHARGE_LIST: "GET_DASHBOARD_RECHARGE_LIST",
    GET_DASHBOARD_RECHARGE_LIST_SUCCESS: "GET_DASHBOARD_RECHARGE_LIST_SUCCESS",
    GET_DASHBOARD_RECHARGE_LIST_FAILED: "GET_DASHBOARD_RECHARGE_LIST_FAILED",

    getDashboardGraphList: () => ({
        type: actions.GET_DASHBOARD_GRAPH_LIST,
    }),
    getDashboardGraphListSuccess: (dashboardListResult) => ({
        type: actions.GET_DASHBOARD_GRAPH_LIST_SUCCESS,
        dashboardListResult
    }),
    getDashboardGraphListFailed: (error) => ({
        type: actions.GET_DASHBOARD_GRAPH_LIST_FAILED,
        error
    }),
    getWidgetData: () => ({
        type: actions.GET_WIDGET_DATA,
    }),
    getWidgetDataSuccess: (widgetResult) => ({
        type: actions.GET_WIDGET_DATA_SUCCESS,
        widgetResult
    }),
    getWigetDataFailed: (error) => ({
        type: actions.GET_WIDGET_DATA_FAILED,
        error
    }),
    getChartData: () => ({
        type: actions.GET_CHART_DATA,
    }),
    getChartDataSuccess: (chartDataResult) => ({
        type: actions.GET_CHART_DATA_SUCCESS,
        chartDataResult
    }),
    getChartDataFailed: (error) => ({
        type: actions.GET_CHART_DATA_FAILED,
        error
    }),
    getDashboardRechargeList: () => ({
        type: actions.GET_DASHBOARD_RECHARGE_LIST,
    }),
    getDashboardRechargeListSuccess: (dashboardListResult) => ({
        type: actions.GET_DASHBOARD_RECHARGE_LIST_SUCCESS,
        dashboardListResult
    }),
    getDashboardRechargeListFailed: (error) => ({
        type: actions.GET_DASHBOARD_RECHARGE_LIST_FAILED,
        error
    }),
}
export default actions;