const actions = {
  GET_COUNTRY: 'GET_COUNTRY',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILED: 'GET_COUNTRY_FAILED',
  GET_COUNTRY_INTERNATIONAL: 'GET_COUNTRY_INTERNATIONAL',
  GET_COUNTRY_INTERNATIONAL_SUCCESS: 'GET_COUNTRY_INTERNATIONAL_SUCCESS',
  GET_COUNTRY__INTERNATIONAL_FAILED: 'GET_COUNTRY__INTERNATIONAL_FAILED',
  getCountry: () => ({
    type: actions.GET_COUNTRY,
  }),
  getCountrySuccess: (countryResult) => ({
    type: actions.GET_COUNTRY_SUCCESS,
    countryResult
  }),
  getCountryFailed: (countryError) => ({
    type: actions.GET_COUNTRY_FAILED,
    countryError
  }),
  getCountryInternational: () => ({
    type: actions.GET_COUNTRY_INTERNATIONAL,
  }),
  getCountryInternationalSuccess: (countryData) => ({
    type: actions.GET_COUNTRY_INTERNATIONAL_SUCCESS,
    countryData
  }),
  getCountryInternationalFailed: (countryError) => ({
    type: actions.GET_COUNTRY__INTERNATIONAL_FAILED,
    countryError
  }),

};
export default actions;
