import React from 'react';
import { useState, useEffect } from 'react'
import { Card, Form, Col, Row, Input, Button, Table, DatePicker } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import bulkReportActions from '../../redux/bulkReport/actions';
import moment from "moment";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import withTokenValidation from '../../withTokenValidation';

const columns = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'BATCH NUMBER',
        dataIndex: 'batchNumber',
        key: 'batchNumber',
    },
    {
        title: 'PLAN',
        dataIndex: 'plan',
        key: 'plan',
    },
    {
        title: 'PLAN MONTH',
        dataIndex: 'planMonth',
        key: 'planMonth',
    },
    {
        title: 'TOTAL ',
        dataIndex: 'total',
        key: 'total',
    },
    {
        title: 'PENDING',
        dataIndex: 'pending',
        key: 'pending',
    },
    {
        title: 'PROCCESSED',
        dataIndex: 'proccessed',
        key: 'proccessed',
    },
    {
        title: 'CANCELLED',
        dataIndex: 'cancelled',
        key: 'cancelled',
    },
    {
        title: 'PROCESSING',
        dataIndex: 'processing',
        key: 'processing',
    },
    {
        title: "BULK DATE",
        key: "bulkDate",
        dataIndex: "bulkDate",
    },
    {
        title: "BULK BY(USER)",
        key: "bulkBy",
        dataIndex: "bulkBy",
    },
];

const detailsColumn = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'BATCH NUMBER',
        dataIndex: 'batchNumber',
        key: 'batchNumber',
    },
    {
        title: 'SERIAL NUMBER',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
    },
    {
        title: 'ZIP CODE',
        dataIndex: 'zipCode',
        key: 'zipCode',
    },
    {
        title: 'MSISDN',
        dataIndex: 'msisdn',
        key: 'msisdn',
    },
    {
        title: 'PLAN',
        dataIndex: 'plan',
        key: 'plan',
    },
    {
        title: 'PLAN MONTH',
        dataIndex: 'planMonth',
        key: 'planMonth',
    },
    {
        title: 'PLAN AMOUNT',
        dataIndex: 'planAmount',
        key: 'planAmount',
    },
    {
        title: 'PLAN TYPE',
        dataIndex: 'planType',
        key: 'planType',
    },
    {
        title: 'SIM TYPE',
        dataIndex: 'simType',
        key: 'simType',
    },
    {
        title: "BULK DATE",
        key: "bulkDate",
        dataIndex: "bulkDate",
    },
    {
        title: "BULK BY(USER)",
        key: "bulkBy",
        dataIndex: "bulkBy",
    },

    {
        title: 'CUSTOMER NAME',
        dataIndex: 'customerName',
        key: 'customerName',
    },
];

function BulkReport() {
    const { getBulkActivationReportDetails, getBulkActivationReportList } = bulkReportActions;
    const dispatch = useDispatch();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const bulkReportResult = useSelector((state) => state.bulkReport.bulkReportResult);
    const bulkDetailsResult = useSelector((state) => state.bulkReport.bulkDetailsResult);
    const loading = useSelector((state) => state.bulkReport.loading);
    const buttonLoading = useSelector((state) => state.bulkReport.buttonLoading);
    const defaultFromDate = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const defaultToDate = moment().format('YYYY-MM-DD');

    const [searchData, setSearchData] = useState({
        batchNumber: '',
        fromDate: defaultFromDate[0],
        toDate: defaultToDate,
    })
    const oneWeekFromToday = moment().startOf('day').subtract(7, 'days');
    const today = moment().startOf('day');
    const [fromDateShow, setfromDateShow] = useState(oneWeekFromToday);
    const [toDateShow, settoDateShow] = useState(today);
    const [showDetailPage, setshowDetailPage] = useState(false);

    useEffect(() => {
        dispatch(getBulkActivationReportList(searchData))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleBatchNumber = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleToDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            toDate: formattedDate
        }));
    }

    const handleFromDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            fromDate: formattedDate
        }));
    }

    // const disabledToDate = (current) => {
    //     const fromDate = moment(searchData.fromDate);
    //     return fromDate ? current.isBefore(fromDate, 'day') || current.isAfter(moment()) : false;
    // };

    const handleSearch = () => {
        dispatch(getBulkActivationReportList(searchData))
    }

    const hanldeshowActivationByStatus = (bulkActivationId, status) => {
        setshowDetailPage(true);
        const data = {
            bulkId: bulkActivationId,
            status: status
        }
        dispatch(getBulkActivationReportDetails(data));
    }

    const handleCancel = () => {
        setshowDetailPage(false);
        const data = {
            batchNumber: '',
            fromDate: defaultFromDate[0],
            toDate: defaultToDate,
        }
        dispatch(getBulkActivationReportList(data))
    }

    var dataSource = bulkReportResult && bulkReportResult.length > 0 && bulkReportResult.map((report, key) => {
        return {
            key: key + 1,
            batchNumber: report.BatchNumber,
            plan: report.PlanName !== null && report.PlanName !== "" ? report.PlanName : <p style={{ color: 'grey' }}>N/A</p>,
            planMonth: report.PlanMonth !== null && report.PlanMonth !== "" ? `${report.PlanMonth} Month` : <p style={{ color: 'grey' }}>N/A</p>,
            total: <Link to="#" onClick={() => { hanldeshowActivationByStatus(report.BulkActivationID, 0) }}>{report.Total}</Link>,
            pending: (report.Pending !== 0 ? <Link to="#" onClick={() => { hanldeshowActivationByStatus(report.BulkActivationID, 10) }}>{report.Pending}</Link> : report.Pending),
            processing: (report.Processing !== 0 ? <Link to="#" onClick={() => { hanldeshowActivationByStatus(report.BulkActivationID, 11) }}>{report.Processing}</Link> : report.Processing),
            proccessed: (report.Processed !== 0 ? <Link to="#" onClick={() => { hanldeshowActivationByStatus(report.BulkActivationID, 12) }}>{report.Processed}</Link> : report.Processed),
            cancelled: (report.Cancelled !== 0 ? <Link to="#" onClick={() => { hanldeshowActivationByStatus(report.BulkActivationID, 13) }}>{report.Cancelled}</Link> : report.Cancelled),
            bulkDate: report.CreatedDtTm ? moment(new Date(report.CreatedDtTm)).format("MM/DD/YYYY") : '',
            bulkBy: report.PurchasedBy,

        }
    });

    var detailsDataSource = bulkDetailsResult && bulkDetailsResult.length > 0 && bulkDetailsResult.map((report, key) => {
        return {
            key: key + 1,
            batchNumber: report.BatchNumber,
            plan: report.PlanName !== null && report.PlanName !== "" ? report.PlanName : <p style={{ color: 'grey' }}>N/A</p>,
            planMonth: report.PlanMonth !== null && report.PlanMonth !== "" ? `${report.PlanMonth} Month` : <p style={{ color: 'grey' }}>N/A</p>,
            serialNumber: report.SerialNumber,
            zipCode: report.ZipCode,
            simType: report.SIMType,
            planType: report.PlanType,
            planAmount: report.PlanAmount !== null && report.PlanAmount !== "" ? `$${report.PlanAmount}` : <p style={{ color: 'grey' }}>N/A</p>,
            msisdn: report.MSISDN,
            bulkDate: report.CreatedDtTm ? moment(new Date(report.CreatedDtTm)).format("MM/DD/YYYY") : '',
            bulkBy: report.PurchasedBy,
            customerName: report.CustomerName
        }
    });

    var listData = bulkReportResult && bulkReportResult.length > 0 && bulkReportResult.map((report, key) => {
        return {
            key: key + 1,
            BatchNumber: report.BatchNumber,
            Plan: report.PlanName,
            PlanMonth: report.PlanMonth,
            Total: report.Total,
            Pending: report.Pending,
            Proccessed: report.Processed,
            Cancelled: report.Cancelled,
            Processing: report.Processing,
            BulkDate: report.CreatedDtTm ? moment(new Date(report.CreatedDtTm)).format("MM/DD/YYYY") : '',
            BulkBy: report.PurchasedBy,
        }
    });

    var detailsListData = bulkDetailsResult && bulkDetailsResult.length > 0 && bulkDetailsResult.map((report, key) => {
        return {
            key: key + 1,
            BatchNumber: report.BatchNumber,
            SerialNumber: report.SerialNumber,
            ZipCode: report.ZipCode,
            MSISDN: report.MSISDN,
            Plan: report.PlanName,
            PlanMonth: report.PlanMonth,
            PlanAmount: report.PlanAmount,
            PlanType: report.PlanType,
            SimType: report.SIMType,
            BulkDate: report.CreatedDtTm ? moment(new Date(report.CreatedDtTm)).format("MM/DD/YYYY") : '',
            BulkBy: report.PurchasedBy,
            CustomerName: report.CustomerName
        }
    });

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'BulkReport' + fileExtension);
        }
    }

    const exportToCSVDetailData = async () => {
        if (detailsListData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(detailsListData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'BulkDetailsReport' + fileExtension);
        }
    }


    return (
        <>
            {!showDetailPage && <Card>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        <Col span={7}>
                            <Form.Item className="username" colon={false} name="fromDate" label="From Date" size="large">
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={fromDateShow} onChange={handleFromDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item className="username" colon={false} name="toDate" label="To Date" size="large"  >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={toDateShow} onChange={handleToDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                className="username"
                                colon={false}
                                name="batchNumber"
                                label="Batch Number"
                                size="large"
                            >
                                <Input placeholder="Batch Number" name="batchNumber" onChange={handleBatchNumber} />
                            </Form.Item>
                        </Col>

                        <Col span={2} style={{paddingTop:'4px'}}>
                            <Button type="primary" htmlType='submit' loading={buttonLoading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>}
            {!showDetailPage && <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        className="criclebox tablespace mb-24"
                        title="Bulk Report"
                        extra={
                            <>
                                <Button onClick={() => exportToCSV()} style={{  float: 'right', backgroundColor: '#35267a', color: 'white', border: 'none' }}><DownloadOutlined style={{ fontSize: '20px', paddingTop: "9px" }} /> DOWNLOAD REPORT</Button>
                            </>
                        }
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>}
            {showDetailPage && <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        title="Bulk Report"
                        extra={
                            <>
                                <Button onClick={() => exportToCSVDetailData()} style={{ marginBottom: 16, float: 'right', backgroundColor: '#35267a', color: 'white', border: 'none' }}><DownloadOutlined style={{ fontSize: '20px', paddingTop: "9px" }} /> DOWNLOAD REPORT</Button>
                            </>
                        }

                    >
                        <div className="table-responsive">
                            <Table
                                columns={detailsColumn}
                                dataSource={detailsDataSource}
                                pagination={false}
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                        <Button type="danger" style={{ float: 'right', marginTop: '10px' }} onClick={handleCancel}>BACK</Button>
                    </Card>
                </Col>
            </Row>}
        </>
    )
}

export default withTokenValidation(BulkReport);
