import { Layout } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Footer() {
  const { Footer: AntFooter } = Layout;
  const currentYr = new Date().getFullYear();
  return (
    <AntFooter style={{ background: "#fafafa" }}>
          {/* <div className="copyright">
            © {currentYr}
            <Link to="#" className="font-weight-bold">
              Virtuzo Infosystems Pvt Ltd
            </Link>
          </div> */}
    </AntFooter>
  );
}

export default Footer;
