import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';


function* getBlankActivationResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/getBlankActivation', searchData);
        // const responseP = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.getBlankSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getBlankFailed());
        }
    } catch (error) {
        yield put(actions.getBlankFailed());
    }
}

function* getBlankPrepareFormResponse() {
    try {
        const response = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.prepareAddBlankFormSuccess(
                    response.response.Data.BatchNumber
                )
            );
        } else {
            yield put(actions.prepareAddBlankFormFailed());
        }
    } catch (error) {
        yield put(actions.prepareAddBlankFormFailed());
    }
}

function* validateBlankUploadDataResponse({ payload: { blankuploadData } }) {
    try {
        const response = yield call(addData, '/validateBulkUpload', blankuploadData);
        if (response.response.Status) {
            yield put(
                actions.validateBlankDataSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.validateBlankDataFailed(response.response.Message, response.response.simData));
        }
    } catch (error) {
        yield put(actions.validateBlankDataFailed(error));
    }
}

function* addBlankDataResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createActivation', data);
        if (response.response.Status) {
            const responseList = yield call(addData, '/getBlankActivation', searchData);
            if (responseList.response.Status) {
                yield put(
                    actions.addBlankSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addBlankFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addBlankFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addBlankFailed(error));
    }
}

function* getBlankDetailsResponse({ payload: { blankId } }) {
    try {
        const response = yield call(getList, '/bulk/' + blankId);
        if (response.response.Status) {
            yield put(
                actions.getBlankDetailsSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getBlankFailed());
        }
    } catch (error) {
        yield put(actions.getBlankFailed());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_BLANK, getBlankActivationResponse)]);
    yield all([takeEvery(actions.PREPARE_ADD_BLANK_FORM, getBlankPrepareFormResponse)]);
    yield all([takeEvery(actions.VALIDATE_BLANK_DATA, validateBlankUploadDataResponse)]);
    yield all([takeEvery(actions.ADD_BLANK, addBlankDataResponse)]);
    yield all([takeEvery(actions.GET_BLANK_DETAILS, getBlankDetailsResponse)]);

}
