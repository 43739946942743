import actions from "./actions";

const initState = {
    activationListResult: [],
    loading: false,
    activationListError: null,
    activationListMessage: null,
    buttonLoading: false,

};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ACTIVATION_REPORT_LIST:
            return {
                ...state,
                loading: true,
                activationListError: null,
                activationListMessage: null,
                buttonLoading :true
            };
        case actions.GET_ACTIVATION_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                activationListError: null,
                activationListMessage: null,
                activationListResult: action.activationListResult,
                buttonLoading:false
            };
        case actions.GET_ACTIVATION_REPORT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                activationListError: action.activationListError,
                activationListMessage: null,
                buttonLoading:false
            };
        default:
            return state;
    }
}