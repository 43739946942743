import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';


function* getBulkActivationResponse({payload:{searchData}}) {
    try {
        const response = yield call(addData, '/bulkActivation',searchData);
        // const responseP = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.getBulkSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getBulkFailed());
        }
    } catch (error) {
        yield put(actions.getBulkFailed());
    }
}

function* getBulkPrepareFormResponse() {
    try {
        const response = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.prepareAddBulkFormSuccess(
                    response.response.Data.BatchNumber
                )
            );
        } else {
            yield put(actions.prepareAddBulkFormFailed());
        }
    } catch (error) {
        yield put(actions.prepareAddBulkFormFailed());
    }
}

function* validateBulkUploadDataResponse({ payload: { bulkuploadData } }) {
    try {
        const response = yield call(addData, '/validateBulkUpload', bulkuploadData);
        if (response.response.Status) {
            yield put(
                actions.validateBulkDataSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.validateBulkDataFailed(response.response.Message, response.response.simData));
        }
    } catch (error) {
        yield put(actions.validateBulkDataFailed(error));
    }
}

function* addBulkDataResponse({ payload: { data,searchData } }) {
    try {
        const response = yield call(addData, '/createBulkActivation', data);
        if (response.response.Status) {
            const responseList = yield call(addData, '/bulkActivation',searchData);
            if (responseList.response.Status) {
                yield put(
                    actions.addBulkSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addBulkFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addBulkFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addBulkFailed(error));
    }
}

function* getBulkDetailsResponse({ payload: { bulkId } }) {
    try {
        const response = yield call(getList, '/bulk/' + bulkId);
        if (response.response.Status) {
            yield put(
                actions.getBulkDetailsSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getBulkFailed());
        }
    } catch (error) {
        yield put(actions.getBulkFailed());
    }
}


function* updateCancelActivationResponse({ payload: { bulkId, data } }) {
    try {
        const response = yield call(addData, '/updateCancelActivation/' + bulkId, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/bulk/' + data.batchId);
            if (responseList.response.Status) {
                yield put(
                    actions.updateCancelActivationSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            }
        } else {
            yield put(actions.updateCancelActivationFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.updateCancelActivationFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.VALIDATE_BULK_DATA, validateBulkUploadDataResponse)]);
    yield all([takeEvery(actions.ADD_BULK, addBulkDataResponse)]);
    yield all([takeEvery(actions.GET_BULK, getBulkActivationResponse)]);
    yield all([takeEvery(actions.GET_BULK_DETAILS, getBulkDetailsResponse)]);
    yield all([takeEvery(actions.PREPARE_ADD_BULK_FORM, getBulkPrepareFormResponse)]);
    yield all([takeEvery(actions.UPDATE_CANCEL_ACTIVATION, updateCancelActivationResponse)]);

}
