import React from 'react';
import { useState, useEffect } from 'react'
import { Card, Form, Col, Row, Input, Button, Table, DatePicker, Tag, Select } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { DownloadOutlined, SyncOutlined, ClockCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import withTokenValidation from '../../withTokenValidation';
import authActions from '../../redux/auth/actions';
import dealerActions from '../../redux/dealer/actions';
import { convertDateTimeToRequiredFormatTZ } from '../../helpers/commonFunction';
import commissionReportActions from '../../redux/commissionReport/actions'


const columns = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'ORDER NUMBER',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
    },
    {
        title: "MOBILE NUMBER",
        key: "mobileNumber",
        dataIndex: "mobileNumber",
    },
    {
        title: 'COMMISSION',
        dataIndex: 'commission',
        key: 'commission',
    },
    {
        title: 'TXN ID',
        dataIndex: 'txnID',
        key: 'txnID',
    },
    {
        title: 'PLAN',
        dataIndex: 'plan',
        key: 'plan',
    },
    {
        title: "PLAN PRICE",
        key: "planPrice",
        dataIndex: "planPrice",
    },
    {
        title: "DEALER NAME",
        key: "dealerName",
        dataIndex: "dealerName",
    },
    {
        title: 'DATE',
        dataIndex: 'CreatedDtTm',
        key: 'CreatedDtTm',
    },
    {
        title: 'REMARK',
        dataIndex: 'remark',
        key: 'remark',
        render: (text) => <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>,
    },
];

function RechargeReport() {
    const { Option } = Select;
    const dispatch = useDispatch();
    const { getProfileData } = authActions;
    const { getDealer } = dealerActions;
    const { getCommissionReportList } = commissionReportActions;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const { profileDataResult: tokenData } = useSelector((state) => state.auth);
    const {commissionListResult,commissionTotal} = useSelector((state) => state.commissionReport);
    const loading = useSelector((state) => state.commissionReport.loading);
    const buttonLoading = useSelector((state) => state.commissionReport.buttonLoading);
    const defaultFromDate = useState(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    const defaultToDate = moment().format('YYYY-MM-DD');
    const [searchData, setSearchData] = useState({
        fromDate: defaultFromDate[0],
        toDate: defaultToDate,
        dealer: '',
    })
    const oneWeekFromToday = moment().startOf('day').subtract(2, 'days');
    const today = moment().startOf('day');
    const [fromDateShow, setfromDateShow] = useState(oneWeekFromToday);
    const [toDateShow, settoDateShow] = useState(today);

    useEffect(() => {
        dispatch(getCommissionReportList(searchData))
        dispatch(getProfileData());
        dispatch(getDealer());

    }, [])

    const handleToDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            toDate: formattedDate
        }));
    }

    const handleFromDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            fromDate: formattedDate
        }));
    }
    const handleSelectDealer = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            dealer: e
        }));
    }

    const handleSearch = () => {
        dispatch(getCommissionReportList(searchData))
    }

    const dataSource = commissionListResult && commissionListResult.length > 0 && commissionListResult.map((report, key) => {
        let jarIPAddress = report.JarIPAddress;
        if (typeof jarIPAddress === 'string') {
            jarIPAddress = jarIPAddress.replace("::ffff:", "").trim();
        } else {
            jarIPAddress = '';
        }
        return {
            key: key + 1,
            orderNumber: report.OrderNumber,
            mobileNumber: report.MSISDN,
            commission: <span className={report.Amount < 0 ? `text-danger` : `text-success`}>{`$${report.Amount >= 0 ? report.Amount.toFixed(2) : report.Amount.toFixed(2)}`}</span>,
            txnID: report.TXNID,
            plan: report.PlanName,
            planPrice: <span className={report.Amount < 0 ? `text-danger` : `text-success`}>{`$${report.Amount >= 0 ? report.PlanPrice.toFixed(2) : report.PlanPrice.toFixed(2)}`}</span>,
            dealerName: report.DealerName,
            CreatedDtTm: convertDateTimeToRequiredFormatTZ(report.CreatedDtTm),
            remark: report.Remarks,
        }
    });

    const listData = commissionListResult && commissionListResult.length > 0 && commissionListResult.map((report, key) => {
        return {
            'S No.': key + 1,
            'Order Number': report.OrderNumber,
            'Mobile Number': report.MSISDN,
            Commission: report.Amount ? `$${report.Amount.toFixed(2)}` : '',
            TxnID: report.TXNID,
            Plan: report.PlanName,
            'Plan Price': report.PlanPrice ? `$${report.PlanPrice.toFixed(2)}` : '',
            'Dealer Name': report.DealerName,
            Date: convertDateTimeToRequiredFormatTZ(report.CreatedDtTm),
            Remark: report.Remarks,
        }
    });

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Commission_Report' + fileExtension);
        }
    }

    return (
        <>
            <Card>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        {tokenData && tokenData.length > 0 && tokenData[0].IsCompany === 1 ? <Col span={4}>
                            <Form.Item className="username" label="Dealer" name="dealer">
                                <Select onChange={handleSelectDealer} name="dealer" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.dealer}>
                                    <Option value="" key="0">ALL</Option>
                                    {dealerResult && dealerResult.length > 0 && dealerResult
                                        .filter(dealer => dealer.IsCompany !== 1)
                                        .map(dealer => (
                                            <Option value={dealer.ID} key={dealer.ID}>{dealer.DealerName}</Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col> : ''}
                        <Col span={4}>
                            <Form.Item className="username" colon={false} name="fromDate" label="From Date" size="large">
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} defaultValue={fromDateShow} className="ant-datepicker" allowClear={false} inputReadOnly={true} onChange={handleFromDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item className="username" colon={false} name="toDate" label="To Date" size="large"  >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={toDateShow} onChange={handleToDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{ paddingTop: '4px' }}>
                            <Button type="primary" htmlType='submit' loading={buttonLoading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <div className='commissioncard'>
            <Card bordered={false}>
                <div>
                    <div className='commision-inner'>
                    <h2>Commission</h2>
                    </div>
                   
                    {commissionTotal && commissionTotal.length > 0 &&
                            <div className='dolor-inner-2' style={{ display: "flex", alignItems: "baseline" }}>
                                {commissionTotal[0].TotalCommission ? `$${commissionTotal[0].TotalCommission.toFixed(2)}` : '$0.00'}
                            </div>
                    }
                </div>
            </Card>
            </div>
            
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        className="criclebox tablespace mb-24"
                        title="Commission Report"
                        extra={
                            <>
                                <Button onClick={() => exportToCSV()} style={{ float: 'right', backgroundColor: '#35267a', color: 'white', border: 'none' }}><DownloadOutlined style={{ fontSize: '20px', paddingTop: "5px" }} /> DOWNLOAD REPORT</Button>
                            </>
                        }
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default withTokenValidation(RechargeReport);