import React from 'react';
import { useState, useEffect } from 'react'
import { Card, Form, Col, Row, Input, Button, Table, DatePicker, Tag, Select } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { DownloadOutlined, SyncOutlined, ClockCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import activationReportActions from '../../redux/activationReport/actions';
import shortCodeDataActions from '../../redux/shortCodeData/actions';
import withTokenValidation from '../../withTokenValidation';
const columns = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'BATCH NUMBER',
        dataIndex: 'batchNumber',
        key: 'batchNumber',
    },
    {
        title: 'TRANSACTION ID',
        dataIndex: 'txnId',
        key: 'txnId',
    },
    {
        title: 'SERIAL NUMBER',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
    },
    {
        title: 'ZIP CODE',
        dataIndex: 'zipCode',
        key: 'zipCode',
    },
    {
        title: 'MSISDN',
        dataIndex: 'msisdn',
        key: 'msisdn',
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: "DATE",
        key: "date",
        dataIndex: "date",
    },
    {
        title: 'ACTIVATION DATE',
        dataIndex: 'activationDate',
        key: 'activationDate',
    },

    {
        title: 'PLAN',
        dataIndex: 'plan',
        key: 'plan',
    },
    {
        title: 'PLAN MONTH',
        dataIndex: 'planMonth',
        key: 'planMonth',
    },
    {
        title: 'PLAN AMOUNT',
        dataIndex: 'planAmount',
        key: 'planAmount',
    },
    {
        title: 'PLAN TYPE',
        dataIndex: 'planType',
        key: 'planType',
    },
    {
        title: 'SIM TYPE',
        dataIndex: 'simType',
        key: 'simType',
    },
    {
        title: "BULK BY(USER)",
        key: "bulkBy",
        dataIndex: "bulkBy",
    },

    {
        title: 'CUSTOMER NAME',
        dataIndex: 'customerName',
        key: 'customerName',
    }

];
function ActivationReport() {
    const dispatch = useDispatch();
    const { getActivationReportList } = activationReportActions;
    const { getShortCode } = shortCodeDataActions;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const activationListResult = useSelector((state) => state.activationReport.activationListResult);
    const buttonLoading = useSelector((state) => state.activationReport.buttonLoading);
    const shortCodeResult = useSelector((state) => state.shortCodeData.shortCodeResult);
    const loading = useSelector((state) => state.activationReport.loading);
    const defaultFromDate = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const defaultToDate = moment().format('YYYY-MM-DD');
    const [searchData, setSearchData] = useState({
        transactionID: '',
        fromDate: defaultFromDate[0],
        toDate: defaultToDate,
        activationStatus: ""
    })
    const [statusData, setstatusData] = useState({
        tableName: "bulk_activation_details",
        fieldName: "RequestStatus"
    })
    const oneWeekFromToday = moment().startOf('day').subtract(7, 'days');
    const today = moment().startOf('day');
    const [fromDateShow, setfromDateShow] = useState(oneWeekFromToday);
    const [toDateShow, settoDateShow] = useState(today);

    useEffect(() => {
        dispatch(getActivationReportList(searchData))
        dispatch(getShortCode(statusData))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTransactionId = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleToDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            toDate: formattedDate
        }));
    }

    const handleFromDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            fromDate: formattedDate
        }));
    }

    const handleSelectStatus = (e) => {
        const findStatus = e === 11 ? '11,19,27' : e
        setSearchData(prevState => ({
            ...prevState,
            activationStatus: findStatus
        }));
    }

    const handleSearch = () => {
        dispatch(getActivationReportList(searchData))
    }

    var dataSource = activationListResult && activationListResult.length > 0 && activationListResult.map((report, key) => {
        return {
            key: key + 1,
            plan: report.PlanName !== null && report.PlanName !== "" ? report.PlanName : <p style={{ color: 'grey' }}>N/A</p>,
            planMonth: report.PlanMonth !== null && report.PlanMonth !== "" ? `${report.PlanMonth} Month` : <p style={{ color: 'grey' }}>N/A</p>,
            serialNumber: report.SerialNumber,
            txnId: report.TransactionID,
            zipCode: report.ZipCode,
            simType: report.SIMType,
            batchNumber: report.BatchNumber,
            planType: report.PlanType,
            planAmount: report.PlanAmount !== null ? `$${report.PlanAmount}` : <p style={{ color: 'grey' }}>N/A</p>,
            msisdn: report.MSISDN,
            customerName: report.CustomerName,
            bulkBy: report.PurchasedBy,
            activationDate: report.CompletionDtTm ? moment(new Date(report.CompletionDtTm)).format("MM/DD/YYYY") : '',
            date: report.RequestedDtTm ? moment(new Date(report.RequestedDtTm)).format("MM/DD/YYYY") : '',
            status: <Tag icon={report.RequestStatus === 'Pending' ? <ClockCircleOutlined /> : report.RequestStatus === 'Processing' || report.RequestStatus === 'Plan Selected' || report.RequestStatus === 'Assigned to Care' ? <SyncOutlined spin /> : report.RequestStatus === 'Processed' ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />} color={report.RequestStatus === 'Pending' ? 'default' : report.RequestStatus === 'Processing' || report.RequestStatus === 'Plan Selected' || report.RequestStatus === 'Assigned to Care' ? 'processing' : report.RequestStatus === 'Processed' ? 'success' : 'error'}>
                {report.RequestStatus === 'Assigned to Care' || report.RequestStatus === 'Plan Selected' ? 'Processing' : report.RequestStatus}
            </Tag>
        }
    });

    var listData = activationListResult && activationListResult.length > 0 && activationListResult.map((report, key) => {
        return {
            key: key + 1,
            BatchNumber: report.BatchNumber,
            TRANSACTIONID: report.TransactionID,
            SerialNumber: report.SerialNumber,
            ZipCode: report.ZipCode,
            Status: report.RequestStatus === 'Assigned to Care' || report.RequestStatus === 'Plan Selected' ? 'Processing' : report.RequestStatus,
            MSISDN: report.MSISDN,
            Date: report.RequestedDtTm ? moment(new Date(report.RequestedDtTm)).format("MM/DD/YYYY") : '',
            ActivationDate: report.CompletionDtTm ? moment(new Date(report.CompletionDtTm)).format("MM/DD/YYYY") : '',
            Plan: report.PlanName,
            PlanMonth: report.PlanMonth,
            PlanAmount: report.PlanAmount.toFixed(2),
            PlanType: report.PlanType,
            SimType: report.SIMType,
            BulkBy: report.PurchasedBy,
            CustomerName: report.CustomerName,
        }
    });

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'ActivationReports' + fileExtension);
        }
    }

    return (
        <>
            <Card>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        <Col span={5}>
                            <Form.Item className="username" colon={false} name="fromDate" label="From Date" size="large">
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={fromDateShow} onChange={handleFromDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item className="username" colon={false} name="toDate" label="To Date" size="large"  >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={toDateShow} onChange={handleToDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item className="username" label="Status" name="activationStatus" >
                                <Select onChange={handleSelectStatus} name="activationStatus" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.activationStatus}>
                                    <Select.Option value="" key="0">ALL</Select.Option>
                                    {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map(shortcode => (
                                        shortcode.Value !== 'Plan Selected' && shortcode.Value !== 'Assigned to Care' && <Select.Option value={shortcode.ID} key={shortcode.ID} >{shortcode.Value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item
                                className="username"
                                colon={false}
                                name="transactionID"
                                label="Serial No./Txn Id/MSISDN"
                                size="large"
                            >
                                <Input placeholder="Serial No./Txn Id/MSISDN" name="transactionID" onChange={handleTransactionId} />
                            </Form.Item>
                        </Col>

                        <Col span={2} style={{paddingTop:'4px'}}>
                            <Button type="primary" htmlType='submit' loading={buttonLoading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        className="criclebox tablespace mb-24"
                        title="Activation Report"
                        extra={
                            <>
                                <Button onClick={() => exportToCSV()} style={{ float: 'right', backgroundColor: '#35267a', color: 'white', border: 'none' }}><DownloadOutlined style={{ fontSize: '20px', paddingTop: "9px" }} /> DOWNLOAD REPORT</Button>
                            </>
                        }
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default withTokenValidation(ActivationReport);