const actions = {
    GET_ACTIVATION_REPORT_LIST: "GET_ACTIVATION_REPORT_LIST",
    GET_ACTIVATION_REPORT_LIST_SUCCESS: "GET_ACTIVATION_REPORT_LIST_SUCCESS",
    GET_ACTIVATION_REPORT_LIST_FAILED: "GET_ACTIVATION_REPORT_LIST_FAILED",

    getActivationReportList: (data) => ({
        type: actions.GET_ACTIVATION_REPORT_LIST,
        payload: { data }
    }),
    getActivationReportListSuccess: (activationListResult) => ({
        type: actions.GET_ACTIVATION_REPORT_LIST_SUCCESS,
        activationListResult
    }),
    getActivationReportListFailed: (activationListError) => ({
        type: actions.GET_ACTIVATION_REPORT_LIST_FAILED,
        activationListError
    })
}
export default actions;