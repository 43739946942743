import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* getCommissionReportListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getCommissionReport', data);
        if (response.response.Status) {
            yield put(
                actions.getCommissionReportListSuccess( response.response.Data, response.response.TotalCommission)
            )
        } else {
            yield put(actions.getCommissionReportListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCommissionReportListFailed(error));
    }
}




export default function* rootSaga() {
    yield all([takeEvery(actions.GET_COMMISSION_REPORT_LIST, getCommissionReportListResponse)]);
}