const actions = {
    GET_BULK: 'GET_BULK',
    GET_BULK_SUCCESS: 'GET_BULK_SUCCESS',
    GET_BULK_FAILED: 'GET_BULK_FAILED',
    ADD_BULK: "ADD_BULK",
    ADD_BULK_SUCCESS: "ADD_BULK_SUCCESS",
    ADD_BULK_FAILED: "ADD_BULK_FAILED",
    GET_BULK_DETAILS: "GET_BULK_DETAILS",
    GET_BULK_DETAILS_SUCCESS: "GET_BULK_DETAILS_SUCCESS",
    VALIDATE_BULK_DATA: 'VALIDATE_BULK_DATA',
    VALIDATE_BULK_DATA_SUCCESS: 'VALIDATE_BULK_DATA_SUCCESS',
    VALIDATE_BULK_DATA_FAILED: 'VALIDATE_BULK_DATA_FAILED',
    PREPARE_ADD_BULK_FORM: "PREPARE_ADD_BULK_FORM",
    PREPARE_ADD_BULK_FORM_SUCCESS: "PREPARE_ADD_BULK_FORM_SUCCESS",
    PREPARE_ADD_BULK_FORM_FAILED: "PREPARE_ADD_BULK_FORM_FAILED",
    RESET_BULK_ACTIVATION: 'RESET_BULK_ACTIVATION',
    RESET_BULK_MESSAGE: 'RESET_BULK_MESSAGE',
    UPDATE_CANCEL_ACTIVATION: 'UPDATE_CANCEL_ACTIVATION',
    UPDATE_CANCEL_ACTIVATION_SUCCESS: 'UPDATE_CANCEL_ACTIVATION_SUCCESS',
    UPDATE_CANCEL_ACTIVATION_FAILED: "UPDATE_CANCEL_ACTIVATION_FAILED",
    PREPARE_CANCEL_MODAL_FORM: 'PREPARE_CANCEL_MODAL_FORM',
    RESET_CANCEL_MODAL_FORM: 'RESET_CANCEL_MODAL_FORM',

    getBulk: (searchData) => ({
        type: actions.GET_BULK,
        payload: { searchData }
    }),
    getBulkSuccess: (bulkResult) => ({
        type: actions.GET_BULK_SUCCESS,
        bulkResult
    }),
    getBulkFailed: (bulkError) => ({
        type: actions.GET_BULK_FAILED,
        bulkError
    }),
    addBulk: (data,searchData) => ({
        type: actions.ADD_BULK,
        payload: { data,searchData }
    }),
    addBulkSuccess: (bulkMessage, bulkResult) => ({
        type: actions.ADD_BULK_SUCCESS,
        bulkMessage, bulkResult
    }),
    addBulkFailed: (bulkError) => ({
        type: actions.ADD_BULK_FAILED,
        bulkError
    }),
    getBulkDetails: (bulkId) => ({
        type: actions.GET_BULK_DETAILS,
        payload: { bulkId }
    }),
    getBulkDetailsSuccess: (bulkDetails) => ({
        type: actions.GET_BULK_DETAILS_SUCCESS,
        bulkDetails
    }),
    validateBulkData: (bulkuploadData) => ({
        type: actions.VALIDATE_BULK_DATA,
        payload: { bulkuploadData }
    }),
    validateBulkDataSuccess: (bulkMessage) => ({
        type: actions.VALIDATE_BULK_DATA_SUCCESS,
        bulkMessage
    }),
    validateBulkDataFailed: (bulkError, simData) => ({
        type: actions.VALIDATE_BULK_DATA_FAILED,
        bulkError, simData
    }),
    prepareAddBulkForm: () => ({
        type: actions.PREPARE_ADD_BULK_FORM
    }),
    prepareAddBulkFormSuccess: (bulkInvResult) => ({
        type: actions.PREPARE_ADD_BULK_FORM_SUCCESS,
        bulkInvResult
    }),
    prepareAddBulkFormFailed: (bulkError) => ({
        type: actions.PREPARE_ADD_BULK_FORM_FAILED,
        bulkError
    }),
    resetBulkActivation: () => ({
        type: actions.RESET_BULK_ACTIVATION
    }),
    resetBulkMessage: () => ({
        type: actions.RESET_BULK_MESSAGE
    }),
    prepareCancelFormModal: () => ({
        type: actions.PREPARE_CANCEL_MODAL_FORM
    }),
    updateCancelActivation: (bulkId, data) => ({
        type: actions.UPDATE_CANCEL_ACTIVATION,
        payload: { bulkId, data }
    }),
    updateCancelActivationSuccess: (bulkMessage, bulkDetails) => ({
        type: actions.UPDATE_CANCEL_ACTIVATION_SUCCESS,
        bulkMessage, bulkDetails
    }),
    updateCancelActivationFailed: (error) => ({
        type: actions.UPDATE_CANCEL_ACTIVATION_FAILED,
        error
    }),
    resetModalCancelForm: () => ({
        type: actions.RESET_CANCEL_MODAL_FORM
    })
};
export default actions;
