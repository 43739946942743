import React, { useEffect, useState } from 'react'
import { Card, Table, Row, Col, Tag, message, Space, Button, Popconfirm, Form, Tooltip, DatePicker, Select } from 'antd';
import { DeleteOutlined, CheckOutlined, } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import planActions from '../../../redux/plan/actions';
import withTokenValidation from '../../../withTokenValidation';
import { isAdmin } from '../../../helpers/commonFunction';
import countryAction from '../../../redux/country/actions';
import ReactCountryFlag from "react-country-flag";
import networkAction from '../../../redux/network/actions'


const {getNetworksByCountryID } = networkAction;
const { getCountry } = countryAction;

function Plan() {
    const { Option } = Select;
    const { getPlan, resetPlanMessage, deletePlan } = planActions;
    const dispatch = useDispatch();
    const { profileDataResult: tokenData } = useSelector((state) => state.auth);
    const planResult = useSelector((state) => state.plan.planResult);
    const loading = useSelector((state) => state.plan.loading);
    const planMessage = useSelector((state) => state.plan.planMessage);
    const planError = useSelector((state) => state.plan.planError);
    const countryResult = useSelector((state) => state.country.countryResult);
    const { getNetworksByCountryIdList } = useSelector((state) => state.network);

    const [searchData, setSearchData] = useState({
        countryID: "",
        networkID: "",
    })
    useEffect(() => {
        dispatch(getPlan(searchData));
        dispatch(getCountry())
        dispatch(getNetworksByCountryID(searchData.countryID ? searchData.countryID : 0 ))
    }, [])

    const handleDelete = (ID, flag) => {
        dispatch(deletePlan(ID, flag,searchData))
    }

    let columns = [];
    if (isAdmin(tokenData)) {
        columns = [
            {
                title: "#",
                dataIndex: "key",
                key: "key",
            },
            {
                title: "PLAN NAME",
                dataIndex: "planName",
                key: "planName",
            },
            {
                title: "PLAN PRICE",
                key: "planPrice",
                dataIndex: "planPrice",
            },
            {
                title: "NETWORK",
                dataIndex: "network",
                key: "network",
            },
            {
                title: "STATUS",
                key: "status",
                dataIndex: "status",
            },
            {
                title: "ACTION",
                key: "action",
                dataIndex: "action",
            },
        ];
    } else {
        columns = [
            {
                title: "#",
                dataIndex: "key",
                key: "key",
            },
            {
                title: "PLAN NAME",
                dataIndex: "planName",
                key: "planName",
            },
            {
                title: "PLAN PRICE",
                key: "planPrice",
                dataIndex: "planPrice",
            },
            {
                title: "NETWORK",
                dataIndex: "network",
                key: "network",
            },
            {
                title: "STATUS",
                key: "status",
                dataIndex: "status",
            },
        ];
    }



    const dataSource = planResult && planResult.length > 0 && planResult.map((plan, key) => {
        return {
            key: key + 1,
            planName: plan.PlanName,
            planPrice: plan.IsRange == 1 ? `$${plan.MinTopUpAmount.toFixed(2)}` + ' - ' + `$${plan.MaxTopUpAmount.toFixed(2)}` : `$${plan.PlanPrice.toFixed(2)}`,
            network: plan.NetworkName,
            status: <Tag color={plan.IsActive === 1 ? "#1d89ca" : "#f50"} className="draft">{plan.IsActive === 1 ? 'Active' : 'Inactive'}</Tag>,
            action: <Space style={{ width: '100%' }}>
                {plan.IsActive === 1 ? (
                    <Tooltip title="Inactive">
                        <Popconfirm
                            title="Are you sure to inactive this plan?"
                            okText="Yes"
                            placement="left"
                            cancelText="No"
                            onConfirm={() => confirmAction(plan.ID, 0)}
                        >
                            <Button
                                type="danger"
                                ghost={true}
                                icon={<DeleteOutlined />}
                                size="large"
                            />
                        </Popconfirm>
                    </Tooltip>
                ) : (
                    <Tooltip title="Active">
                        <Popconfirm
                            title="Are you sure to active this plan?"
                            okText="Yes"
                            placement="left"
                            cancelText="No"
                            onConfirm={() => confirmAction(plan.ID, 1)}
                        >
                            <Button
                                className="prop-btn-toggle"
                                icon={<CheckOutlined />}
                                size="large"
                            />
                        </Popconfirm>
                    </Tooltip>
                )}

            </Space>
        }
    });

    const success = (text) => {
        message.success(text);
        dispatch(resetPlanMessage()); // Reset messages after success message
    };

    const error = (text) => {
        message.error(text);
        dispatch(resetPlanMessage()); // Reset messages after error message
    };

    const confirmAction = (id, status) =>
        new Promise((resolve) => {
            setTimeout(() => {
                handleDelete(id, status);
                resolve(null);
            }, 2000);
        });

    const handleSearch = () => {
        dispatch(getPlan(searchData));
    }

    const handleSelectNetwork = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            networkID: e
        }));
    }
    const handleSelectCountry = (e) => {
        const countryId=e ? e : 0
        setSearchData(prevState => ({
            ...prevState,
            countryID: countryId
        }));
        dispatch(getNetworksByCountryID(countryId))
    }

    return (
        <>
            {planMessage !== null && planMessage !== undefined && success(planMessage)}
            {planError !== null && planError !== undefined && error(planError)}
            <Card style={{ marginBottom: '8px' }}>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        <Col span={4}>
                            <Form.Item className="username" label="Country" name="countryID">
                                <Select placeholder="Select Country" onChange={handleSelectCountry} name="countryID" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.countryID}>
                                    <Option value="" key="0">ALL</Option>
                                    {countryResult && countryResult.length > 0 && countryResult.map(country => (
                                        <Option value={country.ID} key={country.ID}><ReactCountryFlag countryCode={country.ISO2} style={{ width: "20px", height: "20px" }} svg /> {country.CountryName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item className="username" label="Network" name="networkID">
                                <Select placeholder="Select Network" onChange={handleSelectNetwork} name="networkID" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.networkID}>
                                    <Option value="" key="0">ALL</Option>
                                    {getNetworksByCountryIdList && getNetworksByCountryIdList.length > 0 && getNetworksByCountryIdList.map(network => (
                                        <Option value={network.ID} key={network.ID}>{network.NetworkName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={2} style={{ paddingTop: '4px' }}>
                            <Button type="primary" htmlType='submit' loading={loading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Plan"
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    pagination={true}
                                    className="ant-border-space"
                                    loading={loading}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default withTokenValidation(Plan);