import React from 'react';
import { useState, useEffect } from 'react'
import { Card, Form, Col, Row, Button, Table, DatePicker, Tag, Select } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { DownloadOutlined } from '@ant-design/icons';
import moment from "moment";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import auditReportActions from '../../redux/auditReport/actions'
import shortCodeDataActions from '../../redux/shortCodeData/actions';
import dealerActions from '../../redux/dealer/actions';
import authActions from '../../redux/auth/actions';


const columns = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'PAY TYPE',
        dataIndex: 'paymentType',
        key: 'paymentType',
    },
    {
        title: "AMOUNT",
        key: "amount",
        dataIndex: "amount",
    },
    {
        title: 'PREV BAL',
        dataIndex: 'previousBalance',
        key: 'previousBalance',
    },
    {
        title: 'CURR BAL',
        dataIndex: 'currentBalance',
        key: 'currentBalance',
    },
    {
        title: 'DEALER NAME',
        dataIndex: 'dealerName',
        key: 'dealerName',
    },
    {
        title: 'TO/FROM DEALER',
        dataIndex: 'fromDealer',
        key: 'fromDealer',
    },
    {
        title: 'ACCOUNTING',
        dataIndex: 'accounting',
        key: 'accounting',
    },
    {
        title: "REMARK",
        key: "remark",
        dataIndex: "remark",
        render: (text) => <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>,
    },
];

const AuditReport = () => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const { getShortCode } = shortCodeDataActions;
    const { getAuditReport } = auditReportActions;
    const { getDealer } = dealerActions;
    const { getProfileData } = authActions;
    const { profileDataResult: tokenData } = useSelector((state) => state.auth);
    const shortCodeResult = useSelector((state) => state.shortCodeData.shortCodeResult);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const auditResult = useSelector((state) => state.auditReport.auditResult);
    const loading = useSelector((state) => state.auditReport.loading);
    const buttonLoading = useSelector((state) => state.auditReport.buttonLoading);
    const defaultFromDate = useState(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    const defaultToDate = moment().format('YYYY-MM-DD');
    const oneWeekFromToday = moment().startOf('day').subtract(2, 'days');
    const today = moment().startOf('day');
    const [fromDateShow, setfromDateShow] = useState(oneWeekFromToday);
    const [toDateShow, settoDateShow] = useState(today);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [searchData, setSearchData] = useState({
        transactionType: '',
        fromDate: defaultFromDate[0],
        toDate: defaultToDate,
        dealer: ""
    })
    const [statusData, setstatusData] = useState({
        tableName: "t_account_audit_trail",
        fieldName: "EntryType"
    })

    const handleToDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            toDate: formattedDate,
        }));
    }

    const handleFromDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            fromDate: formattedDate,
        }));
    }

    const handleSearch = () => {
        dispatch(getAuditReport(searchData))
    }
    const handleSelectStatus = (e) => {
        // const findStatus = e === 18 ? '19' : e;
        setSearchData(prevState => ({
            ...prevState,
            transactionType: e == '' ? '' : e
        }));
    }
    const handleSelectDealer = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            dealer: e
        }));
    }

    useEffect(() => {
        dispatch(getShortCode(statusData))
        dispatch(getAuditReport(searchData));
        dispatch(getDealer());
        dispatch(getProfileData());
    }, []);


    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'Audit_Reports' + fileExtension);
        }
    }
    const dataSource = auditResult && auditResult.length > 0 && auditResult.map((report, key) => {
        return {
            key: key + 1,
            date: moment(new Date(report.CreatedDtTm)).format("MM/DD/YYYY h:mm:ss"),
            paymentType: report.PaymentType,
            amount: <span className={report.Amount < 0 ? `text-danger` : `text-success`}>{`$${report.Amount >= 0 ? report.Amount.toFixed(2) : report.Amount.toFixed(2)}`}</span>,
            previousBalance: <span className={report.PreviousBalance < 0 ? `text-danger` : `text-success`}>{`$${report.PreviousBalance >= 0 ? report.PreviousBalance.toFixed(2) : report.PreviousBalance.toFixed(2)}`}</span>,
            currentBalance: <span className={report.CurrentBalance < 0 ? `text-danger` : `text-success`}>{`$${report.CurrentBalance >= 0 ? report.CurrentBalance.toFixed(2) : report.CurrentBalance.toFixed(2)}`}</span>,
            dealerName: report.DealerName,
            fromDealer: report.FromDealer,
            remark: report.Remarks,
            // remark: report.Remarks.replace("-", "-<br>"),
            accounting: <Tag color={report.TransactionType == 'Credit' ? "green" : report.TransactionType == 'Debit' ? "yellow" : "red"} title={report.TransactionType}>{report.TransactionType}</Tag>
        }
    });

    const listData = auditResult && auditResult.length > 0 && auditResult.map((report, key) => {
        return {
            'S No.': key + 1,
            Date: moment(new Date(report.CreatedDtTm)).format("MM/DD/YYYY h:mm:ss"),
            'Payment Type': report.PaymentType,
            Amount: `$${report.Amount.toFixed(2)}`,
            'Previous Balance': `$${report.PreviousBalance.toFixed(2)}`,
            'Current Balance': `$${report.CurrentBalance.toFixed(2)}`, 
            'Dealer Name': report.DealerName,
            "To/From Dealer": report.FromDealer,
            Remark: report.Remarks,
            Accounting: report.TransactionType,
        }
    });
    return (
        <>
            <Card>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        {tokenData && tokenData.length > 0 && tokenData[0].IsCompany === 1 ? <Col span={5}>
                            <Form.Item className="username" label="Dealer" name="dealer" >
                                <Select onChange={handleSelectDealer} name="dealer" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.dealer}>
                                    <Select.Option value="" key="0">ALL</Select.Option>
                                    {dealerResult && dealerResult.length > 0 && dealerResult.map(dealer => (
                                        <Select.Option value={dealer.ID} key={dealer.ID} >{dealer.DealerName}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> : ""}
                        <Col span={5}>
                            <Form.Item className="username" label="Payment Type" name="transactionType" >
                                <Select onChange={handleSelectStatus} name="transactionType" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.transactionType}>
                                    <Select.Option value="" key="0">ALL</Select.Option>
                                    {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map(shortcode => (
                                        <Select.Option value={shortcode.ID} key={shortcode.ID} >{shortcode.Value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item className="username" colon={false} name="fromDate" label="From Date" size="large">
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} defaultValue={fromDateShow} className="ant-datepicker" allowClear={false} inputReadOnly={true} onChange={handleFromDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item className="username" colon={false} name="toDate" label="To Date" size="large"  >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={toDateShow} onChange={handleToDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={2} style={{paddingTop:'4px'}}>
                            <Button type="primary" htmlType='submit' loading={buttonLoading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        className="criclebox tablespace mb-24"
                        title="Audit Report"
                        extra={
                            <>
                                <Button onClick={() => exportToCSV()} style={{ float: 'right', backgroundColor: '#35267a', color: 'white', border: 'none' }}><DownloadOutlined style={{ fontSize: '20px', paddingTop: "5px" }} /> DOWNLOAD REPORT</Button>
                            </>
                        }
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AuditReport
