import actions from "./actions";

const initState = {
    validateResult: [],
    loading: false,
    preloadedError: null,
    duplicatedSimDataInv: [],
    preloadedResult: [],
    showButtonUpload: false,
    preloadedDetails: [],
    preloadedInvResult: null,
    showAddPage: false,
    showDetailPage: false,
    preloadedMessage: null,
    showValidateSuccess: false,
    noteError: false,
    buttonLoading: false
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.VALIDATE_PRELOADED_DATA:
            return {
                ...state,
                loading: true,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.VALIDATE_PRELOADED_DATA_SUCCESS:
            return {
                ...state,
                preloadedMessage: action.preloadedMessage,
                loading: false,
                showButtonUpload: true,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: true,
                noteError: false
            };
        case actions.VALIDATE_PRELOADED_DATA_FAILED:
            return {
                ...state,
                preloadedError: action.preloadedError,
                loading: false,
                duplicatedSimDataInv: action.simData && action.simData.split(','),
                showButtonUpload: false,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: true
            };
        case actions.ADD_PRELOADED:
            return {
                ...state,
                loading: false,
                preloadedError: null,
                showButtonUpload:true,
                preloadedMessage: null,
                buttonLoading: true,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false,
            };
        case actions.ADD_PRELOADED_SUCCESS:
            return {
                ...state,
                loading: false,
                preloadedResult: action.preloadedResult,
                preloadedMessage: action.preloadedMessage,
                showButtonUpload:false,
                preloadedError: null,
                buttonLoading: false,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false,
            };
        case actions.ADD_PRELOADED_FAILED:
            return {
                ...state,
                loading: false,
                preloadedMessage: null,
                preloadedError: action.preloadedError,
                showButtonUpload:false,
                buttonLoading: false,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false,
            };
        case actions.GET_PRELOADED:
            return {
                ...state,
                loading: true,
                preloadedError: null,
                preloadedMessage: null,
                buttonLoading: true,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_PRELOADED_SUCCESS:
            return {
                ...state,
                loading: false,
                preloadedError: null,
                preloadedResult: action.preloadedResult,
                preloadedMessage: null,
                buttonLoading: false,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_PRELOADED_FAILED:
            return {
                ...state,
                loading: false,
                preloadedError: action.preloadedError,
                preloadedMessage: null,
                buttonLoading: false,
                showAddPage: false,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_PRELOADED_DETAILS:
            return {
                ...state,
                loading: true,
                preloadedMessage: null,
                // purchaseId:action.payload.purchaseId,
                preloadedError: null,
                showAddPage: false,
                showDetailPage: true,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.GET_PRELOADED_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                preloadedDetails: action.preloadedDetails,
                preloadedInvResult: action.preloadedInvResult,
                showDetailPage: true,
                showAddPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.RESET_PRELOADED_ACTIVATION:
            return {
                ...state,
                validateResult: [],
                loading: false,
                preloadedError: null,
                duplicatedSimDataInv: [],
                showButtonUpload: false,
                preloadedInvResult: null,
                showAddPage: false,
                showDetailPage: false,
                preloadedMessage: null,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.RESET_PRELOADED_MESSAGE:
            return {
                ...state,
                preloadedError: null,
                loading: false,
                preloadedMessage: null,
                noteError: false
            };
        case actions.PREPARE_ADD_PRELOADED_FORM:
            return {
                ...state,
                loading: false,
                preloadedError: null,
                preloadedMessage: null,
                // showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.PREPARE_ADD_PRELOADED_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                preloadedInvResult: action.preloadedInvResult,
                preloadedError: null,
                preloadedMessage: null,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        case actions.PREPARE_ADD_PRELOADED_FORM_FAILED:
            return {
                ...state,
                loading: false,
                preloadedError: action.preloadedError,
                preloadedMessage: null,
                showAddPage: true,
                showDetailPage: false,
                showValidateSuccess: false,
                noteError: false
            };
        default:
            return state;
    }
}
