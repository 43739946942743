import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Form, Input, Button, Card, Modal, Tag, Space, message, Select, Popconfirm, Tooltip } from 'antd';
import dealerActions from '../../redux/dealer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, EditOutlined, DeleteOutlined, CheckOutlined, MailOutlined, MobileOutlined, LockOutlined, AlignLeftOutlined } from "@ant-design/icons";
import countryAction from '../../redux/country/actions'
import ReactCountryFlag from "react-country-flag"
import { PASSWORD_VALIDATE } from '../../helpers/constant'
import { formatString } from '../../helpers/commonFunction';

const { getCountry } = countryAction;


const columns = [
  {
    title: '#',
    dataIndex: "key",
    key: "key",
    width: "5%",
  },
  {
    title: 'DEALER NAME',
    dataIndex: 'DealerName',
    key: 'DealerName',
  },
  {
    title: 'EMAIL',
    dataIndex: 'EmailID',
    key: 'EmailID',
  },
  {
    title: 'CONTACT NUMBER',
    dataIndex: 'ContactNumber',
    key: 'ContactNumber',
  },
  {
    title: 'USERNAME',
    dataIndex: 'Username',
    key: 'Username',
  },
  {
    title: 'ACCOUNT BALANCE',
    dataIndex: 'accountBalance',
    key: 'accountBalance',
  },
  {
    title: 'STATUS',
    dataIndex: 'Status',
    key: 'Status',
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    key: 'action',
  }
]

function Dealer() {
  const { getDealer, resetDealer, addDealer, editDealer, deleteDealer, prepareDealerForm, resetDealerMessage, preparePasswordForm, dealerPasswordReset } = dealerActions;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dealer.loading);
  const dealerResult = useSelector((state) => state.dealer.dealerResult);
  const successMessage = useSelector((state) => state.dealer.dealerMessage);
  const errorMessage = useSelector((state) => state.dealer.dealerError);
  const buttonLoading = useSelector((state) => state.dealer.buttonLoading);
  const dealerFormModal = useSelector((state) => state.dealer.dealerFormModal);
  const dealerPasswordModal = useSelector((state) => state.dealer.dealerPasswordModal);
  const countryResult = useSelector((state) => state.country.countryResult);
  const [formTitle, setFormTitle] = useState('ADD DEALER');
  const [formButton, setFormButton] = useState('SUBMIT');
  const [editFlag, setEditFlag] = useState(false);
  const [editId, setEditId] = useState();
  const [formData, setFromData] = useState({
    dealerName: "",
    contactPerson: "",
    contactNumber: "",
    contactEmailID: "",
    username: "",
    password: "",
    CountryID: "",
  });

  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    UserId: null
  })

  useEffect(() => {
    dispatch(getDealer());
    dispatch(getCountry())
  }, [])

  const handleNumber = (e) => {
    const keyCode = e.keyCode;
    if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 8) {
      e.preventDefault();
    }
  }
  const handleAddForm = () => {
    setFormTitle("ADD DEALER");
    setFormButton("SUBMIT");
    setFromData(prevState => ({
      ...prevState,
      dealerName: "",
      contactPerson: "",
      contactNumber: "",
      contactEmailID: "",
      username: "",
      password: "",
      confirmPassword: "",
      CountryID: "",
    }));
    dispatch(prepareDealerForm());
    setEditFlag(false);
  }

  const handleChange = (e) => {
    dispatch(prepareDealerForm());
    const fname = e.target.name;
    const fvalue = e.target.value;
    setFromData(prevState => ({
      ...prevState,
      [fname]: fvalue
    }));
  }

  const handleCancel = () => {
    dispatch(resetDealer());
  }

  const handleDelete = (ID, flag) => {
    dispatch(deleteDealer(ID, flag));
  }

  const handleSubmit = (item) => {
    formData.CountryID = item.CountryID
    dispatch(addDealer(formData));

  }

  const handleKeyDown = (e) => {
    dispatch(prepareDealerForm());
    if (e.key === " ") {
      e.preventDefault();
    }
  };


  const handleEditForm = (userId) => {
    var selectedUser = dealerResult.filter(user => user.ID === userId);
    setFormTitle("EDIT DEALER");
    setFormButton("UPDATE");
    setFromData(prevState => ({
      ...prevState,
      dealerName: selectedUser[0].DealerName,
      contactEmailID: selectedUser[0].ContactEmailID,
      contactPerson: selectedUser[0].ContactPerson,
      contactNumber: selectedUser[0].ContactNumber,
      website: selectedUser[0].Website,
      CountryID: selectedUser[0].CountryID,
    }));
    dispatch(prepareDealerForm());
    setEditFlag(true);
    setEditId(userId);
  }

  const handleUpdate = (item) => {
    formData.CountryID = item.CountryID
    dispatch(editDealer(editId, formData));
  }

  const handleOpenPasswordFormModal = (UserId) => {
    dispatch(preparePasswordForm());
    setPasswordData(prevState => ({
      ...prevState,
      newPassword: "",
      userId: UserId
    }));
  }
 
  const handlePassword = (e) => {
    dispatch(preparePasswordForm());
    const fname = e.target.name;
    const fvalue = e.target.value;
    setPasswordData(prevState => ({
      ...prevState,
      [fname]: fvalue
    }));
  }
  const handleSubmitResetPassword = () => {
    dispatch(dealerPasswordReset(passwordData))
  }
  const dataSource = dealerResult && dealerResult.length > 0 && dealerResult.map((dealer, key) => {
    return {
      key: key + 1,
      DealerName: dealer.DealerName,
      Username: dealer.Username,
      EmailID: dealer.ContactEmailID,
      accountBalance: <span className={dealer.AccountBalance < 0 ? `text-danger` : `text-success`}>
        {'$' + dealer.AccountBalance.toFixed(2)}
      </span>,
      ContactNumber: dealer.ContactNumber,
      Status: <Tag color={dealer.IsActive === 1 ? "#1d89ca" : "#f50"} className="draft">{dealer.IsActive === 1 ? 'Active' : 'Inactive'}</Tag>,
      action: dealer.ParentID !== 0 ? <Space style={{ width: '100%' }}>
        <Tooltip title="Edit">
          <Button type="default" icon={<EditOutlined />} size="large" onClick={() => handleEditForm(dealer.ID)} />
        </Tooltip>

        {dealer.IsActive === 1 ? (
          <Tooltip title="Inactive">
            <Popconfirm
              title="Are you sure to inactive this dealer?"
              okText="Yes"
              placement="left"
              cancelText="No"
              onConfirm={() => confirmAction(dealer.ID, 0)}
            >
              <Button
                type="danger"
                ghost={true}
                icon={<DeleteOutlined />}
                size="large"
              />
            </Popconfirm>
          </Tooltip>
        ) : (
          <Tooltip title="Active">
            <Popconfirm
              title="Are you sure to active this dealer?"
              okText="Yes"
              placement="left"
              cancelText="No"
              onConfirm={() => confirmAction(dealer.ID, 1)}
            >
              <Button
                className="prop-btn-toggle"
                icon={<CheckOutlined />}
                size="large"
              />
            </Popconfirm>
          </Tooltip>
        )}
        <Tooltip title="Reset Password">
          <Button type="primary" ghost={true} icon={<LockOutlined />} size="large" onClick={() => handleOpenPasswordFormModal(dealer.UserId)}></Button>
        </Tooltip>
      </Space> : ''
    }
  });

  const success = (text) => {
    message.success(text);
    dispatch(resetDealerMessage());
  };

  const error = (text) => {
    message.error(text);
    dispatch(resetDealerMessage());
  };

  const confirmAction = (id, status) =>
    new Promise((resolve) => {
      setTimeout(() => {
        handleDelete(id, status);
        resolve(null);
      }, 2000);
    });

  return (
    <>
      {successMessage !== null && successMessage !== undefined && success(successMessage)}
      {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Dealer"
              extra={
                <>
                  <Button type="ghost" style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }} onClick={handleAddForm}><PlusOutlined />ADD DEALER</Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination
                  loading={loading}
                  className="ant-border-space"
                />
              </div>
            </Card>

            <Modal title={formTitle} style={{ top: 20 }} width={800} closable={true} destroyOnClose={true} maskClosable={false} visible={dealerFormModal}
              onOk={handleCancel}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  CANCEL
                </Button>,
                <Button type="ghost" key="submit" form="dealerForm" htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }}
                  loading={buttonLoading}
                > {formButton} </Button>
              ]}
            >

              <Form id="dealerForm" layout="vertical" onFinish={editFlag ? handleUpdate : handleSubmit} initialValues={formData} >
                <Row gutter={[16, 0]}>
                  <Col span={12} >
                    <Form.Item
                      className="username"
                      name="dealerName"
                      label="Dealer Name"
                      rules={[{ required: true, message: formatString('Please input dealer name !') }]}
                    >
                      <Input placeholder="Dealer Name" name="dealerName" onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      className="username"
                      name="contactPerson"
                      label="Contact Person"
                      rules={[{ required: true, message: formatString('Please input contact person !') }]}
                    >
                      <Input addonBefore={<MobileOutlined />} placeholder="Contact Person" name="contactPerson" onChange={handleChange} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      className="username"
                      name="contactNumber"
                      label="Contact Number"

                      rules={[
                        { required: true, message: formatString('Please input Contact number !') },
                        { min: 10, max: 12, message: formatString('Contact number must be between 10 to 12 digit !') },
                      ]}
                    >
                      <Input placeholder="Contact Number" onKeyDown={handleNumber} name="contactNumber" onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  {!editFlag &&
                    <Col span={12}>
                      <Form.Item className="username" label="Username" name="username"
                        rules={[
                          {
                            required: true,
                            message: formatString("Please input your username!"),
                          }, {
                            whitespace: true,
                            message: formatString('White or empty space is not allowed !.')
                          }, {
                            // pattern: new RegExp("^[a-zA-Z0-9_]*$"),
                            message: formatString('Please use only letters (a-z) (A-Z),numbers, _underscore')
                          }
                        ]}>
                        <Input placeholder="Username" name="username" onChange={handleChange} onKeyDown={handleKeyDown} disabled={editFlag} />
                      </Form.Item>
                    </Col>}
                  {!editFlag && <><Col span={12} ><Form.Item className="username" label="Password" name="password"
                    rules={[
                      {
                        required: true,
                        message: formatString("Please input your password!"),
                      },
                      {
                        min: 8,
                        pattern: PASSWORD_VALIDATE,
                        message: formatString('Invalid password format(Example@123) !')
                      }
                    ]}
                    hasFeedback>
                    <Input type='password' addonBefore={<LockOutlined />} placeholder="Password" name="password" onChange={handleChange} />
                  </Form.Item>

                  </Col>
                    <Col span={12} >
                      <Form.Item className="username" label="Confirm Password" name="confirmPassword" dependencies={['password']} hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please input confirm password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error(formatString('Confirm password does not match!')));
                            },
                          }),
                        ]}>
                        <Input type='password' addonBefore={<LockOutlined />} placeholder="Confirm Password" name="confirmPassword" onChange={handleChange} />
                      </Form.Item></Col></>}
                  <Col span={!editFlag ? 12 : 12}>
                    <Form.Item
                      className="username"
                      name="contactEmailID"
                      label="Email ID"
                      rules={[{ required: true, message: formatString('Please input email id !') },
                      { type: 'email', message: formatString('Please input valid email id !') }]}
                    >
                      <Input addonBefore={<MailOutlined />} placeholder="Email ID" name="contactEmailID" onChange={handleChange} />
                    </Form.Item>
                  </Col>

                  <Col span={12} >
                    <Form.Item label='Country' className="CountryID" name="CountryID" rules={[{ required: true, message: formatString("Please Select Country!"), }]}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                      >
                        <Select.Option value={''} key={''}>Select</Select.Option>
                        {countryResult && countryResult.length > 0 && countryResult.map(country => (
                          <Select.Option value={country.ID} key={country.ID} ><ReactCountryFlag countryCode={country.ISO2} style={{ width: "20px", height: "20px" }} svg /> {country.CountryName}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                </Row>
              </Form>
            </Modal>
            <Modal title="RESET PASSWORD" onOk={handleCancel} visible={dealerPasswordModal} onCancel={handleCancel} closable={true} destroyOnClose={true} maskClosable={false} centered footer={[
              <Button key="back" onClick={handleCancel}>
                CANCEL
              </Button>,
              <Button form="passwordForm" key="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} htmlType="submit" type="primary" loading={buttonLoading}>
                UPDATE
              </Button>,
            ]}>
              <Form id="passwordForm" layout="vertical" className="row-col" onFinish={handleSubmitResetPassword} >
                <Form.Item className="username" label="New Password" name="newPassword" rules={[{ required: true, message: "Please input new password!", }, {
                  min: 8,
                  pattern: PASSWORD_VALIDATE,
                  message: 'Invalid password format(Example@123) !'
                }]}>
                  <Input.Password addonBefore={<LockOutlined />} placeholder="New Password" name="newPassword" hasFeedback onChange={handlePassword} />
                </Form.Item>
                <Form.Item className="username" label="Confirm Password" name="confirmPassword" dependencies={['newPassword']} hasFeedback rules={[
                  { required: true, message: "Please input confirm password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Confirm password does not match!'));
                    },
                  })]}>
                  <Input.Password addonBefore={<LockOutlined />} placeholder="Confirm Password" name="confirmPassword" onChange={handlePassword} />
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Dealer