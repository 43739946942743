import React from "react";
import authActions from '../redux/auth/actions';
import { useSelector, useDispatch } from 'react-redux';
import {  Redirect } from 'react-router-dom';
import {
  Card,
  Layout,
  Button,
  Typography,
  Form,
  Input,
  message
} from "antd";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
// import LogoPng from '../assets/images/moh-connect-v1.jpg';
import LogoPng from '../assets/images/moh-logo-blue.png';
// import LogoPng from '../assets/images/moh-connect-v3.png';
// import LogoPng from '../assets/images/moh-connect-v4.jpg';


const { Title } = Typography;

function SignIn(props) {
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
  const dispatch = useDispatch();
  const { loginRequest, resetAuthError } = authActions;
  const errorMessage = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);

  const error = (text) => {
    const hide = message.error(
      //   <MessageContent>{text}</MessageContent>,
      <p style={{
        'display': 'inline-block',
        'fontSize': '15px'
      }}>{text}</p>,
      0
    );
    setTimeout(hide, 2000);
    dispatch(resetAuthError());
  };

  React.useEffect(() => {
    if (token) {
      setRedirectToReferrer(true);
    } else {
      setRedirectToReferrer(false);
    }
  }, [token]);

  if (redirectToReferrer) {
    return <Redirect to='/dashboard' />;
  }

  const onFinish = (values) => {
    dispatch(loginRequest(values, props.history));
  };

  const onFinishFailed = (errorInfo) => {
  };

  return (

    <Layout className="layout-default layout-signin">
      {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
      <div className="signin-page">
        <Card className="sign-in-card">
          <div style={{ textAlign: 'center' }}>
            <img src={LogoPng} style={{ width: '200px' }}  alt="img" />
            <Title className="font-regular text-muted mb-1" level={5}>
              Enter your credentials to continue
            </Title>
          </div>
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" name="username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                name="password"

              />
            </Form.Item>
            <Form.Item>
              <Button
                type="ghost"
                htmlType="submit"
                style={{ width: "100%", background: "#213987", border: 'none', color: 'white' }}
                loading={loading}
              >
                SIGN IN
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>

    </Layout>
  )
}

export default SignIn
