import React, { useEffect, useState } from 'react'
import { Table, Row, Col, Form, Input, Button, Card, Modal, Tag, Select, Space, message, Image as MyImage,Tooltip } from 'antd';
import { PlusOutlined, MinusCircleFilled, PlusCircleFilled, EditOutlined, DownOutlined, DeleteOutlined, CheckOutlined, MailOutlined, MobileOutlined, LockOutlined, AlignLeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import aggregatorAction from '../../../redux/aggregator/actions';
import { convertLegacyProps } from 'antd/lib/button/button';
import networkAction from '../../../redux/network/actions'

const { getActiveNetwork } = networkAction;
const { getAggregator, addAggregator, updateAggregator, openAddAggregatorModal, resetAggregator } = aggregatorAction;


const Aggregator = () => {
  const dispatch = useDispatch();
  const { list, openAggregatorModel, previewModel, loading, aggregatorMessage, errorMessage ,tableLoading} = useSelector((state) => state.aggregator);
  const { networkResult } = useSelector((state) => state.network);
  const [formButton, setFormButton] = useState('');
  const [formTitle, setFormTitle] = useState('');
  const [editFlag, setEditFlag] = useState(false);
  const [networkData, setNetworkData] = useState([]);
  const [aggregatorId, setAggregatorId] = useState(0);




  const [aggregatorFrom, setAggregatorFrom] = useState({
    aggregatorName: "",
    metaData: [
      {
        key: "",
        value: "",
      }
    ],
    network: [
      {
        label: '',
        value: ''
      }
    ]
  })



  useEffect(() => {
    dispatch(getAggregator());
    dispatch(getActiveNetwork())
  }, [])




  const options = networkResult && networkResult.length > 0 && networkResult.map(network => ({
    label: network.NetworkName,
    value: network.ID,
  }));


  const columns = [
    {
      title: '#',
      dataIndex: "key",
      key: "key",
      width: "5%",
    },
    {
      title: 'AGGREGATOR NAME',
      dataIndex: 'aggregator',
      key: 'aggregator',
    },

    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
    },

    {
      title: 'ACTION',
      dataIndex: 'action',
      key: 'action',
    }
  ]
  var dataSource = list && list.length > 0 && list.map((item, key) => {
    return {
      key: key + 1,
      aggregator: item.AggregatorName,
      status: <Tag color={item.IsActive === 1 ? "#1d89ca" : "#f50"} className="draft">{item.IsActive === 1 ? 'Active' : 'Inactive'}</Tag>,
      action: <Space style={{ width: '100%' }}>
        <Tooltip title ="Edit">
        <Button type="default" icon={<EditOutlined />} size="large" onClick={() => handleEditForm(item.ID)} />
        </Tooltip>
        {/* {item.IsActive === 1 ? <Button type="danger" ghost={true} icon={<DeleteOutlined />} size="large" onClick={() => { handleDelete(item.ID, 0); }} /> : <Button type="primary" icon={<CheckOutlined />} size="large" onClick={() => { handleDelete(item.ID, 1); }} />} */}
      </Space>
    }
  });

  const handleSubmit = (data) => {
    let newData = {
      ...data,
      network: networkData.key
    }
    dispatch(addAggregator(newData))
  }

  const handleUpdate = (data) => {
    data.aggregatorId = aggregatorId;
    delete data.aggregatorName;
    const editedNetworkData = networkData.key
    if (editedNetworkData) {
      let newData = {
        ...data,
        network: editedNetworkData
      }
      dispatch(updateAggregator(newData))
    }
    else {
      dispatch(updateAggregator(data))
    }



  }

  const handleChange = (value, key) => {
    setNetworkData(pre => ({
      ...pre,
      key
    }))
  }

  const handleAddAggregator = () => {
    setFormButton("SUBMIT")
    setFormTitle("ADD AGGREGATOR")
    setAggregatorFrom({
      aggregatorName: "",
      customerSupport: "",
      metaData: [
        {
          key: "",
          value: "",
        }
      ],
      network: [

      ]
    })
    setEditFlag(false)
    dispatch(openAddAggregatorModal())
  }


  const handleEditForm = (id) => {
    setFormButton("UPDATE")
    setFormTitle("EDIT AGGREGATOR")
    setAggregatorId(id)
    const selectedAggregator = list.filter(item => item.ID === id);

    setAggregatorFrom(pre => ({
      ...pre,
      aggregatorName: selectedAggregator[0].AggregatorName,
      network: JSON.parse(selectedAggregator[0].networks),
      metaData: JSON.parse(selectedAggregator[0].metaData),
    }))
    setEditFlag(true)
    dispatch(openAddAggregatorModal())
  }
  const handleCancel = () => {
    dispatch(resetAggregator())
  }

  const success = (text) => {
    message.success(text);
    dispatch(resetAggregator()); // Reset messages after success message
  };

  const error = (text) => {
    message.error(text);
    dispatch(resetAggregator()); // Reset messages after error message
  };
  return (
    <>
      {aggregatorMessage !== null && aggregatorMessage !== undefined && success(aggregatorMessage)}
      {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Aggregator"
              extra={
                <>
                  <Button type="ghost" style={{ float: 'right', backgroundColor: '#223b8b', color: 'white', border: 'none' }} onClick={handleAddAggregator}><PlusOutlined />ADD AGGREGATOR</Button>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination
                  loading={tableLoading}
                  className="ant-border-space"
                />
              </div>
            </Card>

          </Col>

          <Col>
            <Modal title={formTitle} style={{ top: 20 }} width={500} closable={true} destroyOnClose={true} maskClosable={false} visible={openAggregatorModel}
              onOk={handleCancel}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  CANCEL
                </Button>,
                <Button type="ghost" key="submit" form="aggregatorForm" htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }}
                  loading={loading}
                > {formButton} </Button>
              ]}
            >

              <Form id="aggregatorForm" layout="vertical" onFinish={editFlag ? handleUpdate : handleSubmit} initialValues={aggregatorFrom} >
                <Row gutter={[16, 0]}>
                  <Col span={24}>
                    <Form.Item
                      className="aggregatorName"
                      name="aggregatorName"
                      label="Aggregator Name"
                      span={24}
                      rules={[{ required: true, message: 'Please Input Aggregator Name !' }]}
                    >
                      <Input disabled={editFlag ? true : false} placeholder="Aggregator Name" name="aggregatorName" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      className="network aggregator-select-network"  name="network" label="Network" span={24} rules={[{ required: true, message: 'Please Select Network !' }]}>
                      <Select
                        mode="multiple" span={24}
                        placeholder="Please select"
                        defaultValue={aggregatorFrom.network}
                        onChange={handleChange}
                        options={options}
                        value={'network'}

                      />
                    </Form.Item>

                  </Col>

                  <Col span={24} >
                    <h4>Meta Data</h4>
                  </Col>
                  <Col span={24}>
                    <Form.List
                      name="metaData"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) =>
                            <Row key={index} gutter={[16, 0]} style={{ alignItems: 'end' }}>
                              <Col span={editFlag ? 12 : 11}>
                                {index === 0 ? <span>Key</span> : ''}
                                <Form.Item
                                  name={[field.name, 'key']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input key!',
                                    },
                                  ]}
                                >
                                  <Input disabled={editFlag ? true : false} name={`key${index}`} placeholder='Key' style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>
                              <Col span={editFlag ? 12 : 11}>
                                {index === 0 ? <span>Value</span> : ''}
                                <Form.Item
                                  name={[field.name, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please input value!',
                                    },
                                  ]}
                                >
                                  <Input name={`value${index}`} placeholder='Value' style={{ width: '100%' }} />
                                </Form.Item>
                              </Col>

                              {!editFlag ? fields.length !== index + 1 ?
                                <Col span={2} >
                                  <Form.Item>
                                    <MinusCircleFilled style={{ fontSize: '22px', color: '#dd0404' }} span={24} onClick={() => remove(field.name)} />
                                  </Form.Item>
                                </Col>
                                :
                                <Col span={2} className='addAggregator' style={{ marginTop: '0px' }}>
                                  <Form.Item>
                                    <PlusCircleFilled style={{ fontSize: '22px', color: '#08c' }} className='addAggregatorChild' onClick={() => add()} />
                                  </Form.Item>
                                </Col>
                                : ''}

                            </Row>
                          )}
                        </>

                      )}

                    </Form.List>
                  </Col>

                </Row>


              </Form>

            </Modal>
          </Col>
        </Row>
      </div>

    </>
  )
}

export default Aggregator
