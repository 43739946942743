import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getCountryListResponse() {
    try {
        const response = yield call(getList, '/country');
        if (response.response.Status) {
            yield put(actions.getCountrySuccess(response.response.Data));
        } else {
            yield put(actions.getCountryFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCountryFailed(error));
    }
}

function* getCountryInternationalListResponse() {
    try {
        const response = yield call(getList, '/countriesForInternationalRecharge');
        if (response.response.Status) {
            yield put(actions.getCountryInternationalSuccess(response.response.Data));
        } else {
            yield put(actions.getCountryInternationalFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCountryInternationalFailed(error));
    }
}
export default function* rootSaga() {
    yield all([takeEvery(actions.GET_COUNTRY, getCountryListResponse)]);
    yield all([takeEvery(actions.GET_COUNTRY_INTERNATIONAL, getCountryInternationalListResponse)]);
}
