import { combineReducers } from "redux";
import auth from './auth/reducer';
import user from './user/reducer';
import dealer from './dealer/reducer';
import customer from './customer/reducer';
import plan from './plan/reducer';
import country from './country/reducer';
import bulkActivation from './bulkActivation/reducer';
import home from './home/reducer';
import blankActivation from './blankActivation/reducer';
import preloadedActivation from './preloadedActivation/reducer';
import bulkReport from './bulkReport/reducer';
import activationReport from './activationReport/reducer';
import shortCodeData from './shortCodeData/reducer';
import rechargeReport from './rechargeReport/reducer';
import bulkRecharge from './bulkRecharge/reducer';
import manualAddFund from "./manualAddFund/reducer";
import lycaDealer from "./lycaDealer/reducer";
import auditReport from './auditReport/reducer';
import network from './network/reducer';
import rechargeRequest from './rechargeRequest/reducer'
import rechargeMapping from './rechargeMapping/reducer'
import aggregator from './aggregator/reducer'
import commissionReport from './commissionReport/reducer'


const rootReducer = combineReducers({
    auth,
    user,
    dealer,
    customer,
    plan,
    country,
    // bulkActivation,
    home,
    blankActivation,
    preloadedActivation,
    bulkReport,
    activationReport,
    shortCodeData,
    rechargeReport,
    manualAddFund,
    // bulkRecharge,
    // lycaDealer,
    auditReport,
    rechargeRequest,
    network,
    rechargeMapping,
    aggregator,
    commissionReport,

});
export default rootReducer;