import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList, deleteData } from '../../helpers/fackBackend_Helper';

function* getDealerListResponse() {
    try {
        const response = yield call(getList, '/dealers');
        if (response.response.Status) {
            yield put(
                actions.getDealerSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}

function* addDealerDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createDealer', data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealers');
            if (responseList.response.Status) {
                yield put(
                    actions.addDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addDealerFailed(error));
    }
}

function* editDealerDataResponse({ payload: { dealerId, data } }) {
    try {
        const response = yield call(addData, '/updateDealer/' + dealerId, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealers');
            if (responseList.response.Status) {
                yield put(
                    actions.editDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addDealerFailed(error));
    }
}

function* deleteDealerDataResponse({ payload: { dealerId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteDealer/' + dealerId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/dealers');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteDealerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.getDealerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.getDealerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getDealerFailed(error));
    }
}
function* resetdealerPasswordResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/changeUserPassword', data);
        if (response.response.Status) {
            yield put(actions.dealerPasswordResetSuccess(response.response.Message));
            yield put(actions.getDealer());
        } else {
            yield put(actions.dealerPasswordResetFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.dealerPasswordResetFailed(error));
    }
}
export default function* rootSaga() {
    yield all([takeEvery(actions.GET_DEALER, getDealerListResponse)]);
    yield all([takeEvery(actions.ADD_DEALER, addDealerDataResponse)]);
    yield all([takeEvery(actions.EDIT_DEALER, editDealerDataResponse)]);
    yield all([takeEvery(actions.DELETE_DEALER, deleteDealerDataResponse)]);
    yield all([takeEvery(actions.DEALER_PASSWORD_RESET, resetdealerPasswordResponse)]);
}
