import { put, call, all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* loginRequestResponse({ payload: { data, history } }) {
    try {
        const response = yield call(addData, '/user_login', data);
        if (response.response.Status) {
            localStorage.setItem("token", JSON.stringify(response.response.Token));
            yield put(
                actions.loginSuccess(
                    response.response.Token
                ));
            history.push('/dashboard');
        } else {
            yield put(
                actions.loginFailed(
                    response.response.Data.Message
                ));
        }
    } catch (error) {
        yield put(actions.loginFailed(error))
    }
}

function* logoutRequestResponse({ payload: { token, history } }) {
    try {
        const response = yield call(addData, '/user_logout', token);
        if (response.response.Status) {
            localStorage.removeItem("token");
            yield put(actions.logoutSuccess(response.response.Message));
            history.push('/');
            // window.location.reload();
        } else {
            yield put(actions.logoutFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.logoutFailed(error));
    }
}

function* getProfileDataResponse() {
    try {
        const response = yield call(getList, '/getUserByToken');
        if (response.response.Status) {
            yield put(
                actions.getProfileDataSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getProfileDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getProfileDataFailed(error));
    }
}

function* changePasswordResponse({ payload: { passwordData } }) {
    try {
        const response = yield call(addData, '/changePassword', passwordData);
        if (response.response.Status) {
            yield put(actions.changePasswordSuccess(
                response.response.Message
            ));
        }
        else {
            yield put(actions.changePasswordFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.changePasswordFailed(error));
    }
}

function* updateJarStatusResponse({ payload: { flag } }) {
    try {
        const response = yield call(getList, '/updateJarFlag/' + flag);
        if (response.response.Status) {
            yield put(
                actions.updateJarStatusSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.updateJarStatusFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.updateJarStatusFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.LOGIN_REQUEST, loginRequestResponse)]);
    yield all([takeEvery(actions.LOGOUT_REQUEST, logoutRequestResponse)]);
    yield all([takeEvery(actions.GET_POFILE_DATA, getProfileDataResponse)]);
    yield all([takeEvery(actions.CHANGE_PASSWORD, changePasswordResponse)]);
    yield all([takeEvery(actions.UPDATE_JAR_STATUS, updateJarStatusResponse)]);
}