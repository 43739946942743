import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Row, Button, Input, Table, Tag, Select, DatePicker, Tooltip } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { PlusOutlined, PrinterOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import RechargeRequestForm from './RechargeRequestForm';
import RechargeRequestActions from '../../redux/rechargeRequest/actions'
import { isDealer, isDealerAgent } from '../../helpers/commonFunction';
import authActions from '../../redux/auth/actions';
import dealerActions from '../../redux/dealer/actions';
import shortCodeDataActions from '../../redux/shortCodeData/actions';
import rechargeReportActions from '../../redux/rechargeReport/actions'
import { convertDateTimeToRequiredFormatTZ } from '../../helpers/commonFunction';
import InternationalRechargeForm from "./InternationalRechargeForm"

const MobileRecharge = () => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const { getProfileData } = authActions;
    const { getShortCode } = shortCodeDataActions;
    const { getDealer } = dealerActions;
    const { getRechargeReportList, updateRechargeStatus } = rechargeReportActions;
    const shortCodeResult = useSelector((state) => state.shortCodeData.shortCodeResult);
    const { rechargeListResult, loading, updateRechargeStatusLoading } = useSelector((state) => state.rechargeReport);
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const { prepareRechargeForm, resetRecharge, prepareRechargeFormInternational } = RechargeRequestActions;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { profileDataResult: tokenData } = useSelector((state) => state.auth);
    const oneWeekFromToday = moment().startOf('day').subtract(2, 'days');
    const today = moment().startOf('day');
    const [fromDateShow, setfromDateShow] = useState(oneWeekFromToday);
    const [toDateShow, settoDateShow] = useState(today);
    const defaultFromDate = useState(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    const defaultToDate = moment().format('YYYY-MM-DD');
    const [searchData, setSearchData] = useState({
        msisdn: '',
        fromDate: defaultFromDate[0],
        toDate: defaultToDate,
        activationStatus: "",
        dealer: '',
    })


    const [statusData, setstatusData] = useState({
        tableName: "orders",
        fieldName: "OrderStatus"
    })

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'ORDER NUMBER',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: "MOBILE NUMBER",
            key: "mobileNumber",
            dataIndex: "mobileNumber",
        },
        {
            title: 'PLAN',
            dataIndex: 'plan',
            key: 'plan',
        },
        {
            title: "DEALER NAME",
            key: "dealerName",
            dataIndex: "dealerName",
        },
        {
            title: "RECHARGE BY",
            key: "rechargeBy",
            dataIndex: "rechargeBy",
        },
        {
            title: "SOURCE",
            key: "source",
            dataIndex: "source",
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'REMARK',
            dataIndex: 'remark',
            key: 'remark',
        },
    ];

    useEffect(() => {
        dispatch(getShortCode(statusData))
        dispatch(getRechargeReportList(searchData))
        dispatch(getProfileData());
        dispatch(getDealer());

    }, [])

    const handleSearch = () => {
        dispatch(getRechargeReportList(searchData));
    }

    const handleRechargeForm = () => {
        dispatch(prepareRechargeForm());
    }

    const handleRechargeFormInternational = () => {
        dispatch(prepareRechargeFormInternational());
    }
    const handleTransactionId = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleSelectStatus = (e) => {
        const findStatus = e === 11 ? '11,19,27' : e
        setSearchData(prevState => ({
            ...prevState,
            activationStatus: findStatus
        }));
    }

    const handleSelectDealer = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            dealer: e,
        }));
    }

    const handleToDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            toDate: formattedDate
        }));
    }

    const handleFromDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            fromDate: formattedDate
        }));
    }

    const handleUpdateRechargeStatus = (rechargeId) => {
        let selectedRecharge = rechargeListResult.filter((item) => item.ID == rechargeId);
        let request = {
            OrderNumber: selectedRecharge[0].OrderNumber,
            RechargeID: selectedRecharge[0].ID
        }
        dispatch(updateRechargeStatus(request, searchData));
    }

    const dataSource = rechargeListResult && rechargeListResult.length > 0 && rechargeListResult.map((item, key) => ({
        key: key + 1,
        action: <>
            {
                item.AggregatorID == 2 && item.RequestStatus === 'Processing' ?
                    <Tooltip title="Update Status">
                        <Button type="default" loading={item.updateStatusLoading} onClick={() => handleUpdateRechargeStatus(item.ID)} size='large' icon={!item.updateStatusLoading ? <ReloadOutlined style={{ color: "#87ceeb" }} /> : false} />
                    </Tooltip> : ''
            }
        </>,
        remark: item.Remark,
        orderNumber: item.OrderNumber,
        mobileNumber: item.MSISDN,
        IPAddress: item.RequestedIP,
        plan: item.PlanName,
        dealerName: item.DealerName,
        rechargeBy: item.RechargedBy,
        source: item.Source,
        status: <Tag color={item.RequestStatus == 'Pending' ? "grey " : item.RequestStatus == 'Processing' ? "yellow" : item.RequestStatus == 'Complete' ? "green" : "red"} className="draft">{item.RequestStatus}</Tag>,
        date: convertDateTimeToRequiredFormatTZ(item.RequestedDtTm),
    }));

    return (
        <>
            <Card>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        {tokenData && tokenData.length > 0 && tokenData[0].IsCompany === 1 ? <Col span={4}>
                            <Form.Item className="username" label="Dealer" name="dealer">
                                <Select onChange={handleSelectDealer} name="dealer" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.dealer}>
                                    <Option value="" key="0">ALL</Option>
                                    {dealerResult && dealerResult.length > 0 && dealerResult
                                        .filter(dealer => dealer.IsCompany !== 1)
                                        .map(dealer => (
                                            <Option value={dealer.ID} key={dealer.ID}>{dealer.DealerName}</Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col> : ''}
                        <Col span={4}>
                            <Form.Item className="username" label="Status" name="activationStatus" >
                                <Select onChange={handleSelectStatus} name="activationStatus" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.activationStatus}>
                                    <Select.Option value="" key="0">ALL</Select.Option>
                                    {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map(shortcode => (
                                        <Select.Option value={shortcode.ID} key={shortcode.ID} >{shortcode.Value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item className="username" colon={false} name="fromDate" label="From Date" size="large" >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={fromDateShow} onChange={handleFromDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item className="username" colon={false} name="toDate" label="To Date" size="large" >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={toDateShow} onChange={handleToDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                className="username"
                                colon={false}
                                name="msisdn"
                                label="MSISDN/ORDER NUMBER"
                                size="large"
                            >
                                <Input placeholder="MSISDN/ORDER NO" name="msisdn" onChange={handleTransactionId} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <div style={{ paddingTop: '4px' }}>
                                <Button loading={loading} type="primary" htmlType='submit' style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        className="criclebox tablespace mb-24"
                        title="Mobile Recharge"
                        extra={
                            <>
                                {
                                    isDealer(tokenData) || isDealerAgent(tokenData) ? (
                                        <>
                                            <Button type="ghost" style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#e53935', color: 'white', border: 'none', height: "50px", fontSize: "18px" }} onClick={handleRechargeFormInternational} > <PlusOutlined style={{ fontSize: '20px' }} />INTERNATIONAL RECHARGE</Button>
                                            <Button type="ghost" style={{ float: 'right', marginRight: '10px', fontWeight: 'bold', backgroundColor: '#088aca', color: 'white', border: 'none', height: "50px", fontSize: "18px" }} onClick={handleRechargeForm} > <PlusOutlined style={{ fontSize: '20px' }} />DOMESTIC RECHARGE</Button>
                                        </>
                                    ) : ''
                                }

                            </>
                        }>
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
            <RechargeRequestForm searchData={searchData} />
            <InternationalRechargeForm searchData={searchData} />

        </>
    )
}

export default MobileRecharge;