const actions = {
    GET_SHORT_CODE: 'GET_SHORT_CODE',
    GET_SHORT_CODE_SUCCESS: 'GET_SHORT_CODE_SUCCESS',
    GET_SHORT_CODE_FAILED: "GET_SHORT_CODE_FAILED",

    getShortCode: (data) => ({
        type: actions.GET_SHORT_CODE,
        payload:{data}
    }),
    getShortCodeSuccess: (shortCodeResult) => ({
        type: actions.GET_SHORT_CODE_SUCCESS,
        shortCodeResult
    }),
    getShortCodeFailed: (error) => ({
        type: actions.GET_SHORT_CODE_FAILED,
        error
    }),
};
export default actions;
