import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, deleteData, addData } from '../../helpers/fackBackend_Helper';

function* getCustomerListResponse() {
    try {
        const response = yield call(getList, '/customer');
        if (response.response.Status) {
            yield put(
                actions.getCustomerSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getCustomerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCustomerFailed(error));
    }
}

function* deleteCustomerDataResponse({ payload: { customerId, status } }) {
    try {
        const response = yield call(deleteData, '/deleteCustomer/' + customerId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(getList, '/customer');
            if (responseList.response.Status) {
                yield put(
                    actions.deleteCustomerSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.getCustomerFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.getCustomerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCustomerFailed(error));
    }
}

function* releaseCustomerResponse({ data }) {
    try {
        const response = yield call(addData, '/customerRelease', data);
        if (response.response.Status) {
            yield put(actions.releaseCustomerSuccess(response.response.Data));
        } else {
            yield put(actions.releaseCustomerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.releaseCustomerFailed(error.message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_CUSTOMER, getCustomerListResponse)]);
    yield all([takeEvery(actions.DELETE_CUSTOMER, deleteCustomerDataResponse)]);
    yield all([takeEvery(actions.RELEASE_CUSTOMER, releaseCustomerResponse)]);
}