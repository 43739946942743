import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getRechargeReportListResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getRechargeReport', data);
        if (response.response.Status) {
            yield put(
                actions.getRechargeReportListSuccess(
                    response.response.Data
                )
            )
        } else {
            yield put(actions.getRechargeReportListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getRechargeReportListFailed(error));
    }
}

function* updateRechargeStatusResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/updateInternationRechargeRequest', data);
        if (response.Status) {
            yield put(actions.updateRechargeStatusSuccess(response.Message, data.RechargeID));
            yield put(actions.getRechargeReportList(searchData));
        } else {
            yield put(actions.updateRechargeStatusFailed(response.Message, data.RechargeID));
            yield put(actions.getRechargeReportList(searchData));
        }
    } catch (error) {
        yield put(actions.updateRechargeStatusFailed(error.Message, data.RechargeID));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_RECHARGE_REPOST_LIST, getRechargeReportListResponse)]);
    yield all([takeEvery(actions.UPDATE_RECHARGE_STATUS, updateRechargeStatusResponse)]);
}