const actions = {
    GET_DEALER: 'GET_DEALER',
    GET_DEALER_SUCCESS: 'GET_DEALER_SUCCESS',
    GET_DEALER_FAILED: 'GET_DEALER_FAILED',
    ADD_DEALER: "ADD_DEALER",
    ADD_DEALER_SUCCESS: "ADD_DEALER_SUCCESS",
    ADD_DEALER_FAILED: "ADD_DEALER_FAILED",
    EDIT_DEALER: "EDIT_DEALER",
    EDIT_DEALER_SUCCESS: "EDIT_DEALER_SUCCESS",
    DELETE_DEALER: "DELETE_DEALER",
    DELETE_DEALER_SUCCESS: "DELETE_DEALER_SUCCESS",
    PREPARE_DEALER_FORM: "PREPARE_DEALER_FORM",
    RESET_DEALER: "RESET_DEALER",
    RESET_DEALER_MESSAGE: "RESET_DEALER_MESSAGE",
    PREPARE_PASSWORD_FORM: "PREPARE_PASSWORD_FORM",
    DEALER_PASSWORD_RESET: "DEALER_PASSWORD_RESET",
    DEALER_PASSWORD_RESET_SUCCESS: "DEALER_PASSWORD_RESET_SUCCESS",
    DEALER_PASSWORD_RESET_FAILED:"DEALER_PASSWORD_RESET_FAILED",
    getDealer: () => ({
        type: actions.GET_DEALER,
    }),
    getDealerSuccess: (dealerResult) => ({
        type: actions.GET_DEALER_SUCCESS,
        dealerResult
    }),
    getDealerFailed: (dealerError) => ({
        type: actions.GET_DEALER_FAILED,
        dealerError
    }),
    addDealer: (data) => ({
        type: actions.ADD_DEALER,
        payload: { data }
    }),
    addDealerSuccess: (dealerMessage, dealerResult) => ({
        type: actions.ADD_DEALER_SUCCESS,
        dealerResult,
        dealerMessage
    }),
    addDealerFailed: (dealerError) => ({
        type: actions.ADD_DEALER_FAILED,
        dealerError
    }),
    editDealer: (dealerId, data) => ({
        type: actions.EDIT_DEALER,
        payload: { dealerId, data }
    }),
    editDealerSuccess: (dealerMessage, dealerResult) => ({
        type: actions.EDIT_DEALER_SUCCESS,
        dealerResult,
        dealerMessage
    }),
    deleteDealer: (dealerId, status) => ({
        type: actions.DELETE_DEALER,
        payload: { dealerId, status }
    }),
    deleteDealerSuccess: (dealerMessage, dealerResult) => ({
        type: actions.DELETE_DEALER_SUCCESS,
        dealerResult,
        dealerMessage
    }),
    prepareDealerForm: () => ({
        type: actions.PREPARE_DEALER_FORM,
    }),
    resetDealer: () => ({
        type: actions.RESET_DEALER,
    }),
    resetDealerMessage: () => ({
        type: actions.RESET_DEALER_MESSAGE,
    }),
    preparePasswordForm: () => ({
        type: actions.PREPARE_PASSWORD_FORM,
    }),
    dealerPasswordReset: (data) => ({
        type: actions.DEALER_PASSWORD_RESET,
        payload: { data }
    }),
    dealerPasswordResetSuccess: (dealerMessage, dealerResult) => ({
        type: actions.DEALER_PASSWORD_RESET_SUCCESS,
        dealerResult,
        dealerMessage
    }),
    dealerPasswordResetFailed: (dealerError) => ({
        type: actions.DEALER_PASSWORD_RESET_FAILED,
        dealerError
    }),
};
export default actions;
