import actions from "./actions";

const initState = {
    rechargeListResult: [],
    loading: false,
    rechargeListResultLoading: false,
    rechargeListError: null,
    rechargeListMessage: null,
    buttonLoading: false,
    updateRechargeStatusLoading: false,
    updateRechargeStatusError: null,
    updateRechargeStatusMessage: null,

};

export default function reducer(state = initState, action) {
    let rechargeList;
    switch (action.type) {

        case actions.UPDATE_RECHARGE_STATUS:
            rechargeList = state.rechargeListResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.data.RechargeID) {
                    temp.updateStatusLoading = !temp.updateStatusLoading;
                }
                return temp;
            });

            return {
                ...state,
                updateRechargeStatusLoading: true,
                updateRechargeStatusError: null,
                updateRechargeStatusMessage: null,
                rechargeListResult: rechargeList
            };

        case actions.UPDATE_RECHARGE_STATUS_SUCCESS:
            rechargeList = state.rechargeListResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.id) {
                    temp.updateStatusLoading = !temp.updateStatusLoading;
                }
                return temp;
            });

            return {
                ...state,
                updateRechargeStatusLoading: false,
                updateRechargeStatusError: null,
                updateRechargeStatusMessage: action.payload.message,
                rechargeListResult: rechargeList
            };

        case actions.UPDATE_RECHARGE_STATUS_FAILED:
            rechargeList = state.rechargeListResult.map((item) => {
                let temp = item;
                if (item.ID == action.payload.id) {
                    temp.updateStatusLoading = !temp.updateStatusLoading;
                }
                return temp;
            });

            return {
                ...state,
                updateRechargeStatusLoading: false,
                updateRechargeStatusError: action.payload.error,
                updateRechargeStatusMessage: null,
                rechargeListResult: rechargeList
            };

        case actions.GET_RECHARGE_REPOST_LIST:
            return {
                ...state,
                loading: true,
                rechargeListError: null,
                rechargeListMessage: null,
                buttonLoading: true,
                rechargeListResultLoading: true,
            };
        case actions.GET_RECHARGE_REPOST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                rechargeListError: null,
                rechargeListMessage: null,
                rechargeListResult: action.data,
                buttonLoading: false,
                rechargeListResultLoading: false,
            };
        case actions.GET_RECHARGE_REPOST_LIST_FAILED:
            return {
                ...state,
                loading: false,
                rechargeListError: action.activationListError,
                rechargeListResult: null,
                buttonLoading: false,
                rechargeListResultLoading: false,
            };
        default:
            return state;
    }
}