import { useState, useEffect } from "react";
import { Row, Col, Breadcrumb, Input, Modal, Button, Dropdown, List, Form, message, Avatar, Switch } from "antd";
import { ExclamationCircleOutlined, PoweroffOutlined, KeyOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import authActions from '../../redux/auth/actions';
import { useSelector, useDispatch } from 'react-redux';
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { isAdmin, isDealer } from "../../helpers/commonFunction";
import { PASSWORD_VALIDATE } from '../../helpers/constant'


const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ name, subName, onPress }) {
  const history = useHistory();
  const { logoutRequest, getProfileData, changePassword, resetAuthError, passwordFromModel, resetAuthMessage, updateJarStatus } = authActions;
  const { manualAddFundMessage, manualAddFundError } = useSelector((state) => state.manualAddFund);
  const { createRechargeRequestMessage, createRechargeRequestError, createRechargeRequestInternationalMessage, createRechargeRequestInternationalError } = useSelector((state) => state.rechargeRequest);
  const dispatch = useDispatch();
  const [idToken, setidToken] = useState(null);
  const profileDataResult = useSelector((state) => state.auth.profileDataResult);
  const passwordMessage = useSelector((state) => state.auth.passwordMessage);
  const buttonLoading = useSelector((state) => state.auth.buttonLoading);
  const passwordError = useSelector((state) => state.auth.passwordError);
  const openModel = useSelector((state) => state.auth.openModel);
  const Error = useSelector((state) => state.user.userError);
  const jarMessage = useSelector((state) => state.auth.jarMessage);
  const [passwordChangeData, setPasswordChangeData] = useState({
    oldPassword: "",
    newPassword: "",
    userId: "",
  });
  const [form] = Form.useForm();
  const [userId, setUserId] = useState();
  const [modal, contextHolder] = Modal.useModal();
  const [redirect, setRedirect] = useState(null);
  const [JARFlag, setJarFlag] = useState(null);
  const { profileDataResult: tokenData } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
    const token = (localStorage.getItem('token')) ? (localStorage.getItem('token')) : null;
    setidToken(token);
    if (token) {
      let decodedToken = jwtDecode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem("token");
        setRedirect('/');
      }
    } else {
      localStorage.removeItem("token");
      setRedirect('/');
    }
    dispatch(getProfileData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualAddFundMessage, manualAddFundError, createRechargeRequestError, createRechargeRequestMessage, createRechargeRequestInternationalMessage, createRechargeRequestInternationalError])

  useEffect(() => {
    if (profileDataResult && profileDataResult.length > 0) {
      setJarFlag(profileDataResult[0].JARFlag)
    }
  }, [profileDataResult])

  useEffect(() => {
    if (jarMessage !== null) {
      dispatch(getProfileData());
    }

  }, [jarMessage])


  const handleLogOut = () => {
    const data = {
      "token": idToken,
    }
    dispatch(logoutRequest(data, history));
  }

  if (redirect) {
    return <Redirect to={redirect}></Redirect>
  }

  let modifiedSubname = subName.replace("/" && "-", " ")


  const confirm = () => {
    modal.confirm({
      title: 'Are you sure want to logout ? ',
      icon: <ExclamationCircleOutlined />,
      okText: 'Logout',
      cancelText: 'Cancel',
      onOk: handleLogOut,
      okButtonProps: {
        style: { background: '#df0909', borderColor: '#df0909' },
      },
    });
  };

  const handleOpenJarConfirmation = () => {
    modal.confirm({
      title: 'Are you sure want to update Jar ? ',
      icon: <ExclamationCircleOutlined />,
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: handleJAROnOff,
      okButtonProps: {
        style: { background: '#0aa61a', borderColor: '#0aa61a' },
      },
    });
  };

  const showPasswordModal = () => {
    setPasswordChangeData(prevState => ({
      ...prevState,
      oldPassword: "",
      newPassword: "",
    }));
    dispatch(passwordFromModel());
  }

  const handleChangePassword = () => {
    dispatch(changePassword(passwordChangeData));
    if (passwordMessage !== null && passwordMessage !== undefined) {
      setPasswordChangeData(prevState => ({
        ...prevState,
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
      }));
    }
  }

  const handleChange = (e) => {
    dispatch(passwordFromModel());
    if (profileDataResult && profileDataResult.length > 0) {
      const userProfile = profileDataResult[0];
      setUserId(userProfile.ID);
    }
    const fname = e.target.name;
    const fvalue = e.target.value;
    setPasswordChangeData(prevState => ({
      ...prevState,
      [fname]: fvalue,
      userId: userId
    }));
  }

  const handleCancel = () => {
    dispatch(resetAuthError());
  }


  const profileData = [
    {
      title: <><p style={{ marginTop: '10px' }}>{profileDataResult[0] && `${profileDataResult[0].Username} (${profileDataResult[0].UserRole}) `}</p></>,
      icon: <Avatar style={{ backgroundColor: '#223b8b' }} size="large" icon={<UserOutlined />} />,
    },
    {
      title: "Change Password",
      icon: <KeyOutlined />,
      clickFunction: showPasswordModal
    },
    {
      title: "Logout",
      icon: <PoweroffOutlined />,
      clickFunction: confirm
    },
  ];

  const profileMenu = (
    <List
      min-width="100%"
      className="profilemenu profile-21-one"
      itemLayout="horizontal"
      dataSource={profileData}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            style={{ cursor: 'pointer' }}
            avatar={item.icon}
            title={item.title}
            onClick={item.clickFunction}
          />
        </List.Item>
      )}
    />
  );

  const success = (text) => {
    message.success(text);
    form.resetFields();
    dispatch(resetAuthMessage()); // Reset messages after success message
  };

  const error = (text) => {
    message.error(text);
    form.resetFields();
    dispatch(resetAuthMessage()); // Reset messages after error message
  };

  const handleShowJarConfirmation = () => {
    handleOpenJarConfirmation();
  }

  const handleJAROnOff = () => {
    const flag = JARFlag !== null && JARFlag === 1 ? 0 : 1;
    dispatch(updateJarStatus(flag));
  }

  let nameByPage = name === 'user' || name === 'dealer' || name === 'manual-add-fund' || name === 'customer' ? 'Admin' : name === 'network' || name === 'aggregator' || name === 'plan' || name === 'recharge-price-mapping' ? 'Config' : name === 'mobile-recharge' ? 'Recharge' : name === 'mobile-recharge-report' || name === 'audit-report' || name === 'commission-report' || name === 'activation-report' ? 'Report' : 'Dashboard'

  return (
    <>
      {passwordMessage !== null && passwordMessage !== undefined && success(passwordMessage)}
      {passwordError !== null && passwordError !== undefined && error(passwordError)}
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="#">{nameByPage}</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {modifiedSubname.replace("-", " ")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" }}
            >
              {modifiedSubname.replace("-", " ")}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Dropdown overlay={profileMenu} trigger={["click"]}>
            <a
              href="#pablo"
              className="ant-dropdown-link "
              onClick={(e) => e.preventDefault()}
            >
              {profile}
            </a>
          </Dropdown>
          {contextHolder}

          {profileDataResult && profileDataResult.length > 0 && profileDataResult.map((data, index) => {
            return (
              <div style={{ display: "flex", alignItems: "baseline" }} key={index}>
                <h2 style={{ marginLeft: '20px', marginRight: '30px', fontWeight: "900", marginBottom: 0, color: "rgb(241 241 241)" }}>
                  {data.AccountBalance != null && data.AccountBalance != undefined ? `$${data.AccountBalance.toFixed(2)}` : '$0.00'}
                </h2>
              </div>
            );
          })}
        </Col>
      </Row>
      <Modal title="CHANGE PASSWORD" visible={openModel} closable={true} destroyOnClose={true} maskClosable={false} centered
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            CANCEL
          </Button>,
          <Button form="passwordForm" key="submit" loading={buttonLoading} htmlType="submit" type="primary" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} >
            SUBMIT
          </Button>,
        ]}>
        <Form id="passwordForm" form={form} layout="vertical" className="row-col" onFinish={handleChangePassword}>
          <Form.Item className="username" name="oldPassword" rules={[{ required: true, message: "Please input old password!", }]}>
            <Input.Password addonBefore={<LockOutlined />} placeholder="Old Password" className='antdInputPassword' name="oldPassword" onChange={handleChange} />
          </Form.Item>
          <Form.Item className="username" label="New Password" name="newPassword" rules={[{ required: true, message: "Please input new password!", }, {
            min: 8,
            pattern: PASSWORD_VALIDATE,
            message: 'Invalid password format(Example@123) !'
          }]}>
            <Input.Password addonBefore={<LockOutlined />} placeholder="New Password" name="newPassword" hasFeedback onChange={handleChange} />
          </Form.Item>
          <Form.Item className="username" label="Confirm Password" name="confirmPassword" hasFeedback dependencies={['newPassword']} rules={[
            { required: true, message: "Please input confirm password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Confirm password does not match!'));
              },
            })]}>
            <Input.Password addonBefore={<LockOutlined />} placeholder="Confirm Password" name="confirmPassword" onChange={handleChange} />
          </Form.Item>
        </Form>
      </Modal>

    </>
  );
}

export default Header;
