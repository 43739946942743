const actions = {
    GET_RECHARGE_REPOST_LIST: "GET_RECHARGE_REPOST_LIST",
    GET_RECHARGE_REPOST_LIST_SUCCESS: "GET_RECHARGE_REPOST_LIST_SUCCESS",
    GET_RECHARGE_REPOST_LIST_FAILED: "GET_RECHARGE_REPOST_LIST_FAILED",
    UPDATE_RECHARGE_STATUS: "UPDATE_RECHARGE_STATUS",
    UPDATE_RECHARGE_STATUS_SUCCESS: "UPDATE_RECHARGE_STATUS_SUCCESS",
    UPDATE_RECHARGE_STATUS_FAILED: "UPDATE_RECHARGE_STATUS_FAILED",

    updateRechargeStatus: (data, searchData) => ({
        type: actions.UPDATE_RECHARGE_STATUS,
        payload: { data, searchData }
    }),
    updateRechargeStatusSuccess: (message, id) => ({
        type: actions.UPDATE_RECHARGE_STATUS_SUCCESS,
        payload: { message, id }
    }),
    updateRechargeStatusFailed: (error, id) => ({
        type: actions.UPDATE_RECHARGE_STATUS_FAILED,
        payload: { error, id }
    }),

    getRechargeReportList: (data) => ({
        type: actions.GET_RECHARGE_REPOST_LIST,
        payload: { data }
    }),
    getRechargeReportListSuccess: (data) => ({
        type: actions.GET_RECHARGE_REPOST_LIST_SUCCESS,
        data
    }),
    getRechargeReportListFailed: (data) => ({
        type: actions.GET_RECHARGE_REPOST_LIST_FAILED,
        data
    }),

}
export default actions;