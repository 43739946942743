import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';


function* getPreloadedActivationResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/getPreloadedActivation', searchData);
        // const responseP = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.getPreloadedSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getPreloadedFailed());
        }
    } catch (error) {
        yield put(actions.getPreloadedFailed());
    }
}

function* getPreloadedPrepareFormResponse() {
    try {
        const response = yield call(getList, '/prepareInvoice');
        if (response.response.Status) {
            yield put(
                actions.prepareAddPreloadedFormSuccess(
                    response.response.Data.BatchNumber
                )
            );
        } else {
            yield put(actions.prepareAddPreloadedFormFailed());
        }
    } catch (error) {
        yield put(actions.prepareAddPreloadedFormFailed());
    }
}

function* validatePreloadedUploadDataResponse({ payload: { preloadeduploadData } }) {
    try {
        const response = yield call(addData, '/validateBulkUpload', preloadeduploadData);
        if (response.response.Status) {
            yield put(
                actions.validatePreloadedDataSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.validatePreloadedDataFailed(response.response.Message, response.response.simData));
        }
    } catch (error) {
        yield put(actions.validatePreloadedDataFailed(error));
    }
}

function* addPreloadedDataResponse({ payload: { data, searchData } }) {
    try {
        const response = yield call(addData, '/createActivation', data);
        if (response.response.Status) {
            const responseList = yield call(addData, '/getPreloadedActivation', searchData);
            if (responseList.response.Status) {
                yield put(
                    actions.addPreloadedSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.addPreloadedFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.addPreloadedFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addPreloadedFailed(error));
    }
}

function* getPreloadedDetailsResponse({ payload: { preloadedId } }) {
    try {
        const response = yield call(getList, '/bulk/' + preloadedId);
        if (response.response.Status) {
            yield put(
                actions.getPreloadedDetailsSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getPreloadedFailed());
        }
    } catch (error) {
        yield put(actions.getPreloadedFailed());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_PRELOADED, getPreloadedActivationResponse)]);
    yield all([takeEvery(actions.PREPARE_ADD_PRELOADED_FORM, getPreloadedPrepareFormResponse)]);
    yield all([takeEvery(actions.VALIDATE_PRELOADED_DATA, validatePreloadedUploadDataResponse)]);
    yield all([takeEvery(actions.ADD_PRELOADED, addPreloadedDataResponse)]);
    yield all([takeEvery(actions.GET_PRELOADED_DETAILS, getPreloadedDetailsResponse)]);

}
