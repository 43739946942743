import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import dealerSagas from './dealer/saga';
import customerSagas from './customer/saga';
import planSagas from './plan/saga';
import countrySagas from './country/saga';
import bulkActivationSagas from './bulkActivation/saga';
import homeSagas from './home/saga';
import blankActivationSagas from './blankActivation/saga';
import preloadedActivationSagas from './preloadedActivation/saga'
import bulkReportSagas from './bulkReport/saga';
import activationReportSagas from './activationReport/saga';
import shortCodeDataSagas from './shortCodeData/saga';
import rechargeReportSagas from './rechargeReport/saga';
import manualAddFundSaga from './manualAddFund/saga';
// import bulkRechargeSagas from './bulkRecharge/saga';
// import lycaDealerSagas from'./lycaDealer/saga';
import auditReportSagas from './auditReport/saga';
import networkSagas from './network/saga';
import rechargeRequestSagas from './rechargeRequest/saga'
import rechargeMappingsagas from './rechargeMapping/saga'
import aggregatorSagas from './aggregator/saga'
import commissionReportSagas from './commissionReport/saga'


export default function* rootSaga() {
        yield all([
                authSagas(),
                userSagas(),
                dealerSagas(),
                customerSagas(),
                planSagas(),
                countrySagas(),
                bulkReportSagas(),
                bulkActivationSagas(),
                homeSagas(),
                blankActivationSagas(),
                preloadedActivationSagas(),
                activationReportSagas(),
                shortCodeDataSagas(),
                rechargeReportSagas(),
                manualAddFundSaga(),
                // bulkRechargeSagas(),
                // lycaDealerSagas(),
                auditReportSagas(),
                rechargeRequestSagas(),
                networkSagas(),
                rechargeMappingsagas(),
                aggregatorSagas(),
                commissionReportSagas(),
        ])
}