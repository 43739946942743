import actions from "./actions";

const initState = {
    list: [],
    activeAggregatorList: [],
    openAggregatorModel: false,
    previewModel: false,
    loading: false,
    tableLoading:false,
    errorMessage: null,
    aggregatorMessage: null,
    listGetByNetworkId: [],
    preferedAggregatorList:[]
};

export default function reducer(state = initState, action) {

    switch (action.type) {

        case actions.GET_AGGREGATOR_BY_NETWORK_ID:
            return {
                ...state,
                loading: true,
                tableLoading:false,
                errorMessage: null,
            };

        case actions.GET_AGGREGATOR_BY_NETWORK_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: null,
                listGetByNetworkId: action.data,
                modal: false,
                previewData: [],
            };

        case actions.GET_AGGREGATOR_BY_NETWORK_ID_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
                aggregatorMessage: null,
            };

        

        case actions.GET_AGGREGATOR:
            return {
                ...state,
                loading: true,
                errorMessage: null,
                tableLoading:true,
            };

        case actions.GET_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: null,
                list: action.data,
                modal: false,
                previewData: [],
                tableLoading:false,
            };

        case actions.GET_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
                aggregatorMessage: null,
                tableLoading:false,

            };

       
        case actions.OPEN_AGGREGATOR_MODAL:
            return {
                ...state,
                openAggregatorModel: true,
                errorMessage: null,
                aggregatorMessage: null,
                loading: false,
            };
        case actions.ADD_AGGREGATOR:
            return {
                ...state,
                loading: true,
                errorMessage: null,
                aggregatorMessage: null,
            };

        case actions.ADD_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: null,
                aggregatorMessage: action.message,
                openAggregatorModel: false,
            };

        case actions.ADD_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
                aggregatorMessage: null,
            };

        case actions.RESET_AGGREGATOR:
            return {
                ...state,
                openAggregatorModel: false,
                previewModel: false,
                loading: false,
                errorMessage: null,
                aggregatorMessage: null,
            };
        
        
        case actions.UPDATE_AGGREGATOR:
            return {
                ...state,
                loading: true,
                errorMessage: null,
                aggregatorMessage: null,
            };

        case actions.UPDATE_AGGREGATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                errorMessage: null,
                aggregatorMessage: action.message,
                openAggregatorModel: false,
                modalEditParam: false,
            };

        case actions.UPDATE_AGGREGATOR_FAILED:
            return {
                ...state,
                loading: false,
                errorMessage: action.error,
                aggregatorMessage: null,
            };

            case actions.GET_PREFFERED_AGGREGATOR:
                return {
                    ...state,
                    loading: true,
                    networkError: null,
                    networkMessage: null,
                };
            case actions.GET_PREFFERED_AGGREGATOR_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    networkError: null,
                    preferedAggregatorList: action.AggregatorList,
                };
            case actions.GET_PREFFERED_AGGREGATOR_FAILED:
                return {
                    ...state,
                    loading: false,
                    networkError: action.error,
                };
        default:
            return state;
    }
}