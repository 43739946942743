import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, deleteData,addData } from '../../helpers/fackBackend_Helper';

function* getPlanListResponse({ payload: { data} }) {
    try {
        const response = yield call(addData, '/plans',data);
        if (response.response.Status) {
            yield put(
                actions.getPlanSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanFailed(error));
    }
}

function* deletePlanDataResponse({ payload: { planId, status,searchData } }) {
    try {
        const response = yield call(deleteData, '/plan/' + planId + '/' + status);
        if (response.response.Status) {
            const responseList = yield call(addData, '/plans',searchData);
            if (responseList.response.Status) {
                yield put(
                    actions.deletePlanSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.deletePlanFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.deletePlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.deletePlanFailed(error));
    }
}
function* getPlanListByNetworkResponse({network}) {
    try {
        const response = yield call(getList, '/planByNetwork/'+network);
        if (response.response.Status) {
            yield put(
                actions.getPlanByNetworkSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getPlanByNetworkFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getPlanByNetworkFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_PLAN, getPlanListResponse),
        takeEvery(actions.DELETE_PLAN, deletePlanDataResponse),
        takeEvery(actions.GET_PLAN_BY_NETWORK, getPlanListByNetworkResponse),


    ]);
}
