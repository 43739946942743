import React from 'react';
import { useState, useEffect } from 'react'
import { Card, Form, Col, Row, Input, Button, Table, DatePicker, Tag, Select } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { DownloadOutlined, SyncOutlined, ClockCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import activationReportActions from '../../redux/activationReport/actions';
import shortCodeDataActions from '../../redux/shortCodeData/actions';
import withTokenValidation from '../../withTokenValidation';
import rechargeReportActions from '../../redux/rechargeReport/actions';
import authActions from '../../redux/auth/actions';
import dealerActions from '../../redux/dealer/actions';
import { convertDateTimeToRequiredFormatTZ } from '../../helpers/commonFunction';


const columns = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'ORDER NUMBER',
        dataIndex: 'orderNumber',
        key: 'orderNumber',
    },
    {
        title: "MOBILE NUMBER",
        key: "mobileNumber",
        dataIndex: "mobileNumber",
    },
    {
        title: 'PLAN',
        dataIndex: 'plan',
        key: 'plan',
    },
    {
        title: "DEALER NAME",
        key: "dealerName",
        dataIndex: "dealerName",
    },
    {
        title: "RECHARGE BY",
        key: "rechargeBy",
        dataIndex: "rechargeBy",
    },
    {
        title: "SOURCE",
        key: "source",
        dataIndex: "source",
    },
    {
        title: 'STATUS',
        dataIndex: 'RequestStatus',
        key: 'RequestStatus',
    },
    {
        title: 'DATE',
        dataIndex: 'CreatedDtTm',
        key: 'CreatedDtTm',
    },
    {
        title: 'UPDATED DATE',
        dataIndex: 'ModifiedDtTm',
        key: 'ModifiedDtTm',
    },
    {
        title: 'IP ADDRESS',
        dataIndex: 'IPAddress',
        key: 'IPAddress',
    },
];

function MobileRechargeReport() {
    const { Option } = Select;
    const dispatch = useDispatch();
    const { getProfileData } = authActions;
    const { getDealer } = dealerActions;
    const { getShortCode } = shortCodeDataActions;
    const { getRechargeReportList } = rechargeReportActions;
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const shortCodeResult = useSelector((state) => state.shortCodeData.shortCodeResult);
    const { profileDataResult: tokenData } = useSelector((state) => state.auth);
    const rechargeListResult = useSelector((state) => state.rechargeReport.rechargeListResult);
    const loading = useSelector((state) => state.rechargeReport.loading);
    const buttonLoading = useSelector((state) => state.rechargeReport.buttonLoading);
    const defaultFromDate = useState(moment().subtract(2, 'days').format('YYYY-MM-DD'));
    const defaultToDate = moment().format('YYYY-MM-DD');
    const [searchData, setSearchData] = useState({
        msisdn: '',
        fromDate: defaultFromDate[0],
        toDate: defaultToDate,
        activationStatus: "",
        dealer: '',
    })
    const [statusData, setstatusData] = useState({
        tableName: "orders",
        fieldName: "OrderStatus"
    })
    const oneWeekFromToday = moment().startOf('day').subtract(2, 'days');
    const today = moment().startOf('day');
    const [fromDateShow, setfromDateShow] = useState(oneWeekFromToday);
    const [toDateShow, settoDateShow] = useState(today);

    useEffect(() => {
        dispatch(getRechargeReportList(searchData))
        dispatch(getShortCode(statusData))
        dispatch(getProfileData());
        dispatch(getDealer());
    }, [])



    const handleTransactionId = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleToDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            toDate: formattedDate
        }));
    }

    const handleFromDateChange = (date, dateString) => {
        const formattedDate = dateString !== "" ? moment(dateString).format('YYYY-MM-DD') : "";
        setSearchData(prevState => ({
            ...prevState,
            fromDate: formattedDate
        }));
    }

    const handleSelectStatus = (e) => {
        const findStatus = e === 11 ? '11,19,27' : e
        setSearchData(prevState => ({
            ...prevState,
            activationStatus: findStatus
        }));
    }
    const handleSelectDealer = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            dealer: e
        }));
    }

    const handleSearch = () => {
        dispatch(getRechargeReportList(searchData))
    }
    var dataSource = rechargeListResult && rechargeListResult.length > 0 && rechargeListResult.map((report, key) => {
        let jarIPAddress = report.RequestedIP;
        if (typeof jarIPAddress === 'string') {
            jarIPAddress = jarIPAddress.replace("::ffff:", "").trim();
        } else {
            jarIPAddress = '';
        }
        return {
            key: key + 1,
            orderNumber: report.OrderNumber,
            mobileNumber: report.MSISDN,
            IPAddress: jarIPAddress,
            plan: report.PlanName,
            dealerName: report.DealerName,
            rechargeBy: report.RechargedBy,
            source: report.Source,
            RequestStatus: <Tag color={report.RequestStatus == 'Pending' ? "grey " : report.RequestStatus == 'Processing' ? "yellow" : report.RequestStatus == 'Processed' ? "green" : "red"} className="draft">{report.RequestStatus}</Tag>,
            // CreatedDtTm: moment(new Date(report.RequestedDtTm)).format("MM/DD/YYYY h:mm:ss"),
            CreatedDtTm: convertDateTimeToRequiredFormatTZ(report.RequestedDtTm),
            ModifiedDtTm: convertDateTimeToRequiredFormatTZ(report.ModifiedDate),
        }
    });

    var listData = rechargeListResult && rechargeListResult.length > 0 && rechargeListResult.map((report, key) => {
        let jarIPAddress = report.RequestedIP;
        if (typeof jarIPAddress === 'string') {
            jarIPAddress = jarIPAddress.replace("::ffff:", "").trim();
        } else {
            jarIPAddress = '';
        }
        return {
            'S No.': key + 1,
            'Order Number': report.OrderNumber,
            'Mobile Number': report.MSISDN,
            'IP Address': jarIPAddress,
            Plan: report.PlanName,
            'Dealer Name': report.DealerName,
            'Recharge By': report.RechargedBy,
            Source: report.Source,
            'Request Status': report.RequestStatus,
            'Created DtTm': moment(new Date(report.RequestedDtTm)).format("MM/DD/YYYY h:mm:ss"),
            'Modified DtTm': report.ModifiedDate ? moment(new Date(report.ModifiedDate)).format('MM/DD/YYYY h:mm:ss') : ''
        }
    });

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'RechargeReports' + fileExtension);
        }
    }

    return (
        <>
            <Card>
                <Form id="searchForm" layout="vertical" className="row-col" onFinish={handleSearch} >
                    <Row gutter={[16, 16]}>
                        {tokenData && tokenData.length > 0 && tokenData[0].IsCompany === 1 ? <Col span={4}>
                            <Form.Item className="username" label="Dealer" name="dealer">
                                <Select onChange={handleSelectDealer} name="dealer" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.dealer}>
                                    <Option value="" key="0">ALL</Option>
                                    {dealerResult && dealerResult.length > 0 && dealerResult
                                        .filter(dealer => dealer.IsCompany !== 1)
                                        .map(dealer => (
                                            <Option value={dealer.ID} key={dealer.ID}>{dealer.DealerName}</Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col> : ''}
                        <Col span={4}>
                            <Form.Item className="username" label="Status" name="activationStatus" >
                                <Select onChange={handleSelectStatus} name="activationStatus" className="ant-select" showSearch optionFilterProp="children" defaultValue={searchData.activationStatus}>
                                    <Select.Option value="" key="0">ALL</Select.Option>
                                    {shortCodeResult && shortCodeResult.length > 0 && shortCodeResult.map(shortcode => (
                                        <Select.Option value={shortcode.ID} key={shortcode.ID} >{shortcode.Value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item className="username" colon={false} name="fromDate" label="From Date" size="large">
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} defaultValue={fromDateShow} className="ant-datepicker" allowClear={false} inputReadOnly={true} onChange={handleFromDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item className="username" colon={false} name="toDate" label="To Date" size="large"  >
                                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} className="ant-datepicker" allowClear={false} inputReadOnly={true} defaultValue={toDateShow} onChange={handleToDateChange} disabledDate={(current) => current.isAfter(moment())} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                className="username"
                                colon={false}
                                name="msisdn"
                                label="MSISDN/ORDER NUMBER"
                                size="large"
                            >
                                <Input placeholder="MSISDN/ORDER NO" name="msisdn" onChange={handleTransactionId} />
                            </Form.Item>
                        </Col>

                        <Col span={2} style={{paddingTop:'4px'}}>
                            <Button type="primary" htmlType='submit' loading={loading} style={{ marginTop: '28px', float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>SEARCH</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>

            <Row gutter={[24, 0]}>
                <Col xs="24" xl={24}>
                    <Card
                        bordered={false}
                        style={{ marginTop: '8px' }}
                        className="criclebox tablespace mb-24"
                        title="Mobile Recharge Report"
                        extra={
                            <>
                                <Button onClick={() => exportToCSV()} style={{ float: 'right', backgroundColor: '#35267a', color: 'white', border: 'none' }}><DownloadOutlined style={{ fontSize: '20px', paddingTop: "5px" }} /> DOWNLOAD REPORT</Button>
                            </>
                        }
                    >
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination
                                loading={loading}
                                className="ant-border-space"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default withTokenValidation(MobileRechargeReport);