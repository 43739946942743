import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import authActions from './redux/auth/actions';
const { getProfileData, resetAuthMessage } = authActions;

const withTokenValidation = (WrappedComponent) => {
    const TokenValidationWrapper = (props) => {
        const dispatch = useDispatch();
        const history = useHistory();
        const profileError = useSelector((state) => state.auth.profileError);

        useEffect(() => {
            dispatch(getProfileData());
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (profileError && profileError !== null && profileError === 'Invalid credentials') {
                localStorage.removeItem('token');
                history.push('/');
                // window.location.reload('/');
                dispatch(resetAuthMessage());
            }
        }, [profileError, history]);

        return <WrappedComponent {...props} />;
    };
    return TokenValidationWrapper;
}

export default withTokenValidation;