const actions = {
    GET_BLANK: 'GET_BLANK',
    GET_BLANK_SUCCESS: 'GET_BLANK_SUCCESS',
    GET_BLANK_FAILED: 'GET_BLANK_FAILED',
    ADD_BLANK: "ADD_BLANK",
    ADD_BLANK_SUCCESS: "ADD_BLANK_SUCCESS",
    ADD_BLANK_FAILED: "ADD_BLANK_FAILED",
    GET_BLANK_DETAILS: "GET_BLANK_DETAILS",
    GET_BLANK_DETAILS_SUCCESS: "GET_BLANK_DETAILS_SUCCESS",
    VALIDATE_BLANK_DATA: 'VALIDATE_BLANK_DATA',
    VALIDATE_BLANK_DATA_SUCCESS: 'VALIDATE_BLANK_DATA_SUCCESS',
    VALIDATE_BLANK_DATA_FAILED: 'VALIDATE_BLANK_DATA_FAILED',
    PREPARE_ADD_BLANK_FORM: "PREPARE_ADD_BLANK_FORM",
    PREPARE_ADD_BLANK_FORM_SUCCESS: "PREPARE_ADD_BLANK_FORM_SUCCESS",
    PREPARE_ADD_BLANK_FORM_FAILED: "PREPARE_ADD_BLANK_FORM_FAILED",
    RESET_BLANK_ACTIVATION: 'RESET_BLANK_ACTIVATION',
    RESET_BLANK_MESSAGE: 'RESET_BLANK_MESSAGE',

    getBlank: (searchData) => ({
        type: actions.GET_BLANK,
        payload:{searchData}
    }),
    getBlankSuccess: (blankResult) => ({
        type: actions.GET_BLANK_SUCCESS,
        blankResult
    }),
    getBlankFailed: (blankError) => ({
        type: actions.GET_BLANK_FAILED,
        blankError
    }),
    addBlank: (data,searchData) => ({
        type: actions.ADD_BLANK,
        payload: { data,searchData }
    }),
    addBlankSuccess: (blankMessage, blankResult) => ({
        type: actions.ADD_BLANK_SUCCESS,
        blankMessage, blankResult
    }),
    addBlankFailed: (blankError) => ({
        type: actions.ADD_BLANK_FAILED,
        blankError
    }),
    getBlankDetails: (blankId) => ({
        type: actions.GET_BLANK_DETAILS,
        payload: { blankId }
    }),
    getBlankDetailsSuccess: (blankDetails) => ({
        type: actions.GET_BLANK_DETAILS_SUCCESS,
        blankDetails
    }),
    validateBlankData: (blankuploadData) => ({
        type: actions.VALIDATE_BLANK_DATA,
        payload: { blankuploadData }
    }),
    validateBlankDataSuccess: (blankMessage) => ({
        type: actions.VALIDATE_BLANK_DATA_SUCCESS,
        blankMessage
    }),
    validateBlankDataFailed: (blankError, simData) => ({
        type: actions.VALIDATE_BLANK_DATA_FAILED,
        blankError, simData
    }),
    prepareAddBlankForm: () => ({
        type: actions.PREPARE_ADD_BLANK_FORM
    }),
    prepareAddBlankFormSuccess: (blankInvResult) => ({
        type: actions.PREPARE_ADD_BLANK_FORM_SUCCESS,
        blankInvResult
    }),
    prepareAddBlankFormFailed: (blankError) => ({
        type: actions.PREPARE_ADD_BLANK_FORM_FAILED,
        blankError
    }),
    resetBlankActivation: () => ({
        type: actions.RESET_BLANK_ACTIVATION
    }),
    resetBlankMessage: () => ({
        type: actions.RESET_BLANK_MESSAGE
    }),
};
export default actions;
