const actions = {
    GET_CUSTOMER: 'GET_CUSTOMER',
    GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILED: 'GET_CUSTOMER_FAILED',
    DELETE_CUSTOMER: "DELETE_CUSTOMER",
    DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
    RESET_CUSTOMER: "RESET_CUSTOMER",
    RESET_CUSTOMER_MESSAGE: "RESET_CUSTOMER_MESSAGE",
    RELEASE_CUSTOMER: 'RELEASE_CUSTOMER',
    RELEASE_CUSTOMER_SUCCESS: 'RELEASE_CUSTOMER_SUCCESS',
    RELEASE_CUSTOMER_FAILED: 'RELEASE_CUSTOMER_FAILED',

    releaseCustomer: (data) => ({
        type: actions.RELEASE_CUSTOMER,
        data
    }),
    releaseCustomerSuccess: (message) => ({
        type: actions.RELEASE_CUSTOMER_SUCCESS,
        message
    }),
    releaseCustomerFailed: (error) => ({
        type: actions.RELEASE_CUSTOMER_FAILED,
        error
    }),

    getCustomer: () => ({
        type: actions.GET_CUSTOMER,
    }),
    getCustomerSuccess: (customerResult) => ({
        type: actions.GET_CUSTOMER_SUCCESS,
        customerResult
    }),
    getCustomerFailed: (customerError) => ({
        type: actions.GET_CUSTOMER_FAILED,
        customerError
    }),

    deleteCustomer: (customerId, status) => ({
        type: actions.DELETE_CUSTOMER,
        payload: { customerId, status }
    }),
    deleteCustomerSuccess: (customerMessage, customerResult) => ({
        type: actions.DELETE_CUSTOMER_SUCCESS,
        customerResult,
        customerMessage
    }),

    resetCustomer: () => ({
        type: actions.RESET_CUSTOMER,
    }),
    resetCustomerMessage: () => ({
        type: actions.RESET_CUSTOMER_MESSAGE,
    }),
};
export default actions;
