const actions = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILED: "LOGOUT_FAILED",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    GET_POFILE_DATA: "GET_POFILE_DATA",
    GET_POFILE_DATA_SUCCESS: "GET_POFILE_DATA_SUCCESS",
    GET_POFILE_DATA_FAILED: "GET_POFILE_DATA_FAILED",
    PASSWORD_FORM_MODEL: "PASSWORD_FORM_MODEL",
    RESET_AUTH_ERROR: 'RESET_AUTH_ERROR',
    RESET_AUTH_MESSAGE:'RESET_AUTH_MESSAGE',
    UPDATE_JAR_STATUS:"UPDATE_JAR_STATUS",
    UPDATE_JAR_STATUS_SUCCESS:"UPDATE_JAR_STATUS_SUCCESS",
    UPDATE_JAR_STATUS_FAILED:"UPDATE_JAR_STATUS_FAILED",

    loginRequest: (data, history) => ({
        type: actions.LOGIN_REQUEST,
        payload: { data, history }
    }),
    loginSuccess: (token) => ({
        type: actions.LOGIN_SUCCESS,
        token,
    }),
    loginFailed: (error) => ({
        type: actions.LOGIN_FAILED,
        error
    }),
    logoutRequest: (token, history) => ({
        type: actions.LOGOUT_REQUEST,
        payload: { token, history },
    }),
    logoutSuccess: () => ({
        type: actions.LOGOUT_SUCCESS,
    }),
    logoutFailed: () => ({
        type: actions.LOGOUT_FAILED,
    }),
    getProfileData: () => ({
        type: actions.GET_POFILE_DATA,
    }),
    getProfileDataSuccess: (profileDataResult) => ({
        type: actions.GET_POFILE_DATA_SUCCESS,
        profileDataResult
    }),
    getProfileDataFailed: (profileError) => ({
        type: actions.GET_POFILE_DATA_FAILED,
        profileError
    }),
    changePassword: (passwordData) => ({
        type: actions.CHANGE_PASSWORD,
        payload: { passwordData },
    }),
    changePasswordSuccess: (passwordMessage) => ({
        type: actions.CHANGE_PASSWORD_SUCCESS,
        passwordMessage
    }),
    changePasswordFailed: (passwordError) => ({
        type: actions.CHANGE_PASSWORD_FAILED,
        passwordError
    }),
    passwordFromModel: () => ({
        type: actions.PASSWORD_FORM_MODEL
    }),
    resetAuthError: () => ({
        type: actions.RESET_AUTH_ERROR,

    }),
    resetAuthMessage:()=>({
        type:actions.RESET_AUTH_MESSAGE
    }),
    updateJarStatus:(flag)=>({
        type:actions.UPDATE_JAR_STATUS,
        payload:{flag}
    }),
    updateJarStatusSuccess:(jarMessage)=>({
        type:actions.UPDATE_JAR_STATUS_SUCCESS,
        jarMessage
    }),
    updateJarStatusFailed:(error)=>({
        type:actions.UPDATE_JAR_STATUS_FAILED,
        error
    })
}

export default actions;