import { useMemo, useState } from 'react'
import { Button, Card, Table, Row, Col, Input, Form, Modal, Tag, Space, message, Upload } from 'antd';
import { PlusOutlined, UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import Papa from 'papaparse'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import rechargeMapping from '../../../redux/rechargeMapping/actions'
const { updateRechargeMappingData, resetRechargePriceMappingMessages } = rechargeMapping;


const RechargeMappingForm = ({ close }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const networkResult = useSelector((state) => state.network.networkResult);
    const { rechargeMappingData, rechargeMappingLoading, updateRechargeMappingMessage, updateRechargeMappingError, updateRechargeMappingLoading } = useSelector((state) => state.rechargeMapping)
    const [previewHeading, setPreviewHeading] = useState([]);
    const [previewDataSourceExcel, setPreviewDataSourceExcel] = useState([]);
    const [uploadedMapping, setUploadedMapping] = useState([]);
    const [previewSubmitBtn, setPreviewSubmitBtn] = useState(true);
    const [fileError, setFileError] = useState('');

    const networkData = networkResult && networkResult.length > 0 && networkResult.map((item) => item.NetworkName);
    const dealerData = dealerResult && dealerResult.length > 0 && dealerResult.filter((item) => item.ParentID != 0).map((item) => {
        return {
            name: item.DealerName,
            dealerId: item.ID
        }
    });

    let previewColumns = previewHeading
    const PreviewKeys = previewDataSourceExcel && previewDataSourceExcel.length > 0 && Object.keys(previewDataSourceExcel[0]);
    const previewDataSource = useMemo(() => {
        if (previewDataSourceExcel && previewDataSourceExcel.length > 0) {
            return previewDataSourceExcel.map((item, index) => {
                const obj = { key: index + 1 };
                PreviewKeys.forEach(key => {
                    obj[key] = item[key];
                });
                return obj;
                return {}
            });
        } else {
            return [];
        }

    }, [previewDataSourceExcel]);

    const getExistingHeaders = () => {
        const topRowNetworks = networkData.flat(1);
        return ['Dealer Name', 'Dealer ID', ...topRowNetworks];
    }


    const downloadSampleFile = async () => {
        const transformedData = {};

        rechargeMappingData.forEach(item => {
            if (!transformedData[item.DealerName]) {
                transformedData[item.DealerName] = {
                    name: item.DealerName,
                    dealerId: item.DealerID
                };
            }
            transformedData[item.DealerName][`${item.NetworkName}`] = item.DealerPrice;
        });

        const resultArray = Object.values(transformedData);

        const headers = getExistingHeaders(); 

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([headers]);
        let dealerColIndex = 1;

        if (resultArray && resultArray.length > 0) {
            resultArray.forEach((planData) => {
                let newRow = [];
                const keys = Object.keys(planData);
                const dealerRow = keys.map(key => planData[key]);
                newRow.push(dealerRow[0]);
                newRow.push(dealerRow[1]);

                headers.slice(2).forEach((item) => {
                    if (planData[item]) {
                        newRow.push(planData[item]);
                    } else {
                        newRow.push(0);
                    }
                });

                XLSX.utils.sheet_add_aoa(ws, [newRow], { origin: { r: dealerColIndex, c: 0 } });
                dealerColIndex += 1;
            });
        } else {
            dealerData.forEach((dealer) => {
                const dealerRow = [dealer.name, dealer.dealerId];
                XLSX.utils.sheet_add_aoa(ws, [dealerRow], { origin: { r: dealerColIndex, c: 0 } });
                dealerColIndex += 1;
            });
        }

        XLSX.utils.book_append_sheet(wb, ws, 'Recharge Price Mapping');
        const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let fileName = "Existing Recharge Price";
        FileSaver.saveAs(data, `${fileName}.csv`);
    }

    const handleFileChange = (e) => {
        setPreviewHeading([]);
        const file = e.target.files[0];
        setFileError('')
        if (file && file.type !== 'text/csv') {
            setFileError('Invalid file type. Only CSV file allowed.');
            return;
        } else {
            setFileError('');
        }

        const existingHeaders = getExistingHeaders(); 

        const fileReader = new FileReader();
        let uploadedData = [];
        if (file) {
            fileReader.onload = async ({ target }) => {
                const csvOutput = Papa.parse(target.result, { header: true });
                const parsedData = csvOutput?.data;
                const columns = Object.keys(parsedData[0]);

                const columnsMatch = columns.every(col => existingHeaders.includes(col)) && existingHeaders.every(header => columns.includes(header));
                if (!columnsMatch) {
                    setFileError('Invalid CSV. Column does not match. Please download latest file.');
                    return;
                }

                let customizeData = [];
                networkResult.map((item) => {
                    customizeData.push({
                        column: `${item.NetworkName}`,
                        networkID: item.ID
                    });
                });

                dealerData.forEach((dealer) => {
                    customizeData.forEach((item) => {
                        let row = parsedData.filter((item1) => item1['Dealer ID'] == dealer.dealerId);
                        if (row.length > 0) {
                            uploadedData.push({
                                dealerId: dealer.dealerId,
                                dealerName: dealer.name,
                                networkID: item.networkID,
                                networkHeading: item.column,
                                dealerPrice: row[0][item.column]
                            });
                        } else {
                            console.error(`No data found for dealer with ID ${dealer.dealerId}`);
                        }
                    });
                });

                const uniquePlanIds = [...new Set(uploadedData.map(item => item.networkHeading))];
                const heading = [
                    { title: '#', key: 'key', dataIndex: 'key' },
                    { title: 'Dealer', key: 'dealerName', dataIndex: 'dealerName' },
                    ...uniquePlanIds.map(id => ({ title: `${id}`, key: `${id}`, dataIndex: `${id}` }))
                ];

                setPreviewHeading(heading)
                const groupedData = uploadedData.reduce((acc, item) => {
                    const { dealerId, dealerName, networkHeading, dealerPrice } = item;
                    if (!acc[dealerId]) {
                        acc[dealerId] = { dealerId, dealerName };
                    }
                    acc[dealerId][networkHeading] = `${dealerPrice}%` || `0`;
                    return acc;
                }, {});
                setPreviewDataSourceExcel(Object.values(groupedData))
                setUploadedMapping(uploadedData);
                setPreviewSubmitBtn(false);
            };
            fileReader.readAsText(file);
        }
    }



    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const handleSubmit = (data) => {
        let UpdateDataArray = [];
        let insertedArray = [];
        uploadedMapping.forEach((firstItem) => {
            let matchFound = false;
            firstItem.dealerPrice = firstItem.dealerPrice >= 0 ? parseFloat(firstItem.dealerPrice) : 0;
            rechargeMappingData.forEach((secondItem) => {
                if (firstItem.dealerId === secondItem.DealerID && firstItem.networkID === secondItem.NetworkID) {
                    matchFound = true;
                    UpdateDataArray.push(firstItem);
                }
            });
            if (!matchFound) {
                insertedArray.push(firstItem);
            }
        });
        const finalData = { "insertData": insertedArray, "updateData": UpdateDataArray };
        dispatch(updateRechargeMappingData(finalData));
    }

    const success = (text) => {
        message.success(text);
        dispatch(resetRechargePriceMappingMessages());
        close()

    };

    const error = (text) => {
        message.error(text);
        dispatch(resetRechargePriceMappingMessages());
    };


    return (
        <>
            {updateRechargeMappingMessage !== null && updateRechargeMappingMessage !== undefined && success(updateRechargeMappingMessage)}
            {updateRechargeMappingError !== null && updateRechargeMappingError !== undefined && error(updateRechargeMappingError)}
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24} md={24} lg={24} sm={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Recharge Mapping"
                            extra={
                                <>
                                    <Button style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }} onClick={downloadSampleFile}><DownloadOutlined />EXISTING RECHARGE MAPPING</Button>
                                </>
                            }
                            bodyStyle={{ padding: '20px' }}
                        >

                            <Form id="userForm" layout="vertical" className="row-col" onFinish={handleSubmit}>
                                <Row gutter={[16, 0]} justify="space-evenly">
                                    <Col span={24}>
                                        <Form.Item
                                            className="username"
                                            label="Upload File"
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "File is required!",
                                                },
                                            ]}
                                            validateStatus={fileError ? 'error' : ''}
                                            help={fileError}
                                        >
                                            <Input type="file" name="name" accept=".csv" className="username" style={{ border: "none" }} onChange={handleFileChange} />
                                        </Form.Item>

                                    </Col>

                                    <div className="table-responsive">
                                        {previewHeading && previewHeading.length > 0 &&
                                            <Table
                                                columns={previewColumns}
                                                dataSource={previewDataSource}
                                                pagination
                                                loading={rechargeMappingLoading}
                                                className="ant-border-space"
                                            />}
                                    </div>

                                    <Col span={24} offset={21} style={{ marginTop: "8px", }}>
                                        <div style={{ margin: "0 -25px" }}>
                                            <Button key="back" onClick={() => close()} style={{ marginRight: '10px' }}> CLOSE</Button>
                                            <Button form="userForm" key="submit" disabled={previewSubmitBtn} loading={updateRechargeMappingLoading} htmlType="submit" style={{ backgroundColor: '#223b8b', color: 'white', border: 'none' }} >
                                                SUBMIT
                                            </Button>
                                        </div>

                                    </Col>

                                </Row>
                            </Form>

                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default RechargeMappingForm