import actions from "./actions";

const initState = {
    bulkReportResult: [],
    loading: false,
    bulkReportError: null,
    bulkReportMessage: null,
    bulkDetailsResult: [],
    buttonLoading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_BULK_ACTIVATION_REPORT_LIST:
            return {
                ...state,
                loading: true,
                bulkReportError: null,
                bulkReportMessage: null,
                buttonLoading :true
            };
        case actions.GET_BULK_ACTIVATION_REPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkReportError: null,
                bulkReportMessage: null,
                bulkReportResult: action.bulkReportResult,
                buttonLoading:false
            };
        case actions.GET_BULK_ACTIVATION_REPORT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                bulkReportError: action.bulkReportError,
                bulkReportMessage: null,
                buttonLoading:false
            };
        case actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST:
            return {
                ...state,
                loading: true,
                bulkDetailsResult: [],
                bulkReportError: null,
                bulkReportMessage: null,
            };
        case actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                bulkReportError: null,
                bulkReportMessage: null,
                bulkDetailsResult: action.bulkDetailsResult,
            };
        case actions.GET_BULK_ACTIVATION_REPORT_DETAILS_LIST_FAILED:
            return {
                ...state,
                loading: false,
                bulkReportError: action.bulkReportError,
                bulkReportMessage: null,
            };
        default:
            return state;
    }
}