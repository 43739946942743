import { useState, useEffect, useMemo } from 'react'
import { Button, Card, Table, Row, Col, Input, Form, Modal, Tag, Space, message } from 'antd';
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RechargeMappingForm from './RechargeMappingForm';
import dealerActions from '../../../redux/dealer/actions';
import networkActions from '../../../redux/network/actions'
import rechargeMapping from '../../../redux/rechargeMapping/actions'

const { getDealer } = dealerActions;
const { getActiveNetwork } = networkActions;
const { getRechargeMapping } = rechargeMapping;


const RechargeMapping = () => {
    const dispatch = useDispatch();
    const [uploadMapping, setUploadMapping] = useState(false);
    const close = () => setUploadMapping(false);
    const [filterText, setFilterText] = useState("");
    const dealerResult = useSelector((state) => state.dealer.dealerResult);
    const networkResult = useSelector((state) => state.network.networkResult);
    const { rechargeMappingData, rechargeMappingLoading } = useSelector((state) => state.rechargeMapping)

    useEffect(() => {
        dispatch(getDealer());
        dispatch(getActiveNetwork());
        dispatch(getRechargeMapping());

    }, []);

    const columns = useMemo(() => {
        let common = [{
            title: "#",
            dataIndex: "key",
            key: "key",
            width: "5%",
        },
        {
            title: "Dealer",
            dataIndex: "dealerName",
            key: "dealerName",
        }]
        if (networkResult && networkResult.length > 0) {
            let data = networkResult.map(item => ({
                title: item.NetworkName,
                dataIndex: item.NetworkName,
                key: item.NetworkName,
                width: "5%",
            }));
            return [
                ...common,
                ...data
            ]
        } else {
            return common;
        }
    }, [networkResult]);

    const handleAddForm = () => {
        setUploadMapping(true);
    }
    // const handleSideButton = () => {
    //     if (dealerResult.length <= 0) {
    //         navigate("/dealer");
    //     }
    // }

    const newDataSource = Object.values(rechargeMappingData && rechargeMappingData.length > 0 && rechargeMappingData.reduce((acc, item) => {
        const { DealerID, DealerName, DealerPrice, NetworkName } = item;
        const plannewHeading = `${NetworkName}`
        if (!acc[DealerID]) {
            acc[DealerID] = { DealerID, DealerName };
        }
        acc[DealerID][plannewHeading] = DealerPrice;
        return acc;
    }, {}));

    const Keys = newDataSource && newDataSource.length > 0 && rechargeMappingData.map((item) => item.NetworkName);


    const filteredItems = newDataSource && newDataSource.length > 0 && newDataSource.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    );

    const DataSource = filteredItems && filteredItems.length > 0 && filteredItems.map((item, index) => {
        const obj = { key: index + 1, dealerName: item.DealerName };
        Keys.forEach(key => {
            obj[key] = item[key] ? `${item[key]}%` : '';
        });
        return obj;
    });

    const showRechargeMappingForm = () => <RechargeMappingForm close={close} />;

    return (
        <>
            {
                uploadMapping ? showRechargeMappingForm() : <>
                    <div className="tabled">
                        <Row gutter={[24, 0]}>
                            <Col xs="24" xl={24} md={24} lg={24} sm={24}>
                                <Card
                                    bordered={false}
                                    className="criclebox tablespace mb-24"
                                    title="Recharge Price Mapping"
                                    extra={
                                        <>
                                            {dealerResult.length <= 1 ?
                                                <Button style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }}>
                                                    <Link to="/dealer" style={{ color: 'white' }}>
                                                        <PlusOutlined /> Add Dealer
                                                    </Link>
                                                </Button> :
                                                <Button style={{ float: 'right', fontWeight: 'bold', backgroundColor: '#223b8b', color: 'white', border: 'none' }} onClick={handleAddForm}><PlusOutlined />UPLOAD</Button>
                                            }

                                        </>
                                    }

                                >
                                    <div className="table-responsive">
                                        <Table
                                            columns={columns}
                                            dataSource={DataSource}
                                            pagination
                                            loading={rechargeMappingLoading}
                                            className="ant-border-space"
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </>
            }
        </>
    )
}

export default RechargeMapping