import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getRechargeMappingDataResponse() {
    try {
        const response = yield call(getList, '/rechargeMapping');
        if (response.Status) {
            yield put(actions.getRechargeMappingSuccess(response.Data));
        } else {
            yield put(actions.getRechargeMappingFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getRechargeMappingFailed(error.Message));
    }
}

function* updateRechargeMappingDataResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/rechargeMapping', data);
        if (response.Status) {
            yield put(actions.updateRechargeMappingDataSuccess(response.Message));
            yield put(actions.getRechargeMapping());
        } else {
            yield put(actions.updateRechargeMappingDataFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.updateRechargeMappingDataFailed(error.Message));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_RECHARGE_MAPPING_DATA, getRechargeMappingDataResponse)]);
    yield all([takeEvery(actions.UPDATE_RECHARGE_MAPPING_DATA, updateRechargeMappingDataResponse)]);
}